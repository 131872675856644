import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {AppContext} from "../../App";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import deliveryIcon from "../../assets/delivery_icon.svg";
import {globalStoreReducer} from "../../common/methods/context/globalStoreReducer";
import {PUBLIC_URL} from "../../common/struct/urlManager";
import {getOperationParticipantId, updateBasketGift} from "../../common/struct/globalVar";
import {StoreActions} from "../../common/struct/store";
import {TBasketGift} from "../../common/struct/models/TBasket";
import {IconPosition} from "../../components/buttons/buttonProps";
import {OrnamentButton} from "../../components/buttons/mainButton";
import CardMediaLoader from "../../components/image/cardMediaLoader";
import Loader from "../../components/loader/loader";
import {updateParticipantBasket} from "../../services/ParticipantService";
import RemoveGiftFromBasketDialog from "../gift/removeGiftFromBasketDialog";
import {Violation} from "./nonEmptyBasket";

type BasketGiftCardProps = {
  gift: TBasketGift,
  violations: Violation[]|null
  setSuccess: (success: boolean) => void,
  isMobile: boolean
}

export default function BasketGiftCard(props: BasketGiftCardProps): JSX.Element {
  const {t} = useTranslation();
  const {gift, violations, setSuccess, isMobile} = props;
  const giftImage = isMobile ? gift.image?.small??null : gift.image?.medium??null;
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(gift.quantity);
  const [error, setError] = useState<string|undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);

  const STORE = useContext<any>(AppContext);
  const [store] = STORE;

  useEffect(() => {
    if (violations) {
      const violation = violations.find(violations => violations.value.odooProductId == gift.id);
      setError(violation?.violations.message);
    }
    setLoading(false);
  }, [violations])

  const updateQuantity = (event: SelectChangeEvent): void => {
    const operationParticipantId = getOperationParticipantId();
    if (!operationParticipantId) {
      return;
    }

    const newQuantity = parseInt(event.target.value);
    const basket = updateBasketGift(store.basket, gift.id, newQuantity);
    updateParticipantBasket(operationParticipantId, basket)
      .then(basket => globalStoreReducer(STORE, StoreActions.UPDATE_BASKET, {basket: basket}))
      .finally((): void => setQuantity(newQuantity))
      .catch(() => navigate(PUBLIC_URL.HOME));
  }

  const deliveryInformation = (): string => {
    if (gift.deliveryByEmail) {
      return gift.deliveryTime ? t("gift_modal.shipment_infos_deliverytime", {deliverytime: gift.deliveryTime}) :
        t("gift_modal.shipment_infos");
    }
    return gift.deliveryTime ? t("gift_modal.shipment_infos_deliverytime_physical", {deliverytime: gift.deliveryTime}) :
      t("gift_modal.shipment_infos_physical");
  }

  const deleteGift = (): void => {
    setOpenDialog(true);
  }

  const imageSize = isMobile ? 104 : 180;
  const giftPoint = <Box sx={{display: "flex", alignItems: "baseline", mb: 3}}>
    <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", mr: 0}}>
      {gift.points}
    </Typography>
    <Typography variant="body2" color="neutral.main">
      {t("basket.your_basket.points")}
    </Typography>
  </Box>
  const giftQuantitySelect = <Select value={quantity.toString()} onChange={updateQuantity} sx={{mb: 3, height: 40}}>
    {[1,2,3,4,5,6,7,8,9].map((key) => {
      return <MenuItem key={`gift-${gift.id}-quantity-${key}`} value={key}>{key}</MenuItem>
    })}
  </Select>

  return (
    <Card sx={{p: isMobile ? 4 : 6, mb: 4, border: "1px solid", borderColor: "ornament.dark", display: "flex", flexDirection: "column"}}>
      {error && <Alert sx={{mb: 4}} variant="filled" severity="error">{error}</Alert>}
      {loading ? <Loader/> : <>
        <RemoveGiftFromBasketDialog gift={gift} isMobile={isMobile} open={openDialog} setOpen={setOpenDialog} setSuccess={setSuccess}/>
        <Box sx={{display: "flex"}}>
          <CardMediaLoader image={giftImage} isMobile={isMobile} sx={{width: imageSize, height: imageSize}}/>
          <Box sx={{display: "flex", flexDirection: "column", width: "100%", justifyContent: "space-between", ml: 3}}>
            <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", mb: 1}}>
              {gift.name}
            </Typography>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Avatar src={deliveryIcon} sx={{width: 24, height: 24, mr: 1}}/>
              {!isMobile && <Divider orientation="vertical" sx={{backgroundColor: "ornament.dark", borderColor: "ornament.dark", width: "1px", height: "32px", mr: 1}}/>}
              <Typography variant="caption" color="neutral.main">
                {deliveryInformation()}
              </Typography>
            </Box>
          </Box>
          {!isMobile && <Box sx={{display: "flex", flexDirection: "column", width: "40%", alignItems: "flex-end", justifyContent: "space-between", ml: 3}}>
            <Box sx={{display: "flex", flexDirection: "column"}}>
              {!store.operation.shop.hidePricing && giftPoint}
              {giftQuantitySelect}
            </Box>
            <Box sx={{display: "flex", alignItems: "center"}}>
              {/* <Button>
                <Typography variant="caption" color="neutral.main" sx={{fontWeight: "medium"}}>
                  {t("basket.your_basket.remove")}
                </Typography>
              </Button>*/}
              <Divider orientation="vertical" sx={{backgroundColor: "ornament.dark", borderColor: "ornament.dark", width: "1px", height: "10px"}}/>
              <Button onClick={deleteGift}>
                <DeleteIcon sx={{fontSize: "12px", mr: 0, color: "neutral.main"}}/>
                <Typography variant="caption" color="neutral.main" sx={{fontWeight: "medium"}}>
                  {t("basket.your_basket.delete")}
                </Typography>
              </Button>
            </Box>
          </Box>}
        </Box>
        {isMobile && <>
          <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", mt: 3}}>
            {giftQuantitySelect}
            {giftPoint}
          </Box>
          <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            <OrnamentButton sx={{width: "100%", mr: 2}} label={t("basket.your_basket.remove")}/>
            <OrnamentButton sx={{width: "100%"}} label={t("basket.your_basket.delete")}
              icon={DeleteIcon} position={IconPosition.LEFT} action={deleteGift}/>
          </Box>
        </>}
      </>}
    </Card>
  )
}
