import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {PUBLIC_URL} from "../../common/struct/urlManager";
import {Alert, Typography, Dialog, DialogTitle, DialogContent} from "@mui/material";
import {errorManager} from "../../common/methods/ApiService";
import {useTranslation} from "react-i18next";
import {PasswordForm} from "./passwordForm";
import {reinitializeParticipantPassword} from "../../services/ParticipantService";
import {getOperationParticipantId} from "../../common/struct/globalVar";
import {AppContext} from "../../App";
import {globalStoreReducer} from "../../common/methods/context/globalStoreReducer";
import {StoreActions} from "../../common/struct/store";

export default function ResetPassword() : JSX.Element {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const [violations, setViolations] = useState<any[]>([]);
  const STORE = useContext<any>(AppContext);
  const [store] = STORE;
  const user = store.user;
  const [cancel, setCancel] = useState(false);

  useEffect(() => {
    if (user === null) {
      navigate(PUBLIC_URL.HOME);
    } else if (!user.participant.mustChangePassword) {
      navigate(PUBLIC_URL.HOME);
    }
  }, [])

  useEffect(() => {
    if (cancel) {
      navigate(PUBLIC_URL.HOME)
    }
  }, [cancel])

  const changePassword = (password: string): Promise<void> => {
    // Ne devrait jamais arriver, on renvoie vers /home si l'utilisateur est null
    if (user === null) {
      return Promise.resolve();
    }

    const operationId = getOperationParticipantId() ?? "";
    return reinitializeParticipantPassword(operationId, password)
      .then((participant) => {
        globalStoreReducer(STORE, StoreActions.SET_USER,{user: user, participant: participant.participant});
        navigate(PUBLIC_URL.HOME);
      })
      .catch((error: any) => {
        const errorResult = errorManager(error, t, navigate, STORE);
        if (typeof errorResult === 'string') setError(errorResult);
        else if (Array.isArray(errorResult)) setViolations(errorResult);
      });
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={true}>
      <DialogTitle sx={{p: 4, backgroundColor: 'ornament.main', borderBottom: "1px solid", borderColor: "ornament.dark"}}>
        <Typography sx={{fontWeight: "bold", color: 'neutral.main'}} variant="h2">{t('update_password.title')}</Typography>
      </DialogTitle>
      <DialogContent sx={{p: "0px"}}>
        {error && <Alert severity="error" sx={{my: 3}}>{error}</Alert>}
        <PasswordForm changePassword={changePassword} violations={violations} setViolations={setViolations}
          setCancel={setCancel} reset={true}/>
      </DialogContent>
    </Dialog>
  )
}
