import {TOrderLineStatus} from "../../common/struct/models/TOrder";
import React, {ElementType} from "react";
import {Box, Typography} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CachedIcon from '@mui/icons-material/Cached';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import {useTranslation} from "react-i18next";
import SyncIcon from '@mui/icons-material/Sync';

type OrderLineStatusProps = {
  textColor: string,
  iconColor: string,
  backgroundColor: string,
  icon: ElementType
}

export default function OrderLineStatus(props: {status: TOrderLineStatus}): JSX.Element {
  const {t} = useTranslation();

  const extractStatusProps = (status: TOrderLineStatus): OrderLineStatusProps => {
    switch (status) {
    case TOrderLineStatus.ONGOING:
      return {
        textColor: "info.dark",
        iconColor: "info.main",
        backgroundColor: "#E0ECFF",
        icon: CachedIcon
      }
    case TOrderLineStatus.VALIDATED:
      return {
        textColor: "success.dark",
        iconColor: "success.main",
        backgroundColor: "#E0F0ED",
        icon: CheckCircleOutlineIcon
      }
    case TOrderLineStatus.FAILED:
      return {
        textColor: "error.dark",
        iconColor: "error.main",
        backgroundColor: "error.light",
        icon: ErrorOutlineIcon
      }
    case TOrderLineStatus.CANCELLED:
      return {
        textColor: "neutral.main",
        iconColor: "neutral.main",
        backgroundColor: "ornament.main",
        icon: CancelIcon
      }
    case TOrderLineStatus.REFUNDED:
      return {
        textColor: "neutral.main",
        iconColor: "neutral.main",
        backgroundColor: "ornament.main",
        icon: SyncIcon
      }
    }
    
  }

  const statusProps = extractStatusProps(props.status);
  const Icon = statusProps.icon;
  return (
    <Box sx={{display: "flex", alignItems: "center", borderRadius: 4, width: "fit-content", px: 2, py: 0, backgroundColor: statusProps.backgroundColor}}>
      <Icon sx={{color: statusProps.iconColor, mr: 0}}/>
      <Typography variant="body1" color={statusProps.textColor} sx={{fontWeight: "medium"}}>{t("orders.order." + props.status.toLowerCase())}</Typography>
    </Box>
  )
}
