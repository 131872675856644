import React, {useState} from "react";
import {Avatar} from "@mui/material";
import emptyGiftMedium from "../../assets/empty_gift_medium.svg";
import emptyGiftSmall from "../../assets/empty_gift_small.svg";
import Loader from "../loader/loader";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

export default function CardMediaLoader(props: {image: string|null, isMobile: boolean, sx?: SxProps<Theme>}): JSX.Element {
  const {image, isMobile, sx} = props;
  const defaultImage = isMobile ? emptyGiftSmall : emptyGiftMedium;
  const [actualImage, setActualImage] = useState(image??defaultImage);
  const [loadingImage, setLoadingImage] = useState<boolean>(true);

  const setDefaultImage = (): void => {
    setActualImage(defaultImage);
    setLoadingImage(false);
  }

  const handleLoad = (): void => {
    setLoadingImage(false);
  }

  const displayImage = loadingImage ? "none" : "block";
  const maxSize = isMobile ? 146 : 248;
  return <>
    {loadingImage && <Loader size={30} sx={{flexGrow: 1}}/>}
    <Avatar variant="rounded" src={actualImage} onLoad={handleLoad} onError={setDefaultImage}
      sx={{display: displayImage, width: "auto", maxWidth: maxSize, maxHeight: maxSize, ...sx}}/>
  </>
}
