import {useTranslation} from "react-i18next";
import React, {ElementType, useContext} from "react";
import {Link, useLocation} from "react-router-dom";
import {Box, Typography} from "@mui/material";
import {AppContext} from "../../App";

type ThirdMenuProps = {
  icon: ElementType,
  label: string,
  url: string
}

type ThirdLevelMenuProps = {
  menus: ThirdMenuProps[]
}

function ThirdLevelMenuItem(props: ThirdMenuProps): JSX.Element {
  const {t} = useTranslation();
  const location = useLocation();
  const [store] = useContext<any>(AppContext);
  const selected = location.pathname + location.hash === props.url;
  const color = selected ? "neutral.dark" : "neutral.main";
  const Icon = props.icon;
  return (
    <Box sx={{mr: store.isMobile ? 2 : 4, pb: 1, pt: 2, px: 1, borderBottom: selected ? "2px solid" : "none", borderColor: "neutral.dark"}}>
      <Link to={props.url} style={{display: "flex", width: "max-content", alignItems: "center", textDecoration: "none"}}>
        <Icon sx={{width: 18, height: 18, color: color}}/>
        <Typography variant="body1" sx={{color: color, fontWeight: "medium", ml: 1}}>
          {t(props.label)}
        </Typography>
      </Link>
    </Box>
  )
}

function ThirdLevelMenu(props: ThirdLevelMenuProps): JSX.Element {
  const [store] = useContext<any>(AppContext);

  return (
    <Box sx={{display: "flex", overflow: "auto", pl: store.isMobile ? 3 : 7, pr: 2, borderBottom: "1px solid", borderColor: "ornament.dark"}}>
      {props.menus.map((menu: ThirdMenuProps) => {
        return <ThirdLevelMenuItem key={"menu-" + menu.label} {...menu}/>
      })}
    </Box>
  )
}

export default ThirdLevelMenu;
