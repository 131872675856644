import React from "react";
import {Box, InputAdornment, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

type FilterPointsProps = {
  minPoints: number|null,
  setMinPoints: (points: number|null) => void,
  maxPoints: number|null,
  setMaxPoints: (points: number|null) => void,
  reload?: boolean
}

export default function FilterPoints(props: FilterPointsProps): JSX.Element {
  const {t} = useTranslation();
  const {minPoints, setMinPoints, maxPoints, setMaxPoints, reload = false} = props;

  const handleMinPrice = (event: any): void => {
    if (event.target.value !== "") {
      setMinPoints(Number.parseInt(event.target.value));
    } else {
      setMinPoints(null);
    }
  }

  const handleMaxPrice = (event: any): void => {    
    if (event.target.value !== "") {
      setMaxPoints(Number.parseInt(event.target.value));
    } else {
      setMaxPoints(null);
    }
  }

  const checkIfNumber = (event: any): void => {
    if (!Number.isInteger(Number.parseInt(event.key))) {
      event.preventDefault();
    }
  }

  return (
    <>
      <Typography variant="caption" color="neutral.main" sx={{pt: 2, pb: 2, px: 3, pr: 3, mt: 3, fontWeight: "regular", textTransform: "uppercase"}}>
        {t("shop.filters.points.filter")}
      </Typography>
      <Box sx={{display: "flex", flexDirection: "column", px: 3, pr: 3, pb: 2}}>
        <Box sx={{display: "flex", flexDirection: "column"}}>
          <Box sx={{display: "flex", flexDirection: "column", mb: 1}}>
            <Typography variant="caption" color="neutral.main" sx={{fontWeight: "medium", pb: 0}}>
              {t("shop.filters.points.min_price")}
            </Typography>
            <TextField disabled={reload} type="number" color="primary" placeholder={t("shop.filters.points.min_price_label")}
              sx={{"& label": {fontSize: "14px"}}} value={minPoints??""} onKeyPress={checkIfNumber} onChange={handleMinPrice} InputProps={{
                sx: {fontSize: "14px", "& input": {
                  p: 3, "MozAppearance": "textfield", "input::-webkit-outer-spin-button": {"WebkitAppearance": "none", margin: "0px"}
                }},
                endAdornment: <InputAdornment position="end"><Typography variant="caption" color="neutral.main" sx={{fontWeight: "bold", height: "20px"}}>{"Points"}</Typography></InputAdornment>,
              }}/>
          </Box>
          <Box sx={{display: "flex", flexDirection: "column"}}>
            <Typography variant="caption" color="neutral.main" sx={{fontWeight: "medium", pb: 0}}>
              {t("shop.filters.points.max_price")}
            </Typography>
            <TextField disabled={reload} type="number" color="primary" placeholder={t("shop.filters.points.max_price_label")}
              sx={{"& label": {fontSize: "14px"}}} value={maxPoints??""} onKeyPress={checkIfNumber} onChange={handleMaxPrice} InputProps={{
                sx: {fontSize: "14px", "& input": {
                  p: 3, "MozAppearance": "textfield", "input::-webkit-outer-spin-button": {"WebkitAppearance": "none", margin: "0px"}
                }},
                endAdornment: <InputAdornment position="end"><Typography variant="caption" color="neutral.main" sx={{fontWeight: "bold", height: "20px"}}>{"Points"}</Typography></InputAdornment>,
              }}/>
          </Box>
        </Box>
      </Box>
    </>
  )
}
