export interface TFilter {
  universIds: number[];
  brands: string[];
  french: boolean;
  ecofriendly: boolean;
  minPoints: number|null;
  maxPoints: number|null;
}

export enum ESorting {
  // Todo : Conception problem, we cannot had other sortings here
  POINTS_ASC = "POINTS_ASC",
  POINTS_DESC = "POINTS_DESC",
  NEW_GIFTS = 'NEW_GIFTS'
}

export const emptyFilter = {
  universIds: [],
  brands: [],
  french: false,
  ecofriendly: false,
  maxPoints: null,
  minPoints: null
}
