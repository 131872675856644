import {ESorting} from "../../common/struct/models/TFilter";
import React, {Dispatch, SetStateAction, useState} from "react";
import {Box, Button, FormControl, RadioGroup, Typography} from "@mui/material";
import RadioButton from "../../components/buttons/radioButton";
import {useTranslation} from "react-i18next";
import CachedIcon from '@mui/icons-material/Cached';
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

interface SortingProps {
  sorting: ESorting;
  setSorting: (sorting: ESorting) => void;
  setReload?: Dispatch<SetStateAction<boolean>>;
  reload?: boolean;
  isMobile?: boolean;
}

export default function Sorting(props: SortingProps): JSX.Element {
  const {t} = useTranslation();
  const {sorting, setSorting, setReload, reload = false, isMobile = false} = props;
  const [sortingIsOpen, setSortingIsOpen] = useState(true);

  const updateSorting = (sorting: ESorting): void => {
    setSorting(sorting);
    if (setReload) setReload(true);
  }
  const updateOpen = (): void => {
    setSortingIsOpen(!sortingIsOpen);
  }

  return (
    <>
      {isMobile && <Typography variant="body2" color="neutral.dark" sx={{p: 3, fontWeight: "bold", textTransform: "uppercase",
        borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "ornament.dark"}}>
        {t("shop.filters.sorting.title")}
      </Typography>}
      {!isMobile && <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", cursor: "pointer",
        borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "ornament.dark", p: 3}} onClick={updateOpen}>
        <Typography variant="body2" color="neutral.dark" sx={{fontWeight: "bold", textTransform: "uppercase"}}>
          {t("shop.filters.sorting.title")}
        </Typography>
        {sortingIsOpen ? <RemoveIcon sx={{color: "neutral.main"}}/> : <AddIcon sx={{color: "neutral.main"}}/>}
      </Box>}
      {sortingIsOpen && <>
        <FormControl sx={{borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "ornament.dark"}}>
          <RadioGroup>
            <RadioButton disabled={reload} sx={{pt: 2, pb: 2, px: 3, pr: 3}} label={t("shop.filters.sorting.news")} isSelected={sorting === ESorting.NEW_GIFTS} handleSelect={(): void => updateSorting(ESorting.NEW_GIFTS)}/>
            <RadioButton disabled={reload} sx={{pt: 2, pb: 2, px: 3, pr: 3}} label={t("shop.filters.sorting.points_asc")} isSelected={sorting === ESorting.POINTS_ASC} handleSelect={(): void => updateSorting(ESorting.POINTS_ASC)}/>
            <RadioButton disabled={reload} sx={{pt: 2, pb: 2, px: 3, pr: 3, borderBottomStyle: "none"}} label={t("shop.filters.sorting.points_desc")} isSelected={sorting === ESorting.POINTS_DESC} handleSelect={(): void => updateSorting(ESorting.POINTS_DESC)}/>
          </RadioGroup>
        </FormControl>
        {!isMobile && <Button variant="outlined" startIcon={<CachedIcon/>} disabled={reload} onClick={(): void => updateSorting(ESorting.NEW_GIFTS)} sx={{m: 3, p: 0}}>
          {t("shop.filters.reset")}
        </Button>}
      </>}
    </>
  )
}
