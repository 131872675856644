import React from "react";
import {
  ButtonProps,
  ButtonSize,
  ButtonType,
  getEndIcon,
  getIcon,
  getStartIcon,
  MainButtonProps,
  SizeProperties,
  TypeProperties
} from "./buttonProps";
import {Box, Button} from "@mui/material";
import {Link} from "react-router-dom";
import {loaderButton} from "../loader/loader";

const SizePropertiesList: SizeProperties[] = [
  {
    height: "30px",
    padding: "4px 12px",
    iconSize: "small",
    fontSize: "14px"
  },
  {
    height: "56px",
    padding: "16px 24px",
    iconSize: "large",
    fontSize: "16px"
  }
]

const TypePropertiesList: TypeProperties[] = [
  {
    variant: "contained",
    fontWeight: "medium",
    color: "primary.contrastText",
    borderColor: "inherit",
    backgroundColor: "primary.main",
    disabledColor: "primary.contrastText",
    disabledBorderColor: "inherit",
    disabledBackgroundColor: "alt.dark",
    hoverColor: "primary.contrastText",
    hoverBorderColor: "inherit",
    hoverBackgroundColor: "primary.light",
    focusColor: "primary.contrastText",
    focusBorderColor: "inherit",
    focusBackgroundColor: "primary.dark",
  },
  {
    variant: "outlined",
    fontWeight: "medium",
    color: "neutral.main",
    borderColor: "neutral.light",
    backgroundColor: "inherit",
    disabledColor: "alt.dark",
    disabledBorderColor: "alt.dark",
    disabledBackgroundColor: "inherit",
    hoverColor: "primary.main",
    hoverBorderColor: "primary.main",
    hoverBackgroundColor: "inherit",
    focusColor: "primary.main",
    focusBorderColor: "primary.main",
    focusBackgroundColor: "alt.light"
  },
  {
    variant: "text",
    fontWeight: "medium",
    color: "primary.main",
    borderColor: "inherit",
    backgroundColor: "inherit",
    disabledColor: "alt.dark",
    disabledBorderColor: "inherit",
    disabledBackgroundColor: "inherit",
    hoverColor: "primary.contrastText",
    hoverBorderColor: "inherit",
    hoverBackgroundColor: "primary.main",
    focusColor: "primary.contrastText",
    focusBorderColor: "inherit",
    focusBackgroundColor: "primary.main"
  },
  {
    variant: "outlined",
    fontWeight: "medium",
    color: "neutral.main",
    borderColor: "transparent",
    backgroundColor: "ornament.main",
    disabledColor: "ornament.light",
    disabledBorderColor: "transparent",
    disabledBackgroundColor: "inherit",
    hoverColor: "neutral.dark",
    hoverBorderColor: "transparent",
    hoverBackgroundColor: "ornament.dark",
    focusColor: "neutral.dark",
    focusBorderColor: "transparent",
    focusBackgroundColor: "ornament.dark"
  }
]

const MainButton = (props: MainButtonProps): JSX.Element => {
  const sizeProperties = SizePropertiesList[props.size];
  const typeProperties = TypePropertiesList[props.type];

  return (
    <Button type={props.submit?"submit":"button"} variant={typeProperties.variant} className={props.selected?"selected":""} sx={{
      textTransform: "none", fontSize: sizeProperties.fontSize, height: sizeProperties.height, boxShadow: 0, fontWeight: typeProperties.fontWeight,
      padding: sizeProperties.padding,
      color: typeProperties.color,
      borderColor: typeProperties.borderColor,
      backgroundColor: props.backgroundColor??typeProperties.backgroundColor,
      ":disabled": {
        color: typeProperties.disabledColor,
        borderColor: typeProperties.disabledBorderColor,
        backgroundColor: typeProperties.disabledBackgroundColor
      },
      ":hover": {
        color: typeProperties.hoverColor,
        borderColor: typeProperties.hoverBorderColor,
        backgroundColor: typeProperties.hoverBackgroundColor
      },
      "&.selected": {
        color: typeProperties.focusColor,
        borderColor: typeProperties.focusBorderColor,
        backgroundColor: typeProperties.focusBackgroundColor
      },
      ...props.sx
    }} startIcon={!props.loading && getStartIcon(props, sizeProperties.iconSize)} endIcon={!props.loading && getEndIcon(props, sizeProperties.iconSize)}
    onClick={props.action} disabled={props.disabled == undefined ? props.loading : props.disabled}
    component={(props.href?.startsWith('/')) ? Link : Button}
    to={props.href} href={props.href} target={(props.href?.startsWith('/') && !props.href?.startsWith("//")) ? "_self" : "_blank"}>
      {props.loading && loaderButton }
      {props.loading ? <Box sx={{visibility: "hidden"}}>{props.label??getIcon(props, sizeProperties.iconSize)}</Box> : <Box sx={{display: "flex", flexDirection: "column"}}>{props.label??getIcon(props, sizeProperties.iconSize)}</Box>}
    </Button>
  )
}

export const PrimaryBigButton = (props: ButtonProps): JSX.Element => {
  return <MainButton type={ButtonType.PRIMARY} size={ButtonSize.BIG} {...props}/>
}

export const PrimarySmallButton = (props: ButtonProps): JSX.Element => {
  return <MainButton type={ButtonType.PRIMARY} size={ButtonSize.SMALL} {...props}/>
}

export const SecondaryBigButton = (props: ButtonProps): JSX.Element => {
  return <MainButton type={ButtonType.SECONDARY} size={ButtonSize.BIG} {...props}/>
}

export const SecondarySmallButton = (props: ButtonProps): JSX.Element => {
  return <MainButton type={ButtonType.SECONDARY} size={ButtonSize.SMALL} {...props}/>
}

export const GhostButton = (props: ButtonProps): JSX.Element => {
  return <MainButton type={ButtonType.GHOST} size={ButtonSize.SMALL} {...props}/>
}

export const OrnamentButton = (props: ButtonProps): JSX.Element => {
  return <MainButton type={ButtonType.ORNAMENT} size={ButtonSize.SMALL} {...props}/>
}
