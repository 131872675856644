import {useTranslation} from "react-i18next";
import {FormControl, FormHelperText, MenuItem, Select, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {INPUT_TYPES} from "./formConstants";
import {TViolation} from "../../common/struct/models/TViolation";

type FormInputProps = {
  name: string,
  options?: {label: string, value: any}[],
  type?: string,
  value: any,
  setValue: (value: any) => void,
  setPristine?: (pristine: boolean) => void,
  disabled?: boolean,
  required?: boolean,
  multiline?: boolean,
  translate?: boolean,
  violations: TViolation[]
}

export default function FormInput(props: FormInputProps): JSX.Element {
  const {t} = useTranslation();
  const {name, options, type = INPUT_TYPES.DEFAULT, value, setValue, setPristine, disabled, required, multiline = false, translate = true, violations} = props;
  const [errorMessage, setErrorMessage] = useState<string|undefined>(undefined);

  const isPhone = (value: string): boolean => {
    const number = Number(value);
    return !isNaN(number) && number >= 0;
  }

  useEffect(() => {    
    setErrorMessage(violations?.find(violation => violation.field.endsWith(name))?.error);
  }, [violations]);

  const changeValue = (e: any): any => {
    const newValue = e.target.value;
    if (type == INPUT_TYPES.NUMBER && isNaN(newValue)) {
      return;
    }
    if (type == INPUT_TYPES.TELEPHONE && !isPhone(newValue)) {
      return;
    }

    setValue(newValue);
    if (setPristine) setPristine(false);
  }
  return (
    <>
      <Typography variant="body2" color="neutral.main" sx={{fontWeight: "bold", mb: 1}}>
        {t(`form.${name}`)}{required && " *"}
      </Typography>
      <FormControl fullWidth variant="outlined" sx={{mb: 4}}>
        {options && <><Select
          disabled={disabled}
          value={value}
          onChange={changeValue}
          error={errorMessage !== undefined}
          sx={{height: 56, backgroundColor: disabled ? "ornament.dark" : "inherit"}}>
          {options.map(option => <MenuItem key={`${name}-${option}`} value={option.value}>{translate?t(`form.${name}_${option.label.toLowerCase()}`):option.label}</MenuItem>)}
        </Select>
        <FormHelperText sx={{color: 'error.main'}}>{errorMessage}</FormHelperText></>
        }
        {!options && <>
          <TextField
            id={name}
            type={INPUT_TYPES.DEFAULT}
            disabled={disabled}
            multiline={multiline}
            rows={6}
            value={value}
            error={errorMessage !== undefined}
            placeholder={t(`form.${name}_helper`)}
            helperText={errorMessage}
            onChange={changeValue}
          />
        </>}
      </FormControl>
    </>
  )
}
