import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../../App";
import {Alert, Box, Card, CardContent, Menu, Typography, useMediaQuery} from '@mui/material';
import Loader from '../../components/loader/loader';
import {useTranslation} from 'react-i18next';
import {MAX_DESKTOP_SIZE} from "../../tokens/libertyTheme";
import AccountHeader from "../../patterns/header/accountHeader";
import {PUBLIC_URL} from '../../common/struct/urlManager';
import {getOperationParticipantId} from "../../common/struct/globalVar";
import PointsSideCard from './pointsSideCard';
import {SecondaryBigButton} from '../../components/buttons/mainButton';
import {IconPosition} from '../../components/buttons/buttonProps';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import {getUserReward} from '../../services/ParticipantService';
import {errorManager, manageStringError} from '../../common/methods/ApiService';
import {useNavigate} from "react-router-dom";
import {TReward} from '../../common/struct/models/TReward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import backgroundPoints from "../../assets/background_points.svg";
import RewardCard from "./rewardCard";

export default function Points(): JSX.Element {
  const {t} = useTranslation();
  const STORE = useContext<any>(AppContext);
  const [store] = STORE;
  const navigate = useNavigate();

  useEffect(() => {
    document.title = t("tab_title.points");
  }, []);

  const [rewards, setRewards] = useState<TReward[] | null>(null);
  const [rewardsFilters, setRewardsFilters] = useState<boolean | null>(null);
  const [loadingRewards, setLoadingRewards] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [pointsMenuOpen, setPointsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null|HTMLElement>(null);

  const maxWidth = (store.isMobile) ? "100vw" : MAX_DESKTOP_SIZE;
  const matchesM = useMediaQuery('(min-width:1150px)');

  const openPointsMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(pointsMenuOpen ? null : event.currentTarget);
    setPointsMenuOpen(!pointsMenuOpen);
  }
  const closePointsMenu = (): void => {
    setAnchorEl(null);
    setPointsMenuOpen(false);
  }

  useEffect(() => {
    getUserReward(getOperationParticipantId() ?? "", rewardsFilters)
      .then((data: TReward[]) => {
        setRewards(data);
        setLoadingRewards(false);
      })
      .catch((error) => {
        manageStringError(errorManager(error, t, navigate, STORE), setError, t);
        setLoadingRewards(false);
      });
  }, [rewardsFilters])

  const menu = <Box>
    <Box sx={{display: "flex", flexDirection: "column", mt: 0, backgroundColor: "ornament.light", boxShadow: "0px 6px 14px rgba(32, 34, 36, 0.25)", borderRadius: "4px", borderStyle: "solid", borderWidth: "1px", borderColor: "ornament.dark"}}>
      <Box onClick={(): void => { setRewardsFilters(true); setPointsMenuOpen(false); }} sx={{backgroundColor: rewardsFilters ? "info.light" : "ornament.light", display: "flex", alignItems: "center", px: 3, py: 2, cursor: "pointer", boxShadow: "inset 0px -1px 0px #E0E3E8", "&:hover": {backgroundColor: "info.light", "svg": {color: "info.dark"}, ".MuiTypography-body2": {color: "info.dark"}}}}>
        {rewardsFilters ? <RadioButtonCheckedIcon sx={{mr: 0, color: rewardsFilters ? "info.dark" : "neutral.main"}} /> : <RadioButtonUncheckedIcon sx={{mr: 0, color: "neutral.main"}} />}
        <Typography variant="body2" color="neutral.main" sx={{fontWeight: "bold", color: rewardsFilters ? "info.dark" : "neutral.main"}}>{t("account.points.operations.attribution")}</Typography>
      </Box>
      <Box onClick={(): void => { setRewardsFilters(false); setPointsMenuOpen(false); }} sx={{backgroundColor: rewardsFilters !== null ? "info.light" : "ornament.light", display: "flex", alignItems: "center", px: 3, py: 2, cursor: "pointer", boxShadow: "inset 0px -1px 0px #E0E3E8", "&:hover": {backgroundColor: "info.light", "svg": {color: "info.dark"}, ".MuiTypography-body2": {color: "info.dark"}}}}>
        {!rewardsFilters && rewardsFilters !== null ? <RadioButtonCheckedIcon sx={{mr: 0, color: "info.dark"}} /> : <RadioButtonUncheckedIcon sx={{mr: 0, color: "neutral.main"}} />}
        <Typography variant="body2" color="neutral.main" sx={{fontWeight: "bold", color: !rewardsFilters && rewardsFilters !== null ? "info.dark" : "neutral.main"}}>{t("account.points.operations.withdraw")}</Typography>
      </Box>
      <Box onClick={(): void => { setRewardsFilters(null); setPointsMenuOpen(false); }} sx={{display: "flex", alignItems: "center", px: 3, py: 2, cursor: "pointer", "&:hover": {backgroundColor: "info.light", "svg": {color: "info.dark"}, ".MuiTypography-body2": {color: "info.dark"}}}}>
        <AutorenewIcon sx={{mr: 0, color: "neutral.main"}} />
        <Typography variant="body2" color="neutral.main" sx={{fontWeight: "bold"}}>{t("account.points.operations.re_init")}</Typography>
      </Box>
    </Box>
  </Box>

  return (
    <Box sx={{minHeight: !store.isMobile ? "calc(100vh - 182px)" : "calc(100vh - 292px)", pb: !store.isMobile ? "40px" : "32px"}}>
      {!store.operation.shop.hidePricing && <Box>
        <AccountHeader background={backgroundPoints} title={t("account.points.title")} breadcrumb={[
          {label: t("account.my_account"), url: PUBLIC_URL.ACCOUNT},
          {label: t("account.points.title")}
        ]} />
        <Box sx={{maxWidth: maxWidth, px: !store.isMobile ? 4 : 2, display: "flex", justifyContent: "center", margin: "0 auto", mt: "-20px", position: "relative", zIndex: "1", flexDirection: matchesM ? "row" : "column"}}>
          {error && <Alert variant="filled" severity="error">{error}</Alert>}
          <Card sx={{width: "100%", maxWidth: matchesM ? "974px" : "none", mr: !store.isMobile ? 4 : "", mb: matchesM ? "0px" : 6, height: "fit-content", borderWidth: "1px", borderStyle: "solid", borderColor: "ornament.dark"}}>
            <CardContent sx={{p: store.isMobile ? 3 : 6, display: "flex", alignItems: "center", justifyContent: "space-between"}}>
              <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold"}}>{t("account.points.operations.title")}</Typography>
              {!store.isMobile ?
                <Box sx={{display: "flex"}}>
                  <SecondaryBigButton icon={rewardsFilters ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon} position={IconPosition.LEFT} label={t("account.points.operations.attribution")} sx={{mr: 2, height: "fit-content", fontSize: "14px", lineHeight: "22px", px: 3, py: 2, "span": {mr: 0}, "&:hover": {color: "info.dark", borderColor: "info.dark", backgroundColor: "info.light"}, color: rewardsFilters ? "info.dark" : "neutral.main", borderColor: rewardsFilters ? "info.dark" : "neutral.light", backgroundColor: rewardsFilters ? "info.light" : "ornament.light"}} action={(): void => setRewardsFilters(true)}/>
                  <SecondaryBigButton icon={!rewardsFilters && rewardsFilters !== null ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon} position={IconPosition.LEFT} label={t("account.points.operations.withdraw")} sx={{mr: 2, height: "fit-content", fontSize: "14px", lineHeight: "22px", px: 3, py: 2, "span": {mr: 0}, "&:hover": {color: "info.dark", borderColor: "info.dark", backgroundColor: "info.light"}, color: !rewardsFilters && rewardsFilters !== null ? "info.dark" : "neutral.main", borderColor: !rewardsFilters && rewardsFilters !== null ? "info.dark" : "neutral.light", backgroundColor: !rewardsFilters && rewardsFilters !== null ? "info.light" : "ornament.light"}} action={(): void => setRewardsFilters(false)}/>
                  <SecondaryBigButton icon={AutorenewIcon} position={IconPosition.LEFT} label={t("account.points.operations.re_init")} sx={{height: "fit-content", fontSize: "14px", lineHeight: "22px", px: 3, py: 2, "span": {mr: 0}, "svg": {transform: "rotate(90deg) scaleX(-1)"}, "&:hover": {color: "info.dark", borderColor: "info.dark", backgroundColor: "info.light"}}} action={(): void => setRewardsFilters(null)} />
                </Box>
                :
                <Box sx={{position: "relative"}}>
                  <SecondaryBigButton icon={ExpandMoreIcon} position={IconPosition.RIGHT} label={t("account.points.operations.filter_button")} sx={{height: "fit-content", fontSize: "14px", lineHeight: "22px", px: 3, py: 2, "span": {mr: 0}, "svg": {transform: pointsMenuOpen ? "rotate(180deg)" : "none"}, "&:hover": {color: "info.dark", borderColor: "info.dark", backgroundColor: "info.light"}}} action={openPointsMenu} />
                  <Menu id="menu-points" open={pointsMenuOpen} anchorEl={anchorEl} transformOrigin={{horizontal: 'right', vertical: 'top'}} anchorOrigin={{horizontal: 'right', vertical: 'bottom'}} onClose={closePointsMenu}
                    sx={{"& .MuiPaper-root": {boxShadow: 2, borderTopLeftRadius: "0", borderTopRightRadius: "0"}, "& ul": {p: "0px"}}}>
                    {menu}
                  </Menu>
                </Box>
              }
            </CardContent>
            {loadingRewards ?
              <Loader size={75} sx={{height: "100px", pt: 3, pb: 8}} />
              :
              <CardContent sx={{p: "0px !important"}}>
                {rewards?.map((reward: TReward, index: number) => <RewardCard key={index} reward={reward} isLastOne={index === rewards?.length - 1}/>)}
              </CardContent>
            }
          </Card>
          <PointsSideCard />
        </Box>
      </Box>}
    </Box>
  )
}
