import React, {useContext} from 'react';
import {AppContext} from "../../App";
import {Box, Card, Divider, Typography, useMediaQuery} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useNavigate} from "react-router-dom";
import {PUBLIC_URL} from '../../common/struct/urlManager';
import CheckIcon from '@mui/icons-material/Check';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import {PrimaryBigButton} from '../../components/buttons/mainButton';
import moment from "moment";

export default function PointsSideCard(): JSX.Element {
  const {t} = useTranslation();
  const [store] = useContext<any>(AppContext);
  const navigate = useNavigate();

  const matchesM = useMediaQuery('(min-width:1250px)');
  const matchesS = useMediaQuery('(min-width:1150px)');

  const redirectToShop = (): void => navigate(PUBLIC_URL.HOME);
  const isShopOpen = store.operation && ((moment(store.operation.startDate, 'YYYY-MM-DD HH:mm:ss').toDate() <= new Date() && !store.operation.endDate) || (moment(store.operation.startDate, 'YYYY-MM-DD HH:mm:ss').toDate() <= new Date() && moment(store.operation.endDate, 'YYYY-MM-DD HH:mm:ss').toDate() >= new Date()));

  return (
    <Card sx={{width: matchesM ? "100%" : matchesS ? "35%" : "none", maxWidth: matchesS ? "386px" : "none", ml: matchesS ? 4 : "0px", height: "fit-content", p: 4, borderWidth: "1px", borderStyle: "solid", borderColor: "ornament.dark"}}>
      <Box>
        <Typography variant="body2" sx={{mb: 0, fontWeight: 400, color: "neutral.main"}}>{t("account.points.current_date_amount", {date: new Date})}</Typography>
        <Box sx={{display: "flex"}}>
          <Typography variant="h1" color="neutral.dark" sx={{fontWeight: "bold"}}>
            {store.user && store.user.rewardPoints}
          </Typography>
          <Typography variant="h1" color="neutral.dark" sx={{fontWeight: "bold", fontSize: "22px", ml: "8px", mt: "-6px"}}>
            {t('account.points.point', {count: store.user && store.user.rewardPoints})}
          </Typography>
        </Box>
      </Box>
      {store.operation && store.operation.expirationDate &&
        <>
          <Divider sx={{backgroundColor: "ornament.dark", borderColor: "ornament.dark", width: "100%", mb: 4}} />
          {parseInt(new Number((new Date(store.operation.expirationDate).getTime() / 86400000) - (new Date().getTime() / 86400000)).toFixed(0)) > 30 ?
            <Box sx={{mt: 4}}>
              <Typography variant="body2" sx={{mb: 0, fontWeight: 400, color: "neutral.main"}}>{t("account.points.expiry_date")}</Typography>
              <Box sx={{display: "flex", py: 1, px: 2, backgroundColor: "info.light", borderRadius: 1}}>
                <CheckIcon sx={{width: "24px", height: "24px", color: "info.main", mr: 1}} />
                <Typography variant="body1" color="info.dark" sx={{fontWeight: "medium"}}>{t("account.points.full_date", {date: store.operation && new Date(store.operation.expirationDate)})}</Typography>
              </Box>
            </Box>
            : (parseInt(new Number((new Date(store.operation.expirationDate).getTime() / 86400000) - (new Date().getTime() / 86400000)).toFixed(0)) <= 30 && parseInt(new Number((new Date(store.operation.expirationDate).getTime() / 86400000) - (new Date().getTime() / 86400000)).toFixed(0)) > 0) ||
                (parseInt(new Number((new Date(store.operation.expirationDate).getTime() / 86400000) - (new Date().getTime() / 86400000)).toFixed(0)) <= 30 && new Date() < new Date(store.operation.expirationDate)) ?
              <Box sx={{mt: 4}}>
                <Typography variant="body2" sx={{mb: 0, fontWeight: 400, color: "neutral.main"}}>{t("account.points.expiry_date")}</Typography>
                <Box sx={{display: "flex", py: 1, px: 2, backgroundColor: "warning.light", borderRadius: 1}}>
                  <WarningAmberIcon sx={{width: "24px", height: "24px", color: "warning.main", mr: 1}} />
                  <Typography variant="body1" color="warning.dark" sx={{fontWeight: "medium"}}>{t("account.points.full_date", {date: store.operation && new Date(store.operation.expirationDate)})}</Typography>
                </Box>
              </Box>
              :
              <Box sx={{mt: 4}}>
                <Typography variant="body2" sx={{mb: 0, fontWeight: 400, color: "neutral.main"}}>{t("account.points.expiry_date")}</Typography>
                <Box sx={{display: "flex", py: 1, px: 2, backgroundColor: "neutral.light", borderRadius: 1}}>
                  <StopCircleIcon sx={{width: "24px", height: "24px", color: "neutral.main", mr: 1}} />
                  <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "medium"}}>{t("account.points.expiry", {date: store.operation && new Date(store.operation.expirationDate)})}</Typography>
                </Box>
              </Box>
          }
        </>
      }
      {isShopOpen && <PrimaryBigButton label={t("account.points.button")} sx={{height: "fit-content", width: "100%", mt: 4}} action={redirectToShop} />}
    </Card>
  )
}
