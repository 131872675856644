import {Alert, Box, Card, MenuItem, Select, SelectChangeEvent, Typography} from "@mui/material";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import AddIcon from "@mui/icons-material/Add";
import {IconPosition} from "../../components/buttons/buttonProps";
import React, {useContext} from "react";
import {TOperation} from "../../common/struct/models/TOperation";
import {TOperationParticipant} from "../../common/struct/models/TOperationParticipant";
import {TGift} from "../../common/struct/models/TGift";
import {TBasket} from "../../common/struct/models/TBasket";
import {useTranslation} from "react-i18next";
import GiftProcess from "./giftProcess";
import WithdrawGiftBanner from "../../assets/withdraw_gift.svg"
import GiftTopUpProcess from "./giftTopUpProcess";
import InfoAlert from "../../patterns/alerts/infoAlert";
import {StoreContext} from "../../common/struct/store";
import {AppContext} from "../../App";
import {PreviewTooltip} from "../../components/tooltip/tooltip";

interface GiftSelectorProps {
  operation: TOperation;
  user: TOperationParticipant|null;
  gift: TGift;
  basket: TBasket;
  quantity: number;
  setQuantity: (quantity: number) => void;
  addToBasket: () => void;
  isMobile: boolean;
}

export default function GiftSelector(props: GiftSelectorProps): JSX.Element {
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  const {operation, user, gift, quantity, setQuantity, addToBasket, isMobile} = props;
  const {t} = useTranslation();

  const selector = (inTopUp: boolean): JSX.Element => <Box sx={{display: "flex", mb: inTopUp ? "0px" : 4, p: inTopUp ? 4: "0px"}}>
    {store.isPreview !== null && store.isPreview ? <PreviewTooltip backgroundColor={'primary.main'} color={"primary.contrastText"}><Select value={quantity.toString()} onChange={(event: SelectChangeEvent): void => setQuantity(parseInt(event.target.value))}
      sx={{mr: 2, height: "56px", width: "80px"}} disabled={true}>
      {[1,2,3,4,5,6,7,8,9].map((key) => {
        return <MenuItem key={key} value={key}>{key}</MenuItem>
      })}
    </Select></PreviewTooltip> : <Select value={quantity.toString()} onChange={(event: SelectChangeEvent): void => setQuantity(parseInt(event.target.value))}
      sx={{mr: 2, height: "56px", width: "80px"}}>
      {[1,2,3,4,5,6,7,8,9].map((key) => {
        return <MenuItem key={key} value={key}>{key}</MenuItem>
      })}
    </Select>}
    {store.isPreview !== null && store.isPreview ? <PreviewTooltip backgroundColor={'primary.main'} color={"primary.contrastText"} sx={{width: "-webkit-fill-available"}}><PrimaryBigButton label={t("gift_modal.add_cart_button")} icon={AddIcon} position={IconPosition.LEFT} sx={{width: "100%"}}
      action={addToBasket} disabled={true} /></PreviewTooltip> : <PrimaryBigButton label={t("gift_modal.add_cart_button")} icon={AddIcon} position={IconPosition.LEFT}
      action={addToBasket} disabled={(gift.participantMonetaryComplement !== undefined && !operation.complementActivated)} sx={{width: "100%"}}/>}
  </Box>

  return <>
    {!(user && gift.participantMonetaryComplement && operation.complementActivated) && <>
      {selector(false)}
      {gift.participantMonetaryComplement && <Alert sx={{mt: -2, mb: 4}} severity="warning" variant="filled">
        {operation?.shop.hidePricing ? t("gift_modal.not_enough_points_hidden") : t("gift_modal.not_enough_points")}
      </Alert>}
      <GiftProcess isMobile={isMobile}/>
    </>}
    {user && gift.participantMonetaryComplement && operation.complementActivated && <>
      <InfoAlert text={t("gift_modal.point_amount")} isMobile={isMobile}/>
      <Card sx={{border: "1px solid", borderColor: "ornament.dark"}}>
        <Typography variant="h2" color="info.dark" sx={{fontWeight: "bold", width: "100%", p: 4, background: `url(${WithdrawGiftBanner})`, backgroundSize: "cover", backgroundPosition: "right"}}
          dangerouslySetInnerHTML={{__html: t("gift_modal.withdraw_gift")}}/>
        <GiftTopUpProcess isMobile={isMobile}/>
        {selector(true)}
      </Card>
    </>}
  </>
}
