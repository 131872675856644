import {Badge, Box, Divider, Typography} from "@mui/material";
import React, {Dispatch, SetStateAction, useState} from "react";
import {ESorting, TFilter} from "../../common/struct/models/TFilter";
import MobileUniverseMenu from "./mobileUniverseMenu";
import {TUniverse} from "../../common/struct/models/TUniverse";
import MobileFilterMenu from "./mobileFilterMenu";
import MobileSortingMenu from "./mobileSortingMenu";
import {useTranslation} from "react-i18next";

type MobileMenuProps = {
  filters: TFilter,
  setFilters: Dispatch<SetStateAction<TFilter>>,
  search: string|null,
  setSearch: Dispatch<SetStateAction<string|null>>,
  sorting: ESorting,
  setSorting: (sorting: ESorting) => void
  universes: TUniverse[],
  brands: string[],
  setReload: Dispatch<SetStateAction<boolean>>
}

export default function MobileMenu(props: MobileMenuProps): JSX.Element {
  const {t} = useTranslation();
  const {filters, setFilters, search, setSearch, sorting, setSorting, universes, brands, setReload} = props;
  const [openUniverseMenu, setOpenUniverseMenu] = useState(false);
  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [openSortingMenu, setOpenSortingMenu] = useState(false);

  const handleUniverseMenu = (): void => {
    setOpenUniverseMenu(true);
  }
  const handleFilterMenu = (): void => {
    setOpenFilterMenu(true);
  }
  const handleSortMenu = (): void => {
    setOpenSortingMenu(true);
  }

  return (
    <Box sx={{display: "flex", justifyContent: "space-evenly", backgroundColor: "ornament.dark", width: "100%", pt: 3, pb: 3}}>
      <MobileUniverseMenu open={openUniverseMenu} setOpen={setOpenUniverseMenu} universes={universes} filters={filters} setFilters={setFilters} setReload={setReload}/>
      <MobileFilterMenu open={openFilterMenu} setOpen={setOpenFilterMenu} brands={brands} filters={filters} setFilters={setFilters} search={search} setSearch={setSearch} setReload={setReload}/>
      <MobileSortingMenu open={openSortingMenu} setOpen={setOpenSortingMenu} sorting={sorting} setSorting={setSorting} setReload={setReload}/>
      <Badge badgeContent={filters.universIds.length} color="primary" componentsProps={{badge: {style: {right: -10}}}}>
        <Typography variant="caption" color="neutral.main" sx={{textTransform: "uppercase", fontWeight: 800, cursor: "pointer"}} onClick={handleUniverseMenu}>
          {t("shop.filters.universes.title")}
        </Typography>
      </Badge>
      <Divider sx={{backgroundColor: "neutral.light", borderColor: "neutral.light", width: "1px", height: "50%", mt: "6px"}} flexItem orientation="vertical"/>
      <Typography variant="caption" color="neutral.main" sx={{textTransform: "uppercase", fontWeight: 800, cursor: "pointer"}} onClick={handleFilterMenu}>
        {t("shop.filters.title")}
      </Typography>
      <Divider sx={{backgroundColor: "neutral.light", borderColor: "neutral.light", width: "1px", height: "50%", mt: "6px"}} flexItem orientation="vertical"/>
      <Typography variant="caption" color="neutral.main" sx={{textTransform: "uppercase", fontWeight: 800, cursor: "pointer"}} onClick={handleSortMenu}>
        {t("shop.filters.sorting.title")}
      </Typography>
    </Box>
  )
}
