import {Box, Dialog, DialogContent, IconButton, Menu, MenuItem, Typography} from "@mui/material";
import {SecondaryBigButton} from "../../components/buttons/mainButton";
import {PUBLIC_URL} from "../../common/struct/urlManager";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {ReactComponent as ShopBox} from "../../assets/shop_box.svg";
import PersonIcon from "@mui/icons-material/Person";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import React, {useContext} from "react";
import {AppContext} from "../../App";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {globalStoreReducer} from "../../common/methods/context/globalStoreReducer";
import {StoreActions} from "../../common/struct/store";
import {MobileTransition} from "../../pages/shop/shopProps";
import ClearIcon from "@mui/icons-material/Clear";
import {PreviewTooltip} from "../../components/tooltip/tooltip";

type UserMenuDesktopProps = {
  userMenuOpen: boolean;
  closeUserMenu: () => void;
  anchorEl: null|HTMLElement;
}

export function MenuChoice(props: {url: string, label: string, icon: JSX.Element, isLast?: boolean, disabled?: boolean}): JSX.Element {
  const {url, label, icon, isLast, disabled} = props;
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [store] = useContext<any>(AppContext);

  return (
    <MenuItem onClick={(): void => { if (!disabled) navigate(url) }} sx={{p: "0px", cursor: disabled ? "default" : "pointer", "&:hover": disabled ? {backgroundColor: "unset"} : {}}}>
      <Box sx={{mx: 4, py: 2, width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center",
        borderBottom: (!store.isMobile && isLast) ? "none" : "1px solid", borderColor: "ornament.dark", color: "neutral.main"}}>
        <Box sx={{display: "flex", mr: 1, opacity: !store.isPreview ? 1 : 0.3}}>
          {icon}
          <Typography variant="body2" color="neutral.main" sx={{ml: 1, fontWeight: "medium"}}>{t("header." + label)}</Typography>
        </Box>
        <KeyboardArrowRightIcon sx={{width: 18, height: 18, color: "neutral.main", opacity: !disabled ? 1 : 0.3}}/>
      </Box>
    </MenuItem>
  )
}

export default function UserMenu(props: UserMenuDesktopProps): JSX.Element {
  const {userMenuOpen, closeUserMenu, anchorEl} = props;
  const navigate = useNavigate();
  const {t} = useTranslation();

  const STORE = useContext<any>(AppContext);
  const [store] = STORE;

  const handleLogout = (): void => {
    navigate(PUBLIC_URL.HOME);
    globalStoreReducer(STORE, StoreActions.LOGOUT);
    closeUserMenu();
  }  

  const closeDate = (): Date|null => {
    let date: any;
    if (!store.operation.endDate && !store.operation.expirationDate) {
      return null;
    } else if (store.operation.endDate && store.operation.expirationDate && new Date(store.operation.expirationDate) >= new Date(store.operation.endDate)) {
      date = new Date(store.operation.endDate);
    } else if (store.operation.endDate && store.operation.expirationDate && new Date(store.operation.expirationDate) < new Date(store.operation.endDate)) {
      date = new Date(store.operation.expirationDate);
    } else if (store.operation.endDate && !store.operation.expirationDate) {
      date = new Date(store.operation.endDate);
    } else if (!store.operation.endDate && store.operation.expirationDate) {
      date = new Date(store.operation.expirationDate);
    }

    return date;
  }

  const isAboutToEnd = (): boolean => {
    const date: any = closeDate();
    if (!date) {
      return false;
    }

    const today: any = new Date();
    const diffDate = date - today;
    const diffDay = diffDate / (1000 * 60 * 60 * 24);

    if (diffDay > 30) {
      return false
    } else {
      return true;
    }
  }

  const menu = <Box>
    <Box sx={{display: "flex", flexDirection: "column"}}>
      <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold", mt: 3, mb: 2, mx: 4}}>
        {t("header.hello", {name: store.user?.participant?.firstName})}
      </Typography>
      {store.isPreview !== null && store.isPreview ? <PreviewTooltip backgroundColor={"primary.main"} color={"primary.contrastText"}><SecondaryBigButton label={t("header.account")} href={PUBLIC_URL.ACCOUNT} disabled={true} sx={{mx: 4, mb: 4, boxSizing: "border-box", width: "-webkit-fill-available"}}/></PreviewTooltip> : <SecondaryBigButton label={t("header.account")} href={PUBLIC_URL.ACCOUNT} sx={{mx: 4, mb: 4, boxSizing: "border-box", width: "-webkit-fill-available"}}/>}
      {isAboutToEnd() && <Box sx={{backgroundColor: "warning.light", mx: 4, mb: 2, padding: 2, borderRadius: "4px"}}><Typography variant="body2" color="warning.main" sx={{textAlign: "center"}} dangerouslySetInnerHTML={{__html: t("header.close_shop", {date: closeDate()})}}/></Box>}
      {!store.operation.shop.hidePricing && (store.isPreview !== null && store.isPreview ? <PreviewTooltip backgroundColor={"primary.main"} color={"primary.contrastText"}><MenuChoice disabled={true} url={PUBLIC_URL.POINTS} label="transactions" icon={<AccountBalanceWalletIcon sx={{color: "neutral.main"}}/>}/></PreviewTooltip> : <MenuChoice url={PUBLIC_URL.POINTS} label="transactions" icon={<AccountBalanceWalletIcon sx={{color: "neutral.main"}}/>}/>) }
      {store.isPreview !== null && store.isPreview ? <PreviewTooltip backgroundColor={"primary.main"} color={"primary.contrastText"}><MenuChoice disabled={true} url={PUBLIC_URL.ORDERS} label="orders" icon={<ShopBox fill="currentColor"/>}/></PreviewTooltip> : <MenuChoice url={PUBLIC_URL.ORDERS} label="orders" icon={<ShopBox fill="currentColor"/>}/>}
      {store.isPreview !== null && store.isPreview ? <PreviewTooltip backgroundColor={"primary.main"} color={"primary.contrastText"}><MenuChoice disabled={true} url={PUBLIC_URL.INFORMATION} label="information" icon={<PersonIcon sx={{color: "neutral.main"}}/>} isLast/></PreviewTooltip> : <MenuChoice url={PUBLIC_URL.INFORMATION} label="information" icon={<PersonIcon sx={{color: "neutral.main"}}/>} isLast/>}
    </Box>
    <MenuItem onClick={handleLogout} sx={{backgroundColor: "ornament.main", py: 2, px: 4, borderTop: "1px solid", borderColor: "ornament.dark"}}>
      <ExitToAppIcon sx={{color: "neutral.main"}}/>
      <Typography variant="body2" color="neutral.main" sx={{ml: 1}}>{t("header.logout")}</Typography>
    </MenuItem>
  </Box>

  return !store.isMobile ?
    <Menu id="menu-user" open={userMenuOpen} anchorEl={anchorEl} transformOrigin={{horizontal: 'right', vertical: 'top'}} anchorOrigin={{horizontal: 'right', vertical: 'bottom'}} onClose={closeUserMenu}
      sx={{"& .MuiPaper-root": {boxShadow: 2, borderTopLeftRadius: "0", borderTopRightRadius: "0"}, "& ul": {p: "0px"}}}>
      {menu}
    </Menu> :
    <Dialog open={userMenuOpen} fullScreen TransitionComponent={MobileTransition} sx={{mx: 8, mr: "0px", "& .MuiBackdrop-root": {backgroundColor: "#363739"}}}>
      <IconButton sx={{color: "ornament.light", position: "fixed", top: 0, left: 0, mt: 2, mx: 2, p: "0px"}} onClick={closeUserMenu}>
        <ClearIcon />
      </IconButton>
      <DialogContent sx={{display: "flex", flexDirection: "column", justifyContent: "space-between", p: "0px"}}>
        {menu}
      </DialogContent>
    </Dialog>
}
