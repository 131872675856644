import {get, getPaginated, getWithoutToken} from "../common/methods/ApiService";
import {ESorting, TFilter} from "../common/struct/models/TFilter";
import {API_URL, GLOBAL_SHOP_ID} from "../common/struct/urlManager";
import {getToken} from "../common/struct/globalVar";

export async function getShopGifts(shopId: string, page: number, giftPerPage: number, filter?: TFilter, sorting?: ESorting|null, search?: string|null, basicEndpoint?: boolean): Promise<any> {
  return getPaginated(
    `${(API_URL.GET_SHOP).replace(":" + GLOBAL_SHOP_ID, shopId)}/gifts`, page, giftPerPage, search, sorting, filter, false, basicEndpoint
  );
}

export async function getShopGift(shopId: string, giftId: number, quantity: number, basicEndpoint?: boolean): Promise<any> {
  const method = getToken() == null ? getWithoutToken : get;
  return method(`${(API_URL.GET_SHOP).replace(":" + GLOBAL_SHOP_ID, shopId)}/gifts/${giftId}?quantity=${quantity}`, false, basicEndpoint);
}

export async function getShopUniverses(shopId: string): Promise<any> {
  return getWithoutToken(`${(API_URL.GET_SHOP).replace(":" + GLOBAL_SHOP_ID, shopId)}/universes`);
}

export async function getShopBrands(shopId: string): Promise<any> {
  return getWithoutToken(`${(API_URL.GET_SHOP).replace(":" + GLOBAL_SHOP_ID, shopId)}/brands`);
}

