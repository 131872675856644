import {createTheme} from "@mui/material";
import {Theme} from "@mui/material/styles";

const shadows = [
  "none",
  "0 2px 0 0 rgb(32, 34, 36, 0.15)",
  "0 4px 4px 0 rgb(32, 34, 36, 0.15)",
  "0 6px 14px 0 rgb(32, 34, 36, 0.15)",
  "inset 0px -1px 0px #E0E3E8",
  "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  "inset 0px 1px 0px #BABFC3"
]
const noneShadows = Array(21).fill("none");

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
    alt: Palette['primary'];
    ornament: Palette['primary'];
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
    alt: PaletteOptions['primary'];
    ornament: Palette['primary'];
  }
}

export const MAX_DESKTOP_SIZE = "1400px";
export const MOBILE_SWITCH_SIZE = 800;
export const MIN_MOBILE_SIZE = "336px";

export const spacing = (index: number): number => {
  switch (index) {
  case 0:
    return 4;
  case 1:
    return 8;
  case 2:
    return 12;
  case 3:
    return 16;
  case 4:
    return 20;
  case 5:
    return 24;
  case 6:
    return 32;
  case 7:
    return 40;
  case 8:
    return 48;
  case 9:
    return 56;
  case 10:
    return 64;
  case 11:
    return 72;
  case 12:
    return 96;
  default:
    return 120;
  }
}
export const LibertyTheme = (primaryColor: string, contrastColor: string): Theme => {
  return createTheme({
    palette: {
      primary: {
        main: primaryColor,
        contrastText: contrastColor
      },
      neutral: {
        light: '#D0D3D9',
        main: '#6A6E72',
        dark: '#202224'
      },
      ornament: {
        light: '#FFFFFF',
        main: '#F7F8FA',
        dark: '#E0E3E8',
        contrastText: '#000000'
      },
      alt: {
        light: '#FEF4F3',
        main: '#F0B8B3',
        dark: '#EB8B80'
      },
      info: {
        dark: '#1857B9',
        main: '#3875D4',
        light: '#EFF5FF',
        contrastText: '#FFFFFF'
      },
      error: {
        dark: '#A31820',
        main: '#D1303A',
        light: '#F4E5E6',
        contrastText: '#202224'
      },
      warning: {
        dark: '#B94300',
        main: '#EF5700',
        light: '#FFF1E9',
        contrastText: '#202224'
      },
      success: {
        dark: '#005949',
        main: '#00826A',
        light: '#F0FAF8',
        contrastText: '#202224'
      }
    },
    spacing: spacing,
    typography: {
      fontFamily: "Mulish",
      fontWeightBold: 700,
      fontWeightMedium: 600,
      fontWeightRegular: 300,
      allVariants: {
        letterSpacing: 0
      },
      h1: {
        fontSize: "32px",
        lineHeight: "40px"
      },
      h2: {
        fontSize: "20px",
        lineHeight: "28px"
      },
      body1: {
        fontSize: "16px",
        lineHeight: "24px"
      },
      body2: {
        fontSize: "14px",
        lineHeight: "22px"
      },
      caption: {
        fontSize: "12px",
        lineHeight: "22px"
      }
    },
    // @ts-ignore shadows has 4 elements and noneShadows has 21 = 25 elements
    shadows: shadows.concat(noneShadows),
    shape: {
      borderRadius: 4
    },
    components: {
      MuiAlert: {
        styleOverrides: {
          root: {
            fontWeight: 300 // regular
          },
          filledSuccess: {
            backgroundColor: "#CCE7E1",
            color: "#005949", // success.dark
            "& .MuiAlert-icon": {
              color: "#00826A" // success.main
            }
          },
          filledError: {
            backgroundColor: "#F1D8D9",
            color: "#A31820", // error.dark
            "& .MuiAlert-icon": {
              color: "#D1303A" // error.main
            }
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            "& a": {
              textDecoration: "none",
              color: "inherit"
            }
          }
        }
      }
    }
  })
};


