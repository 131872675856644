import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../../App";
import {TBasket} from "../../common/struct/models/TBasket";
import {isAuthenticated} from "../../common/struct/globalVar";
import {Grid} from "@mui/material";
import BasketEmpty from "./basketEmpty";
import NonEmptyBasket from './nonEmptyBasket';
import {useNavigate} from "react-router-dom";
import {PUBLIC_URL} from "../../common/struct/urlManager";
import Loader from "../../components/loader/loader";
import {StoreContext} from "../../common/struct/store";
import {useTranslation} from 'react-i18next';


export default function Basket(): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [store] = useContext<StoreContext>(AppContext);
  const [gifts, setGifts] = useState<TBasket>(store.basket);

  useEffect(() => {
    document.title = t("tab_title.basket1");
  }, []);

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate(PUBLIC_URL.HOME);
    }
  }, [])

  useEffect(() => {
    setGifts(store.basket);
  }, [store])

  const paddingH = store.isMobile ? 2 : 5;
  const paddingV = store.isMobile ? 4 : 6;
  return !store.user ? <Loader size={75} sx={{height: '100vh', width: '100vw'}}/> :
    <Grid container justifyContent="center" columns={10} columnSpacing={5} sx={{py: paddingV, px: paddingH}}>
      {gifts.length === 0 && <BasketEmpty/>}
      {gifts.length > 0 && store.user && <NonEmptyBasket/>}
    </Grid>
}
