import {Box, Card, CardMedia, Grid, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {AppContext} from "../../App";
import {MAX_DESKTOP_SIZE} from "../../tokens/libertyTheme";
import LoginWithPassword from "./LoginWithPassword";
import {PasswordForget} from "./passwordForget";

export default function LoginPage(): JSX.Element {
  const {t} = useTranslation();
  const [store] = useContext<any>(AppContext);
  const operation = store.operation;
  const isMobile = store.isMobile;

  useEffect(() => {
    document.title = t("tab_title.login");
  }, []);

  const [showForgetPassword, setShowForgetPassword] = useState(false);

  const componentPadding = (isMobile) ? "0px" : 4;
  const logoMargin = (operation.logoUrl) ? "256px" : 6;
  const bannerDesktopMargin = (operation.bannerUrl) ? "-204px" : "-89px";
  const bannerMobileMargin = (operation.bannerUrl) ? "-60px" : 0;
  const maxWidth = (isMobile) ? "100vw" : MAX_DESKTOP_SIZE;

  const mobileTitle = <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "ornament.light"}}>
    {operation.logoUrl && <Card sx={{boxShadow: "none", width: 144, height: 144, display: "flex", alignItems: "center", mt: bannerMobileMargin}}>
      <CardMedia component="img" image={operation.logoUrl} sx={{mr: "auto", mx: "auto", maxWidth: "100%", maxHeight: "100%", width: "auto !important", height: "auto !important"}} alt="operation-logo"/>
    </Card>}
    <Typography variant="h1" color="neutral.dark" sx={{fontWeight: "bold", mt: 4, px: 5, pb: 3}}>{operation.name}</Typography>
  </Box>

  const desktopTitle = <>
    <Box sx={{display: "flex", pt: 4, pb: 4, px: logoMargin, pr: 6, alignItems: "center", justifyContent: "space-between", backgroundColor: "ornament.light", boxShadow: 1}}>
      <Typography variant="h1" color="neutral.dark" sx={{fontWeight: "bold", px: 5}}>{operation.name}</Typography>
    </Box>
    {operation.logoUrl && <Card sx={{boxShadow: "none", width: 204, height: 204, display: "flex", alignItems: "center", mx: 6, mt: bannerDesktopMargin, "& img": {width: "100%"}}}>
      <CardMedia component="img" image={operation.logoUrl} sx={{mr: "auto", mx: "auto", maxWidth: "204px", maxHeight: "204px", width: "auto !important", height: "auto !important"}} alt="operation-logo"/>
    </Card>}
  </>

  return (
    <Box sx={{display: "flex", flexDirection: "column", zIndex: 0, p: componentPadding}}>
      <Box sx={{pb: !store.isMobile ? 7 : 6}}>
        <Box sx={{display: "flex", flexDirection: "column", width: "100%", maxWidth: maxWidth, mx: "auto"}}>
          <Box sx={{display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%",
            overflowX: "auto", borderRadius: "4px", borderWidth: "1px", borderColor: "ornament.dark", borderStyle: "solid", "& .MuiCardContent-root:last-child": {p: "0px"}}}>
            {operation.bannerUrl && <Box sx={{minHeight: "104px", display: "flex", boxShadow: "unset"}}><CardMedia sx={{mb: -1, width: "100%", height: "fit-content", alignSelf: "end"}} component="img" image={operation.bannerUrl} alt="operation-banner"/></Box>}
            {isMobile && mobileTitle}
            {!isMobile && desktopTitle}
          </Box>
        </Box>
        <Grid container columns={10} columnSpacing={store.isMobile ? 0 : 5} sx={{maxWidth: maxWidth, mx: "auto", mt: !store.isMobile ? 7 : 3}}>
          {showForgetPassword ?
            <PasswordForget showForgetPassword={showForgetPassword} setShowForgetPassword={setShowForgetPassword}/>
            : <LoginWithPassword showForgetPassword={showForgetPassword} setShowForgetPassword={setShowForgetPassword}/>
          }
        </Grid>
      </Box>
    </Box>
  )
}
