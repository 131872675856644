import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {TOrderLine} from "../../common/struct/models/TOrder";
import {Box, Divider, Link, Typography} from "@mui/material";
import CardMediaLoader from "../../components/image/cardMediaLoader";
import boxIcon from "../../assets/box_icon.svg";
import {AppContext} from "../../App";
import {PrimarySmallButton} from "../../components/buttons/mainButton";
import OrderLineStatus from "../order/orderLineStatus";

OrderLineCard.defaultProps = {
  details: false,
}

export default function OrderLineCard(props: {line: TOrderLine, lineLength: number, index: number, details: boolean}): JSX.Element {
  const {t} = useTranslation();
  const [store] = useContext<any>(AppContext);

  const {line, lineLength, index, details} = props;

  return (
    <>
      {!store.isMobile ?
        <Box sx={{borderWidth: "1px", borderStyle: "solid", borderColor: "ornament.dark", borderRadius: '4px', mb: `${index !== lineLength-1 ? "16px" : "0px"}`}}>
          {lineLength > 1 && <Box sx={{p: "15px 12px 15px 12px", display: "flex", alignItems: "center", justifyContent: "space-between", borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "ornament.dark"}}>
            <Box sx={{display: "flex", width: "100%", alignItems: "center"}}>
              <img src={boxIcon}/>
              <Typography variant="body2" color="neutral.main" sx={{fontWeight: "medium", ml: 1}}>{t("orders.order.count", {count: (index+1)})}</Typography>
            </Box>
            {details && !store.operation.shop.hidePricing &&
              <Box sx={{display: "flex"}}>
                <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", height: "22px"}}>
                  {line.pointsPrice*line.quantity}
                </Typography>
                <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", fontSize: "14px", ml: "5px", mt: "-4px", height: "22px"}}>
                  {t('orders.order.point', {count: line.pointsPrice*line.quantity})}
                </Typography>
              </Box>
            }
          </Box>}
          <Box sx={{display: "flex", p: 2, mb: "0px"}}>
            <CardMediaLoader image={line.mainImageUrl} isMobile={false} sx={{height: "64px", width: "64px", m: "0px"}}/>
            <Box sx={{ml: "16px", width: "100%"}}>
              <Box sx={{width: "100%", height: "100%"}}>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", mb: 1, height: `${!details ? "100%" : "fit-content"}`}}>
                  <Box sx={{display: "flex", alignItems: "center"}}>
                    <Typography variant="body1" color="neutral.dark" sx={{mr: 2, wordBreak: "break-all", whiteSpace: "normal"}}>
                      {line.name}
                    </Typography>
                    {details &&
                      <>
                        <Divider orientation="vertical" sx={{backgroundColor: "ornament.dark", borderColor: "ornament.dark", width: "1px", height: "12px", mr: 2}}/>
                        <Typography variant="body1" color="neutral.dark" sx={{mr: 2}}>
                          {"x" + line.quantity}
                        </Typography>
                      </>
                    }
                  </Box>
                  {details && lineLength === 1 && !store.operation.shop.hidePricing && <Box sx={{display: "flex"}}>
                    <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold"}}>
                      {line.pointsPrice * line.quantity}
                    </Typography>
                    <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", fontSize: "14px", ml: "5px", mt: "-4px"}}>
                      {t('orders.order.point', {count: line.pointsPrice*line.quantity})}
                    </Typography>
                  </Box>}
                </Box>
                {details &&
                  <>
                    <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", mb: 1, mr: 2}}>
                      <Box sx={{display: "flex"}}>
                        <Typography variant="body2" color="neutral.main" sx={{mr: 0}}>
                          {t("orders.order.order_number")}
                        </Typography>
                        <Typography variant="body2" color="neutral.dark">
                          {line.odooOrderName}
                        </Typography>
                      </Box>
                      {(line.odooTrackingReference || line.odooTrackingUrl) &&
                        <>
                          <Box sx={{display: "flex", mb: 2}}>
                            <Typography variant="body2" color="neutral.main" sx={{mr: 0}}>
                              {t("orders.order.tracking_number")}
                            </Typography>
                            {!line.odooTrackingUrl ?
                              <Typography variant="body2" color="neutral.dark">
                                {line.odooTrackingReference}
                              </Typography>
                              :
                              <Link href={line.odooTrackingUrl} target="_blank" sx={{textDecorationColor: store.theme.palette.neutral.dark}}>
                                <Typography variant="body2" color="neutral.dark">
                                  {line.odooTrackingReference ? line.odooTrackingReference : t("orders.order.track")}
                                </Typography>
                              </Link>
                            }
                          </Box>
                        </>
                      }
                    </Box>
                    <OrderLineStatus status={line.status}/>
                  </>
                }
              </Box>
            </Box>
          </Box>
        </Box>
        :
        <Box sx={{borderWidth: "1px", borderStyle: "solid", borderColor: "ornament.dark", borderRadius: '4px', mb: `${index !== lineLength-1 ? "16px" : "0px"}`}}>
          {lineLength > 1 && <Box sx={{p: "15px 12px 15px 12px", display: "flex", justifyContent: "space-between", borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "ornament.dark"}}>
            <Box sx={{display: "flex", width: "100%", alignItems: "center"}}>
              <img src={boxIcon}/>
              <Typography variant="body2" color="neutral.main" sx={{fontWeight: "medium", ml: 1}}>{t("orders.order.count", {count: (index+1)})}</Typography>
            </Box>
            {details && !store.operation.shop.hidePricing &&
              <Box sx={{display: "flex"}}>
                <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", height: "22px"}}>
                  {line.pointsPrice*line.quantity}
                </Typography>
                <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", fontSize: "14px", ml: "5px", mt: "-4px", height: "22px"}}>
                  {t('orders.order.point', {count: line.pointsPrice})}
                </Typography>
              </Box>
            }
          </Box>}
          <Box sx={{p: 2}}>
            <Box sx={{display: "flex"}}>
              <CardMediaLoader image={line.mainImageUrl} isMobile={false} sx={{height: "94px", width: "94px", m: "0px"}}/>
              <Box sx={{ml: "16px", width: "100%"}}>
                <Box sx={{display: "flex", width: "100%", justifyContent: "space-between", mb: 1, alignItems: "center", height: `${details ? "fit-content" : "100%"}`}}>
                  <Typography variant="body1" color="neutral.dark" sx={{wordBreak: "break-all", whiteSpace: "normal"}}>
                    {line.name}
                  </Typography>
                  {details && <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold", ml: 1}}>
                    {"x" + line.quantity}
                  </Typography>}
                </Box>
                {details &&
                  <Box sx={{mb: 3}}>
                    {line.odooOrderName && <Box sx={{display: "flex", flexWrap: "wrap", mb: 1}}>
                      <Typography variant="body2" color="neutral.main" sx={{mr: 0}}>
                        {t("orders.order.order_number")}
                      </Typography>
                      <Typography variant="body2" color="neutral.dark">
                        {line.odooOrderName}
                      </Typography>
                    </Box>}
                    {(line.odooTrackingReference || line.odooTrackingUrl) && <Box sx={{display: "flex", flexWrap: "wrap", mb: 1}}>
                      <Typography variant="body2" color="neutral.main" sx={{mr: 0}}>
                        {t("orders.order.tracking_number")}
                      </Typography>
                      {!line.odooTrackingUrl ?
                        <Typography variant="body2" color="neutral.dark">
                          {line.odooTrackingReference}
                        </Typography>
                        :
                        <Link href={line.odooTrackingUrl} target="_blank" sx={{textDecorationColor: store.theme.palette.primary.main}}>
                          <Typography variant="body2" color="neutral.dark">
                            {line.odooTrackingReference ? line.odooTrackingReference : t("orders.order.track")}
                          </Typography>
                        </Link>
                      }
                    </Box>}
                    <OrderLineStatus status={line.status}/>
                    {lineLength <= 1 && !store.operation.shop.hidePricing && <Box sx={{display: "flex", mt: 2}}>
                      <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", height: "22px"}}>
                        {line.pointsPrice*line.quantity}
                      </Typography>
                      <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", fontSize: "14px", ml: "5px", mt: "-4px", height: "22px"}}>
                        {t('orders.order.point', {count: line.pointsPrice*line.quantity})}
                      </Typography>
                    </Box>}
                  </Box>
                }
              </Box>
            </Box>
            {details && <PrimarySmallButton label={t("orders.order.problem")} sx={{width: "100%", height: "fit-content"}} />}
          </Box>
        </Box>
      }
    </>
  )
}
