import React, {MouseEventHandler} from "react";
import rightNav from "../../assets/right_nav.svg";

export default function RightNav(props: {disabled: boolean, onClick: MouseEventHandler<HTMLElement>}): JSX.Element {
  return (
    <button
      type="button"
      className="image-gallery-icon image-gallery-right-nav"
      disabled={props.disabled}
      onClick={props.onClick}
      aria-label="Previous Slide"
    >
      <img src={rightNav}/>
    </button>
  );
}
