import React from "react";
import {Box} from "@mui/system";

export function WithdrawGiftBanner(props: {sx?: any}): JSX.Element {
  return (
    <Box sx={{display: "inline-block", position: "relative", width: "100%", verticalAlign: "middle", overflow: "hidden", ...props.sx && props.sx}}>
      <svg viewBox="0 0 448 96" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" style={{display: "inline-block", position: "absolute", bottom: "0", left: "0", minHeight: "96px"}}>
        <g clipPath="url(#clip0_1569_80366)">
          <path d="M0 3C0 1.34315 1.34315 0 3 0H445C446.657 0 448 1.34315 448 3V96H0V3Z" fill="#EFF5FF"/>
          <path d="M117.5 452.092C249.772 452.092 357 342.517 357 207.348C357 72.1793 249.772 -37.3966 117.5 -37.3966C-14.7722 -37.3966 -122 72.1793 -122 207.348C-122 342.517 -14.7722 452.092 117.5 452.092Z" fill="#C1D3F0"/>
          <path d="M315.525 26.498L262.154 28.974C260.179 29.0656 258.652 30.7361 258.743 32.7049L260.095 62.088C260.185 64.0571 261.859 65.579 263.834 65.4869L317.205 63.0114C319.179 62.9192 320.706 61.249 320.616 59.2799L319.263 29.8972C319.173 27.9282 317.499 26.4063 315.525 26.498Z" fill="#79A2E4"/>
          <path d="M319.311 30.9002L258.79 33.708L259.119 40.8548L319.64 38.047L319.311 30.9002Z" fill="#3875D4"/>
          <path d="M173.781 116.173C174.5 115.693 228.709 83.4223 234.612 78.6781C236.043 77.5279 236.792 75.7167 237.055 74.6006C238.092 70.2032 238.515 63.0086 238.885 61.8829C239.345 60.4766 240.212 55.1194 246.534 51.9597C252.856 48.8001 259.282 45.6875 259.282 45.6875L259.857 57.301C259.857 57.301 266.3 49.9377 267.934 48.1212C269.236 46.672 271.247 44.5479 273.492 46.0814C275.447 47.4173 274.342 51.2484 271.741 57.4883C269.14 63.7275 267.936 65.1813 267.936 65.1813L282.318 64.5277C282.318 64.5277 280.693 67.8012 273.987 72.8838C267.017 78.1607 265.32 78.3541 262.918 87.8697C260.813 96.207 248.489 104.343 242.422 108.24C236.354 112.138 201.857 134.536 201.857 134.536L173.781 116.173Z" fill="white"/>
          <path d="M381.854 27.2698L377.118 25.9709C371.288 24.3842 366.232 22.8213 363.43 19.7495C362.594 18.8515 362.079 17.7025 361.967 16.4821C361.855 15.2617 362.152 14.0387 362.811 13.0043C363.517 11.8427 364.584 10.9427 365.85 10.441C367.116 9.93936 368.511 9.86343 369.825 10.2248C371.177 10.5841 373.371 11.7076 376.784 17.2774C377.877 19.0726 378.878 20.9222 379.784 22.8191L381.854 27.2698ZM368.153 14.5607C367.861 14.5621 367.575 14.638 367.322 14.7813C367.068 14.9246 366.856 15.1303 366.705 15.379C366.559 15.5743 366.489 15.8155 366.507 16.0584C366.525 16.3013 366.63 16.5296 366.803 16.7016C368.055 18.074 370.362 19.1348 373.143 20.0677C373.054 19.9205 372.965 19.7733 372.874 19.6348C370.065 15.0564 368.704 14.6559 368.648 14.6365C368.487 14.5901 368.32 14.5646 368.153 14.5607Z" fill="#4C87E4"/>
          <path d="M375.8 27.2697L377.871 22.8298C378.776 20.9329 379.777 19.0833 380.871 17.2881C384.283 11.7183 386.478 10.5948 387.832 10.2355C389.145 9.87427 390.54 9.95031 391.805 10.452C393.071 10.9537 394.138 11.8536 394.844 13.015C395.502 14.0494 395.799 15.2724 395.687 16.4928C395.575 17.7132 395.061 18.8622 394.225 19.7602C391.423 22.832 386.369 24.3949 380.537 25.9817L375.8 27.2697ZM389.497 14.5606C389.33 14.5617 389.163 14.5843 389.002 14.6277C388.946 14.6428 387.578 15.0498 384.757 19.6585C384.672 19.7949 384.589 19.9313 384.507 20.0676C387.288 19.1347 389.595 18.0739 390.847 16.7015C391.02 16.5294 391.125 16.3013 391.143 16.0587C391.161 15.816 391.092 15.5748 390.947 15.3788C390.796 15.1302 390.583 14.9245 390.329 14.7812C390.075 14.6379 389.789 14.562 389.497 14.5606Z" fill="#4C87E4"/>
          <path d="M397.939 28.8198H361.936C360.09 28.8198 358.593 30.3123 358.593 32.1535V59.5998C358.593 61.4412 360.09 62.9337 361.936 62.9337H397.939C399.786 62.9337 401.282 61.4412 401.282 59.5998V32.1535C401.282 30.3123 399.786 28.8198 397.939 28.8198Z" fill="#79A2E4"/>
          <path d="M402.513 23.7891H357.364C356.727 23.7891 356.21 24.3046 356.21 24.9407V31.9393C356.21 32.5753 356.727 33.0909 357.364 33.0909H402.513C403.15 33.0909 403.667 32.5753 403.667 31.9393V24.9407C403.667 24.3046 403.15 23.7891 402.513 23.7891Z" fill="#3875D4"/>
          <path d="M382.931 22.8301H376.944V62.8773H382.931V22.8301Z" fill="#4C87E4"/>
          <path d="M392.554 51.5458C388.805 51.7793 375.969 52.0463 375.25 52.2474C372.385 53.036 372.951 56.5105 376.589 57.9025C378.065 58.4688 383.408 58.6959 387.413 58.787C386.722 59.4521 385.987 60.0702 385.215 60.6377C383.127 62.1868 380.262 62.9778 378.647 62.9195C378.647 62.9195 376.89 63.0995 373.774 62.7249C370.658 62.3508 366.375 61.6557 366.375 61.6557C366.375 61.6557 359.415 59.0446 357.025 58.787L356.696 58.757C355.906 58.3477 355.095 57.9796 354.269 57.6526C352.436 56.9504 351.18 56.6887 349.514 57.1515C348.262 57.5003 346.944 58.5859 348.236 60.2167C349.781 62.1632 356.206 66.0547 360.893 68.5488C369.014 72.8724 380.512 73.804 384.269 73.4764C394.2 72.6107 400.278 70.5542 405.52 69.4779C408.703 68.8246 411.735 70.1072 411.735 70.1072C411.735 70.1072 454.037 90.8169 469.397 90.0242C474.231 89.7748 485.819 75.2266 487.406 68.5488C490.862 54.0076 479.726 40.9005 479.726 40.9005C479.726 40.9005 468.027 67.3396 461.717 65.7265C441.085 60.4513 401.785 50.9648 392.554 51.5458Z" fill="#3875D4"/>
        </g>
        <defs>
          <clipPath id="clip0_1569_80366">
            <path d="M0 3C0 1.34315 1.34315 0 3 0H445C446.657 0 448 1.34315 448 3V96H0V3Z" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </Box>
  )
}

export function SelectGiftBanner(props: {sx?: any}): JSX.Element {
  return (
    <Box sx={{...props.sx && props.sx}}>
      <svg width={"100%"} height={"100%"} viewBox="0 0 122 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="121.333" height="46" rx="4" fill="#EFF5FF"/>
        <path d="M60.4132 21.0696L54.86 12.3931C54.6191 12.0228 54.2134 11.8376 53.8077 11.8376C53.402 11.8376 52.9963 12.0228 52.7554 12.4064L47.2022 21.0696H41.1292C40.4319 21.0696 39.8613 21.6648 39.8613 22.3922C39.8613 22.5112 39.874 22.6303 39.912 22.7493L43.1324 35.0101C43.424 36.1211 44.4002 36.9411 45.5667 36.9411H62.0487C63.2151 36.9411 64.1914 36.1211 64.4957 35.0101L67.716 22.7493L67.7541 22.3922C67.7541 21.6648 67.1835 21.0696 66.4862 21.0696H60.4132ZM50.0041 21.0696L53.8077 15.25L57.6112 21.0696H50.0041ZM53.8077 31.6506C52.4131 31.6506 51.272 30.4602 51.272 29.0053C51.272 27.5504 52.4131 26.3601 53.8077 26.3601C55.2023 26.3601 56.3434 27.5504 56.3434 29.0053C56.3434 30.4602 55.2023 31.6506 53.8077 31.6506Z" fill="#3875D4"/>
        <ellipse cx="68.6666" cy="19" rx="10" ry="10" fill="#C1D3F0" stroke="#EFF5FF" strokeWidth="2"/>
        <g clipPath="url(#clip0_4971_193191)">
          <path d="M71.5833 19.4167H69.0833V21.9167H68.25V19.4167H65.75V18.5834H68.25V16.0834H69.0833V18.5834H71.5833V19.4167Z" fill="#3875D4" stroke="#3875D4" strokeWidth="0.5"/>
        </g>
        <defs>
          <clipPath id="clip0_4971_193191">
            <rect width="10" height="10" fill="white" transform="translate(63.6666 14)"/>
          </clipPath>
        </defs>
      </svg>
    </Box>
  )
}

export function SelectGiftIcon(props: {sx?: any}): JSX.Element {
  return (
    <Box sx={{...props.sx && props.sx}}>
      <svg width={"100%"} height={"100%"} viewBox="0 0 201 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="201" height="48" rx="4" fill="#F7F8FA"/>
        <rect x="41" y="42" width="118" height="6" rx="3" fill="#E0E3E8"/>
        <path d="M107.564 24.0348L100.263 12.9747C99.9468 12.5026 99.4134 12.2666 98.88 12.2666C98.3466 12.2666 97.8133 12.5026 97.4966 12.9916L90.1961 24.0348H82.2122C81.2955 24.0348 80.5454 24.7935 80.5454 25.7208C80.5454 25.8725 80.5621 26.0242 80.6121 26.176L84.8457 41.8051C85.2291 43.2213 86.5125 44.2666 88.0459 44.2666H109.714C111.248 44.2666 112.531 43.2213 112.931 41.8051L117.165 26.176L117.215 25.7208C117.215 24.7935 116.465 24.0348 115.548 24.0348H107.564ZM93.8797 24.0348L98.88 16.6164L103.88 24.0348H93.8797ZM98.88 37.5227C97.0466 37.5227 95.5465 36.0053 95.5465 34.1507C95.5465 32.2961 97.0466 30.7787 98.88 30.7787C100.713 30.7787 102.214 32.2961 102.214 34.1507C102.214 36.0053 100.713 37.5227 98.88 37.5227Z" fill="#6A6E72"/>
        <ellipse cx="113.166" cy="21" rx="11.0294" ry="11" fill="#E0E3E8" stroke="#F7F8FA" strokeWidth="3"/>
        <rect width="12" height="12" transform="translate(107 15)" fill="#E0E3E8"/>
        <path d="M116.5 21.5H113.5V24.5H112.5V21.5H109.5V20.5H112.5V17.5H113.5V20.5H116.5V21.5Z" fill="#6A6E72" stroke="#6A6E72" strokeWidth="0.5"/>
      </svg>
    </Box>
  )
}

export function SelectLocationIcon(props: {sx?: any}): JSX.Element {
  return (
    <Box sx={{...props.sx && props.sx}}>
      <svg width={"100%"} height={"100%"} viewBox="0 0 201 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="201" height="48" rx="4" fill="#F7F8FA"/>
        <rect x="39" y="42" width="118" height="6" rx="3" fill="#E0E3E8"/>
        <path d="M87.3548 12C81.0772 12 76 17.008 76 23.2C76 31.6 87.3548 44 87.3548 44C87.3548 44 98.7097 31.6 98.7097 23.2C98.7097 17.008 93.6324 12 87.3548 12ZM87.3548 27.2C85.1163 27.2 83.2995 25.408 83.2995 23.2C83.2995 20.992 85.1163 19.2 87.3548 19.2C89.5934 19.2 91.4101 20.992 91.4101 23.2C91.4101 25.408 89.5934 27.2 87.3548 27.2Z" fill="#6A6E72"/>
        <ellipse cx="108" cy="21" rx="11" ry="11" fill="#E0E3E8" stroke="#F7F8FA" strokeWidth="3"/>
        <rect width="12" height="12" transform="translate(102 15)" fill="#E0E3E8"/>
        <path d="M111.5 21.5H108.5V24.5H107.5V21.5H104.5V20.5H107.5V17.5H108.5V20.5H111.5V21.5Z" fill="#6A6E72" stroke="#6A6E72" strokeWidth="0.5"/>
      </svg>
    </Box>
  )
}

export function PayDifferenceBanner(props: {sx?: any}): JSX.Element {
  return (
    <Box sx={{...props.sx && props.sx}}>
      <svg width={"100%"} height={"100%"} viewBox="0 0 122 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.333374" width="121.333" height="46" rx="4" fill="#EFF5FF"/>
        <path d="M64.422 16.2H43.3446C41.8824 16.2 40.7231 17.357 40.7231 18.8L40.71 34.4C40.71 35.843 41.8824 37 43.3446 37H64.422C65.8842 37 67.0567 35.843 67.0567 34.4V18.8C67.0567 17.357 65.8842 16.2 64.422 16.2ZM64.422 34.4H43.3446V26.6H64.422V34.4ZM64.422 21.4H43.3446V18.8H64.422V21.4Z" fill="#3875D4"/>
        <circle cx="71.29" cy="19" r="10" fill="#C1D3F0" stroke="#EFF5FF" strokeWidth="2"/>
        <g clipPath="url(#clip0_4971_193202)">
          <path d="M71.96 21.7083C70.9141 21.7083 70.01 21.1167 69.56 20.25H71.96V19.4167H69.285C69.2641 19.2792 69.2516 19.1417 69.2516 19C69.2516 18.8583 69.2641 18.7208 69.285 18.5833H71.96V17.75H69.56C70.01 16.8833 70.9183 16.2917 71.96 16.2917C72.6308 16.2917 73.2475 16.5375 73.7225 16.9458L74.46 16.2083C73.7975 15.6125 72.9183 15.25 71.96 15.25C70.3266 15.25 68.9433 16.2958 68.4266 17.75H66.96V18.5833H68.235C68.2183 18.7208 68.21 18.8583 68.21 19C68.21 19.1417 68.2183 19.2792 68.235 19.4167H66.96V20.25H68.4266C68.9433 21.7042 70.3266 22.75 71.96 22.75C72.9225 22.75 73.7975 22.3875 74.46 21.7917L73.7183 21.0542C73.2475 21.4625 72.635 21.7083 71.96 21.7083Z" fill="#3875D4"/>
        </g>
        <defs>
          <clipPath id="clip0_4971_193202">
            <rect width="10" height="10" fill="white" transform="translate(65.71 14)"/>
          </clipPath>
        </defs>
      </svg>

    </Box>
  )
}

export function GiftDeliveredBanner(props: {sx?: any}): JSX.Element {
  return (
    <Box sx={{...props.sx && props.sx}}>
      <svg width={"100%"} height={"100%"} viewBox="0 0 122 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.666748" width="121.333" height="46" rx="4" fill="#EFF5FF"/>
        <g clipPath="url(#clip0_1569_80409)">
          <path d="M58.9207 27.4C58.2375 27.4016 57.5653 27.2152 56.9681 26.8583C56.3708 26.5015 55.8685 25.9861 55.5088 25.3613L55.3334 25.0593L55.158 25.3613C54.6525 26.2345 53.8742 26.8852 52.9629 27.1968C52.0515 27.5084 51.0668 27.4603 50.1854 27.0613L43.2474 23.938V31.112C43.2475 31.6017 43.3907 32.0789 43.6569 32.4761C43.9231 32.8734 44.2987 33.1705 44.7306 33.3253L54.6473 36.8813C55.0928 37.0396 55.5739 37.0396 56.0195 36.8813L65.9361 33.3253C66.368 33.1705 66.7436 32.8734 67.0098 32.4761C67.276 32.0789 67.4193 31.6017 67.4193 31.112V23.938L60.4814 27.0667C59.987 27.2886 59.4564 27.4019 58.9207 27.4Z" fill="#3875D4"/>
          <path d="M67.128 13L55.616 9.04537C55.4321 8.98227 55.2346 8.98227 55.0507 9.04537L43.5387 13L55.3333 18.314L67.128 13Z" fill="#C1D3F0"/>
          <path d="M71.0587 18.8701L68.5392 14.5368L55.6922 20.3248C55.5785 20.3759 55.4565 20.4023 55.3333 20.4023C55.2101 20.4023 55.0881 20.3759 54.9744 20.3248L42.1274 14.5334L39.6079 18.8668C39.5365 18.9897 39.4907 19.1279 39.4739 19.2718C39.457 19.4157 39.4694 19.5618 39.5103 19.7C39.5511 19.8382 39.6195 19.9652 39.7105 20.0721C39.8015 20.1791 39.9131 20.2635 40.0375 20.3194L50.9018 25.2181C51.3763 25.4338 51.9068 25.4601 52.3977 25.2922C52.8886 25.1244 53.3076 24.7735 53.5793 24.3028L55.3314 21.2868L57.0836 24.3028C57.3552 24.7735 57.7743 25.1244 58.2652 25.2922C58.7561 25.4601 59.2866 25.4338 59.7611 25.2181L70.6273 20.3228C70.7517 20.2668 70.8632 20.1824 70.9543 20.0755C71.0453 19.9685 71.1136 19.8415 71.1545 19.7033C71.1953 19.5651 71.2078 19.419 71.1909 19.2751C71.174 19.1313 71.1283 18.9931 71.0568 18.8701H71.0587Z" fill="#3875D4"/>
          <ellipse cx="69.8334" cy="19" rx="10" ry="10" fill="#C1D3F0" stroke="#EFF5FF" strokeWidth="2"/>
          <path d="M68.5833 20.75L66.8333 19L66.25 19.5834L68.5833 21.9167L73.5833 16.9167L73 16.3334L68.5833 20.75Z" fill="#3875D4" stroke="#3875D4" strokeWidth="0.5"/>
        </g>
        <defs>
          <clipPath id="clip0_1569_80409">
            <rect width="43" height="28" fill="white" transform="translate(39.8334 9)"/>
          </clipPath>
        </defs>
      </svg>
    </Box>
  )
}

export function GiftDeliveredIcon(props: {sx?: any}): JSX.Element {
  return (
    <Box sx={{...props.sx && props.sx}}>
      <svg width={"100%"} height={"100%"} viewBox="0 0 201 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="201" height="48" fill="#F7F8FA"/>
        <rect x="41" y="42" width="118" height="6" rx="3" fill="#E0E3E8"/>
        <g clipPath="url(#clip0_1639_81894)">
          <path d="M98.2331 33.0286C97.4523 33.0305 96.6841 32.8174 96.0015 32.4095C95.319 32.0017 94.7448 31.4127 94.3338 30.6987L94.1333 30.3535L93.9328 30.6987C93.3551 31.6966 92.4657 32.4403 91.4242 32.7964C90.3826 33.1524 89.2572 33.0976 88.2499 32.6416L80.3208 29.072V37.2709C80.3208 37.8305 80.4846 38.3759 80.7888 38.8299C81.093 39.2839 81.5223 39.6234 82.0158 39.8004L93.3492 43.8644C93.8584 44.0453 94.4082 44.0453 94.9174 43.8644L106.251 39.8004C106.744 39.6234 107.174 39.2839 107.478 38.8299C107.782 38.3759 107.946 37.8305 107.946 37.2709V29.072L100.017 32.6476C99.4518 32.9013 98.8453 33.0308 98.2331 33.0286Z" fill="#6A6E72"/>
          <path d="M107.613 16.5715L94.4564 12.0519C94.2463 11.9798 94.0205 11.9798 93.8104 12.0519L80.6538 16.5715L94.1334 22.6447L107.613 16.5715Z" fill="#6A6E72"/>
          <path d="M112.105 23.2802L109.226 18.3278L94.5436 24.9426C94.4136 25.0011 94.2742 25.0312 94.1334 25.0312C93.9926 25.0312 93.8532 25.0011 93.7233 24.9426L79.041 18.324L76.1616 23.2763C76.0799 23.4169 76.0276 23.5748 76.0083 23.7393C75.9891 23.9037 76.0033 24.0707 76.05 24.2286C76.0967 24.3865 76.1747 24.5316 76.2788 24.6539C76.3828 24.7761 76.5103 24.8726 76.6525 24.9365L89.0688 30.535C89.6111 30.7815 90.2174 30.8115 90.7784 30.6197C91.3395 30.4279 91.8184 30.0269 92.1288 29.4889L94.1313 26.0421L96.1338 29.4889C96.4442 30.0269 96.9231 30.4279 97.4842 30.6197C98.0452 30.8115 98.6515 30.7815 99.1938 30.535L111.612 24.9403C111.754 24.8764 111.882 24.7799 111.986 24.6577C112.09 24.5355 112.168 24.3903 112.215 24.2324C112.261 24.0745 112.276 23.9075 112.256 23.7431C112.237 23.5786 112.185 23.4207 112.103 23.2802H112.105Z" fill="#6A6E72"/>
        </g>
        <ellipse cx="113" cy="21" rx="11" ry="11" fill="#E0E3E8" stroke="#F7F8FA" strokeWidth="3"/>
        <rect width="12" height="12" transform="translate(107 15)" fill="#E0E3E8"/>
        <path d="M111.5 23.1L109.4 21L108.7 21.7L111.5 24.5L117.5 18.5L116.8 17.8L111.5 23.1Z" fill="#6A6E72" stroke="#6A6E72" strokeWidth="0.5"/>
        <defs>
          <clipPath id="clip0_1639_81894">
            <rect width="36.2667" height="32" fill="white" transform="translate(76 12)"/>
          </clipPath>
        </defs>
      </svg>
    </Box>
  )
}

export function IncludedTaxesIcon(props: {sx?: any}): JSX.Element {
  return (
    <Box sx={{...props.sx && props.sx}}>
      <svg width={"100%"} height={"100%"} viewBox="0 0 201 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="201" height="48" fill="#F7F8FA"/>
        <rect x="39" y="42" width="118" height="6" rx="3" fill="#E0E3E8"/>
        <g>
          <circle cx="91" cy="21" r="11" fill="#6A6E72"/>
          <path d="M96.25 18L93.25 21H95.5C95.5 23.4825 93.4825 25.5 91 25.5C90.2425 25.5 89.5225 25.3125 88.9 24.975L87.805 26.07C88.7275 26.655 89.8225 27 91 27C94.315 27 97 24.315 97 21H99.25L96.25 18ZM86.5 21C86.5 18.5175 88.5175 16.5 91 16.5C91.7575 16.5 92.4775 16.6875 93.1 17.025L94.195 15.93C93.2725 15.345 92.1775 15 91 15C87.685 15 85 17.685 85 21H82.75L85.75 24L88.75 21H86.5Z" fill="white"/>
        </g>
        <path d="M106.833 26.4875C106.442 26.141 105.934 25.9675 105.419 26.005C104.903 26.0426 104.424 26.2881 104.085 26.6877L98.9913 32.7069C98.9913 32.7309 98.9913 32.7548 98.9913 32.7788C98.9904 33.5022 98.7097 34.1957 98.211 34.7073C97.7123 35.2188 97.0362 35.5068 96.3308 35.5079H87.9708C87.8703 35.5008 87.7762 35.4549 87.7075 35.3794C87.6388 35.304 87.6006 35.2046 87.6006 35.1013C87.6006 34.9981 87.6388 34.8987 87.7075 34.8232C87.7762 34.7477 87.8703 34.7018 87.9708 34.6947H96.3308C96.8267 34.6944 97.3021 34.4923 97.6528 34.1327C98.0034 33.7731 98.2005 33.2854 98.2008 32.7769C98.2008 32.7172 98.198 32.6592 98.1929 32.6038V32.6009C98.15 32.125 97.9352 31.6826 97.5907 31.3607C97.2462 31.0388 96.7969 30.8605 96.3308 30.8609H80.6902C80.1036 30.8592 79.5224 30.9769 78.9805 31.207C78.4385 31.4372 77.9464 31.7753 77.5327 32.2018L74 35.5079L82.4005 44L84.8523 40.922C85.3404 40.4211 85.9201 40.0238 86.558 39.7528C87.196 39.4818 87.8798 39.3424 88.5703 39.3426H96.4477C97.6952 39.3426 98.9871 38.7366 99.9072 37.7211L107.027 29.3065C107.366 28.9064 107.536 28.3847 107.499 27.8562C107.463 27.3277 107.223 26.8348 106.833 26.4875Z" fill="#6A6E72"/>
        <ellipse cx="111.792" cy="21" rx="11" ry="11" fill="#E0E3E8" stroke="#F7F8FA" strokeWidth="3"/>
        <rect width="12" height="12" transform="translate(105.792 15)" fill="#E0E3E8"/>
        <path d="M110.292 23.1L108.192 21L107.492 21.7L110.292 24.5L116.292 18.5L115.592 17.8L110.292 23.1Z" fill="#6A6E72" stroke="#6A6E72" strokeWidth="0.5"/>
        <defs>
          <clipPath id="clip0_1639_81912">
            <rect width="22" height="20" fill="white" transform="translate(80 10)"/>
          </clipPath>
        </defs>
      </svg>
    </Box>
  )
}
