import {Box, Typography} from "@mui/material";
import MaintenanceMode from "../../assets/maintenance_mode.svg";
import React from "react";
import {useTranslation} from "react-i18next";

export default function Maintenance(props: {isMobile: boolean}): JSX.Element {
  const {isMobile} = props;
  const containerWidth = (isMobile) ? "100%" : "80%";
  const {t} = useTranslation();

  return (
    <Box sx={{display: "flex", flexDirection: "column", width: containerWidth, mx: "auto", mr: "auto", mt: 4, mb: 4}}>
      <img src={MaintenanceMode} style={{height: isMobile ? "250px" : "378px"}} alt="aucun resultat trouvé"/>
      <Typography variant="h1" sx={{fontWeight: "bold", color: 'neutral.main', mt: 3}}>
        {t('maintenance.text')}
      </Typography>
    </Box>
  )
}
