import React, {useContext, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {Box, Card, CardContent, CardMedia, Divider, Typography} from "@mui/material";
import {MAX_DESKTOP_SIZE} from "../../tokens/libertyTheme";
import {PrimaryBigButton, PrimarySmallButton} from "../../components/buttons/mainButton";
import Shop from "../shop/shop";
import {AppContext} from "../../App";
import {PUBLIC_URL} from "../../common/struct/urlManager";
import {Link} from 'react-router-dom';

export default function Home(): JSX.Element {
  const [store] = useContext<any>(AppContext);
  const operation = store.operation;
  const isMobile = store.isMobile;
  const {t} = useTranslation();

  const componentPadding = (isMobile) ? "0px" : 4;
  const logoMargin = (operation.logoUrl) ? "256px" : 6;
  const bannerDesktopMargin = (operation.bannerUrl) ? "-185px" : "-89px";
  const bannerMobileMargin = (operation.bannerUrl) ? -4 : 0;
  const presentationMargin = (operation.bannerUrl == null && operation.logoUrl != null) ? -12 : "0px";
  const presentationPadding = (operation.logoUrl) ? 1 : 3;
  const maxWidth = (isMobile) ? "100vw" : MAX_DESKTOP_SIZE;

  const goToShop = (): void => {
    const element = document.getElementById("shop");
    element?.scrollIntoView();
  }
  const checkDate = new Date(operation.startDate) > new Date();

  useEffect(() => {    
    document.title = t("tab_title.home");
  }, []);

  const mobileTitle = <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "ornament.light"}}>
    {operation.logoUrl && <Card sx={{boxShadow: "none", width: 144, height: 144, display: "flex", alignItems: "center", mt: bannerMobileMargin}}>
      <CardMedia component="img" image={operation.logoUrl} sx={{mr: "auto", mx: "auto", maxWidth: "100%", maxHeight: "100%", width: "auto !important", height: "auto !important", borderColor: "ornament.dark", borderStyle: "solid", borderWidth: "1px", boxSizing: "border-box"}} alt="operation-logo"/>
    </Card>}
    <Typography variant="h1" color="neutral.dark" sx={{fontWeight: "bold", mt: 4, px: 5}}>{operation.name}</Typography>
    {(store.isShopOpen || store.isUserSav) && <PrimaryBigButton sx={{mt: 4, mb: 4, mx: 2, mr: 2, width: "90%"}} label={t("home.view_shop")} action={goToShop}/>}
  </Box>

  const desktopTitle = <>
    <Box sx={{display: "flex", pt: 4, pb: 4, px: logoMargin, pr: 6, alignItems: "center", justifyContent: "space-between", backgroundColor: "ornament.light", boxShadow: 1}}>
      <Typography variant="h1" color="neutral.dark" sx={{fontWeight: "bold", px: 5}}>{operation.name}</Typography>
      {(store.isShopOpen || store.isUserSav) && <PrimarySmallButton label={t("home.view_shop")} action={goToShop} sx={{height: "fit-content"}}/>}
    </Box>
    {operation.logoUrl && <Card sx={{boxShadow: "none", width: 204, height: 204, display: "flex", alignItems: "center", mx: 6, mt: bannerDesktopMargin, "& img": {width: "100%"}}}>
      <CardMedia component="img" image={operation.logoUrl} sx={{mr: "auto", mx: "auto", maxWidth: "204px", maxHeight: "204px", width: "auto !important", height: "auto !important", borderColor: "ornament.dark", borderStyle: "solid", borderWidth: "1px", boxSizing: "border-box"}} alt="operation-logo"/>
    </Card>}
  </>

  const mobilePresentation = ((store.isShopOpen && operation.twig) || (!store.isShopOpen && store.isUserSav && operation.twig) || (!store.isShopOpen && !store.isUserSav)) && <Box sx={{display: "flex", px: 2, pr: 2, pt: 3, pb: 3}}>
    <Divider sx={{backgroundColor: "ornament.dark", borderColor: "ornament.dark", width: "4px", mr: 3}} flexItem orientation="vertical" variant="middle" />
    {(store.isShopOpen || store.isUserSav || store.isPreview) && <Box sx={{fontFamily: "Mulish", color: "neutral.dark", "& img": {width: "100%", height: "auto"}}}
      dangerouslySetInnerHTML={{__html: operation.twig}}/>}
    {(!store.isShopOpen && !store.isUserSav && !store.isPreview) && <Typography color="neutral.dark" variant="body1">
      {checkDate ? t("home.shop_closed.before", {date: new Date(operation.startDate)}) : t("home.shop_closed.after", {date: new Date(operation.endDate)})}
      <Link to={PUBLIC_URL.CONTACT}>{!store.isShopOpen && !store.isUserSav ? t("home.shop_closed.contact") : ''}</Link>
    </Typography>}
  </Box>

  const desktopPresentation = ((store.isShopOpen && operation.twig) || (!store.isShopOpen && store.isUserSav && operation.twig) || (!store.isShopOpen && !store.isUserSav)) && <Box sx={{display: "flex", pt: presentationPadding, pb: 3, mt: presentationMargin, px: logoMargin, pr: 6}}>
    <Divider sx={{backgroundColor: "ornament.dark", borderColor: "ornament.dark", width: "4px", mr: 3}} flexItem orientation="vertical" variant="middle" />
    {(store.isShopOpen || store.isUserSav || store.isPreview) && <Box sx={{fontFamily: "Mulish", color: "neutral.dark", "& img": {width: "100%", height: "auto"}}}
      dangerouslySetInnerHTML={{__html: operation.twig}}/>}
    {(!store.isShopOpen && !store.isUserSav && !store.isPreview) && <Typography color="neutral.dark" variant="body1">
      {checkDate ? t("home.shop_closed.before", {date: new Date(operation.startDate)}) : t("home.shop_closed.after", {date: new Date(operation.endDate)})}
      <Link to={PUBLIC_URL.CONTACT}>{!store.isShopOpen && !store.isUserSav ? t("home.shop_closed.contact") : ''}</Link>
    </Typography>}
  </Box>

  return (
    <Box sx={{display: "flex", flexDirection: "column", zIndex: 0, p: componentPadding}}>
      <Box sx={{display: "flex", flexDirection: "column", width: "100%", maxWidth: maxWidth, mx: "auto"}} className="bloc-edito">
        <Card sx={{display: "flex", flexDirection: "column", justifyContent: "space-between", backgroundColor: "transparent", width: "100%",
          overflowX: "auto", "& .MuiCardContent-root:last-child": {p: "0px"}}}>
          {operation.bannerUrl && <Box sx={{minHeight: "104px", display: "flex", boxShadow: "unset"}}><CardMedia sx={{mb: -1, width: "100%", height: "fit-content", alignSelf: "end"}} component="img" image={operation.bannerUrl} alt="operation-banner"/></Box>}
          <CardContent sx={{p: "0px", boxShadow: 2, minHeight: operation.logoUrl ? "204px" : "unset"}}>
            <Divider sx={{backgroundColor: "primary.main", borderColor: "primary.main", height: 8, width: "100%"}} flexItem/>
            {isMobile && mobileTitle}
            {!isMobile && desktopTitle}
            {isMobile && mobilePresentation}
            {!isMobile && desktopPresentation}
          </CardContent>
        </Card>
      </Box>
      {(store.isShopOpen || store.isUserSav || store.isPreview) && <Shop operation={operation} isMobile={isMobile}/>}
    </Box>
  )
}
