import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../App";
import {Box, Card, Grid, Typography} from "@mui/material";
import backgroundInformation from "../../assets/background_information.svg";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import {PUBLIC_URL} from "../../common/struct/urlManager";
import {MAX_DESKTOP_SIZE} from "../../tokens/libertyTheme";
import {useTranslation} from "react-i18next";
import FaqPreview from "../../patterns/faq/faqPreview";
import ThirdLevelMenu from "../../patterns/menu/thirdLevelMenu";
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import PlaceIcon from '@mui/icons-material/Place';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import {useLocation, useNavigate} from "react-router-dom";
import InformationProfile from "./informationProfile";
import InformationPassword from "./informationPassword";
import InformationAddress from "./informationAddress";
import {TAddress, TAddressType} from "../../common/struct/models/TAddress";
import {getUserAddresses} from "../../services/ParticipantService";
import {getOperationParticipantId} from "../../common/struct/globalVar";
import Loader from "../../components/loader/loader";

export enum InformationMenu {
  PROFILE,
  PASSWORD,
  SHIPPING_ADDRESS,
  BILLING_ADDRESS,
}

export default function Information(): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const STORE = useContext<any>(AppContext);
  const [store] = STORE;
  const [loading, setLoading] = useState(true);
  const [addresses, setAddresses] = useState<TAddress[]>([]);

  const extractSelectedMenu = (): InformationMenu|null => {
    if (location.hash.length == 0) {
      navigate(PUBLIC_URL.INFORMATION_PROFILE, {replace: true});
      return null;
    }

    switch (location.pathname + location.hash) {
    case PUBLIC_URL.INFORMATION_PROFILE:
      return InformationMenu.PROFILE;
    case PUBLIC_URL.INFORMATION_PASSWORD:
      return InformationMenu.PASSWORD;
    case PUBLIC_URL.INFORMATION_SHIPPING_ADDRESS:
      return InformationMenu.SHIPPING_ADDRESS;
    case PUBLIC_URL.INFORMATION_BILLING_ADDRESS:
      return InformationMenu.BILLING_ADDRESS;
    default:
      return null;
    }
  }

  const [selectedMenu, setSelectedMenu] = useState(extractSelectedMenu());

  useEffect(() => {
    setSelectedMenu(extractSelectedMenu());
  }, [location]);

  useEffect(() => {
    const operationParticipantId = getOperationParticipantId();
    if (operationParticipantId == null) {
      navigate(PUBLIC_URL.HOME);
      return;
    }

    getUserAddresses(operationParticipantId)
      .then(result => {
        setAddresses(result.addresses);
        setLoading(false);
      })
  }, []);

  const maxWidth = (store.isMobile) ? "100vw" : MAX_DESKTOP_SIZE;
  const menus = [
    {
      icon: PersonIcon,
      label: 'information.profile',
      url: PUBLIC_URL.INFORMATION_PROFILE
    },
    {
      icon: LockIcon,
      label: 'information.password',
      url: PUBLIC_URL.INFORMATION_PASSWORD
    },
    {
      icon: PlaceIcon,
      label: 'information.shipping_address',
      url: PUBLIC_URL.INFORMATION_SHIPPING_ADDRESS
    },
    {
      icon: CreditCardIcon,
      label: 'information.billing_address',
      url: PUBLIC_URL.INFORMATION_BILLING_ADDRESS
    },
  ]

  return (
    <Box sx={{minHeight: !store.isMobile ? "calc(100vh - 182px)" : "calc(100vh - 292px)"}}>
      <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        <Box sx={{width: "100%", display: "flex", justifyContent: "center", backgroundImage: `url(${backgroundInformation})`}}>
          <Box sx={{width: "100%", maxWidth: maxWidth, px: !store.isMobile ? 4 : 3, py: !store.isMobile ? 4 : 3}}>
            <Breadcrumb breadcrumb={[{label: t("account.my_account"), url: PUBLIC_URL.ACCOUNT}, {label: t("information.title" + (store.isMobile ? "_mobile" : ""))}]} sx={{mb: 2}} />
            <Typography variant="h1" sx={{color: "neutral.dark", fontWeight: "bold", zIndex: "1", pb: !store.isMobile ? 4 : 3}}>
              {t("information.title" + (store.isMobile ? "_mobile" : ""))}
            </Typography>
          </Box>
        </Box>
        <Grid container justifyContent="center" columns={10} columnSpacing={store.isMobile ? 0 : 5} sx={{width: "100%", maxWidth: maxWidth, px: !store.isMobile ? 4 : 3, mt: !store.isMobile ? -4 : -3, pb: !store.isMobile ? 4 : 3}}>
          {/** Le important ici ne doit pas être supprimer pour éviter un padding left forcé par MUI */}
          <Grid item xs={10} sm={7} sx={{pl: "0px !important", mb: 7}}>
            <Card sx={{boxShadow: 1, border: "1px solid", borderColor: "ornament.dark"}}>
              <ThirdLevelMenu menus={menus}/>
              {loading ? <Loader sx={{py: 5}}/> : <>
                {selectedMenu === InformationMenu.PROFILE && <InformationProfile/>}
                {selectedMenu === InformationMenu.PASSWORD && <InformationPassword/>}
                {selectedMenu === InformationMenu.SHIPPING_ADDRESS && <InformationAddress addresses={addresses} setAddresses={setAddresses} type={TAddressType.DELIVERY}/>}
                {selectedMenu === InformationMenu.BILLING_ADDRESS && <InformationAddress addresses={addresses} setAddresses={setAddresses} type={TAddressType.INVOICE}/>}
              </>}
            </Card>
          </Grid>
          <Grid item xs={10} sm={3} sx={{mb: 5}}><FaqPreview/></Grid>
        </Grid>
      </Box>
    </Box>
  )
}
