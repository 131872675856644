import {Box, FormControlLabel, Radio, RadioGroup, Typography} from "@mui/material";
import {ShippingType, TAddress, TAddressType} from "../../common/struct/models/TAddress";
import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TViolation} from "../../common/struct/models/TViolation";
import {AppContext} from "../../App";
import FormInput from "../../patterns/form/formInput";
import {INPUT_TYPES} from "../../patterns/form/formConstants";

type AddressFormProps = {
  loading?: boolean,
  address?: TAddress|undefined,
  type: TAddressType;
  setAddress: (address: TAddress) => void,
  violations: TViolation[]
}

export default function AddressForm(props: AddressFormProps): JSX.Element {
  const {t} = useTranslation();
  const {loading, address, type, setAddress, violations} = props;
  const [store] = useContext<any>(AppContext);

  const countries = [1].map(key => ({label: t("address.countries." + key), value: t("address.countries." + key)}));

  const [shippingType, setShippingType] = useState(ShippingType.HOME.toString());
  const [society, setSociety] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState(countries[0].value);
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [street2, setStreet2] = useState("");
  const [street3, setStreet3] = useState("");
  const [phone, setPhone] = useState("");
  const [vat, setVat] = useState("");
  const [isPristine, setPristine] = useState(false);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (address) {
      setSociety(address.society??"");
      setFirstName(address.firstName);
      setLastName(address.lastName);
      setEmail(address.email);
      setZip(address.zip);
      setCity(address.city);
      setStreet(address.street);
      setStreet2(address.street2??"");
      setStreet3(address.street3??"");
      setPhone(address.phone);
      setVat(address.vat??"");
    } else {
      setFirstName(store.user.participant.firstName);
      setLastName(store.user.participant.lastName);
      setEmail(store.user.participant.email);
      setPhone(store.user.participant.phone);
      setPristine(true);
    }
  }, [loading])

  useEffect(() => {
    if(zip.length>5) {
      setZip(zip.substring(0, zip.length - 1));
    }
  }, [zip])

  useEffect(() => {
    setAddress({
      id: address?.id,
      type: type,
      society: (type == TAddressType.INVOICE || shippingType === ShippingType.SOCIETY) ? society : "",
      firstName: firstName,
      lastName: lastName,
      email: email,
      country: country,
      zip: zip,
      city: city,
      street: street,
      street2: street2,
      street3: street3,
      phone: phone,
      vat: vat
    });
  }, [society, firstName, lastName, email, country, zip, city, street, street2, street3, phone, vat])

  useEffect(() => {    
    if (isPristine) {
      if (shippingType == ShippingType.SOCIETY) {
        setSociety(store.user.company.name);
      } else {
        setCountry(countries[0].value);
        setZip(address?.zip??"");
        setCity(address?.city??"");
        setStreet(address?.street??"");
        setStreet2(address?.street2??"");
        setStreet3(address?.street3??"");
        setVat(address?.vat??"")
      }
      setFirstName(store.user.participant.firstName);
      setLastName(store.user.participant.lastName);
      setEmail(store.user.participant.email);
      setPhone(store.user.participant.phone);
    }
  }, [shippingType, isPristine])

  const changeShippingType = (event: ChangeEvent<HTMLInputElement>): void => {
    setSociety("");
    setShippingType(event.target.value);
  }

  return (
    <>
      {type == TAddressType.DELIVERY && <>
        <Typography variant="body2" color="neutral.main" sx={{fontWeight: "bold", mb: 1}}>
          {t("address.type")}
        </Typography>
        <RadioGroup defaultValue={address?.society ? ShippingType.SOCIETY : ShippingType.HOME} onChange={changeShippingType} sx={{ml: 1}}>
          <Box sx={{display: "flex", flexDirection: store.isMobile ? "column" : "row", mb: 4}}>
            <FormControlLabel value={ShippingType.HOME} sx={{mr: store.isMobile ? "0px" : 5, mb: store.isMobile ? 3 : "0px"}} disabled={loading}
              control={<Radio color="primary" sx={{width: 24, height: 24, color: "primary.main"}}/>}
              label={<Box sx={{display: "flex", ml: 1}}>
                <Typography variant="body1" color="neutral.dark">{t("basket.shipping.title")}</Typography>
                <Typography variant="body1" color="neutral.dark" sx={{ml: 0, fontWeight: "bold"}}>{t("basket.shipping.home")}</Typography>
              </Box>}/>
            <FormControlLabel value={ShippingType.SOCIETY} disabled={loading}
              control={<Radio color="primary" sx={{width: 24, height: 24, color: "primary.main"}}/>}
              label={<Box sx={{display: "flex", ml: 1}}>
                <Typography variant="body1" color="neutral.dark">{t("basket.shipping.title")}</Typography>
                <Typography variant="body1" color="neutral.dark" sx={{ml: 0, fontWeight: "bold"}}>{t("basket.shipping.society")}</Typography>
              </Box>}/>
          </Box>
        </RadioGroup>
      </>}
      {type == TAddressType.DELIVERY && shippingType == ShippingType.SOCIETY.toString() && <FormInput required name="society" value={society} setValue={setSociety} setPristine={setPristine} violations={violations} disabled={loading}/>}
      <FormInput required name="firstName" value={firstName} setValue={setFirstName} violations={violations} disabled={loading}/>
      <FormInput required name="lastName" value={lastName} setValue={setLastName} violations={violations} disabled={loading}/>
      <FormInput required name="email" value={email} setValue={setEmail} violations={violations} disabled={loading}/>
      <FormInput required name="phone" value={phone} setValue={setPhone} violations={violations} disabled={loading} type={INPUT_TYPES.TELEPHONE}/>
      {type == TAddressType.INVOICE && <FormInput name="society" value={society} setValue={setSociety} setPristine={setPristine} violations={violations} disabled={loading}/>}
      {type == TAddressType.INVOICE && <FormInput name="vat" value={vat} setValue={setVat} setPristine={setPristine} violations={violations} disabled={loading}/>}
      <FormInput required name="street" value={street} setValue={setStreet} setPristine={setPristine} violations={violations} disabled={loading}/>
      <FormInput name="street2" value={street2} setValue={setStreet2} setPristine={setPristine} violations={violations} disabled={loading}/>
      <FormInput name="street3" value={street3} setValue={setStreet3} setPristine={setPristine} violations={violations} disabled={loading}/>
      <FormInput required name="zip" value={zip} setValue={setZip} setPristine={setPristine} violations={violations} disabled={loading}/>
      <FormInput required name="city" value={city} setValue={setCity} setPristine={setPristine} violations={violations} disabled={loading}/>
      <FormInput required name="country" value={country} setValue={setCountry} setPristine={setPristine} options={countries} violations={violations} disabled={loading}/>
    </>
  )
}
