import React, {useContext} from 'react';
import {Box, Typography} from "@mui/material";
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import {AppContext} from '../../App';
import {MAX_DESKTOP_SIZE} from '../../tokens/libertyTheme';

type BreadcrumbProps = {
  label: string,
  url?: string
}

export default function AccountHeader(props: {title: string, background: any, breadcrumb: BreadcrumbProps[]}): JSX.Element {
  const [store] = useContext<any>(AppContext);
  const {title, breadcrumb} = props;
  const maxWidth = (store.isMobile) ? "100vw" : MAX_DESKTOP_SIZE;

  return (
    <Box sx={{width: "100%", minHeight: "134px", boxShadow: "inset 0px -1px 0px #E0E3E8", display: "flex", justifyContent: "center", position: "relative"}}>
      <img src={props.background} style={{position: "absolute", top: "0%", left: "0%", objectFit: "cover", width: "100%", height: "100%", zIndex: "0"}} />
      <Box sx={{width: "100%", maxWidth: maxWidth, px: !store.isMobile ? 4 : 3, py: !store.isMobile ? 4 : 3, display: "flex", justifyContent: "center"}}>
        <Box sx={{width: "100%"}}>
          <Breadcrumb breadcrumb={breadcrumb} sx={{mb: 2}} />
          <Typography variant="h1" sx={{color: "neutral.dark", fontWeight: "bold", position: "relative", zIndex: "1", mb: 2}}>{title}</Typography>
        </Box>
      </Box>
    </Box>
  )
}
