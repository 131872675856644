import {API_URL, GLOBAL_GIFT_ORDER_ID, GLOBAL_OPERATION_ID, GLOBAL_OPERATION_PARTICIPANT_ID} from "../common/struct/urlManager";
import {get, getBlob, post, put} from "../common/methods/ApiService";
import {TOrder, TOrderPayment} from "../common/struct/models/TOrder";
import {TBasketGift} from "../common/struct/models/TBasket";
import {getOperationParticipantId} from "../common/struct/globalVar";
import {TAddress} from "../common/struct/models/TAddress";

export async function getOperationParticipantOrders(operationParticipantId: string): Promise<TOrder[]> {
  return get(`${(API_URL.GET_PARTICIPANT_ORDERS).replace(":" + GLOBAL_OPERATION_PARTICIPANT_ID, operationParticipantId)}`);
}

export async function getGiftOrder(giftOrderId: string): Promise<TOrder> {
  return get(`${(API_URL.GET_ORDER).replace(":" + GLOBAL_GIFT_ORDER_ID, giftOrderId)}`);
}

export async function getOperationParticipantLastOrder(operationParticipantId: string): Promise<TOrder|null> {
  return get(`${(API_URL.GET_PARTICIPANT_LAST_ORDER).replace(":" + GLOBAL_OPERATION_PARTICIPANT_ID, operationParticipantId)}`);
}

export async function addOrderAddress(address: TAddress): Promise<TAddress> {
  return post(API_URL.ADDRESSES, address);
}

export async function updateOrderAddress(id: string, address: TAddress): Promise<TAddress> {
  return put(API_URL.ADDRESSES, id, address);
}

export async function validateOrder(operationId: string, gifts: TBasketGift[]): Promise<any> {
  return post(API_URL.VALIDATE_BASKET.replace(":" + GLOBAL_OPERATION_ID, operationId), {lines: gifts.map(gift => ({odooProductId: gift.id, quantity: gift.quantity}))})
}

export async function downloadOrderReceipt(giftOrderId: string): Promise<any> {
  return getBlob(`giftorders/${giftOrderId}/receipt`);
}

export async function downloadOrderBill(giftOrderId: string): Promise<any> {
  return getBlob(`giftorders/${giftOrderId}/bill`);
}

export async function orderGifts(shopId: string, gifts: TBasketGift[], deliveryAddressId: string, invoiceAddressId?: string, intentId?: string): Promise<any> {
  return post("giftorders", {
    operationParticipant: `/fo/operationparticipants/${getOperationParticipantId()}`,
    shop: `/fo/shops/${shopId}`,
    deliveryAddress: `/fo/addresses/${deliveryAddressId}`,
    invoiceAddress: invoiceAddressId ? `/fo/addresses/${invoiceAddressId}` : null,
    lines: gifts.map(gift => ({odooProductId: gift.id, quantity: gift.quantity})),
    paymentIntentId: intentId??null
  })
}

export async function initiatePayment(amount: number, addressId: string): Promise<TOrderPayment> {
  return post("giftorders/payment", {
    amount: amount,
    operationParticipant: `/fo/operationparticipants/${getOperationParticipantId()}`,
    invoiceAddress: `/fo/addresses/${addressId}`,
  })
}
