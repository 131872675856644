import React, {MouseEventHandler} from "react";
import leftNav from "../../assets/left_nav.svg";

export default function LeftNav(props: {disabled: boolean, onClick: MouseEventHandler<HTMLElement>}): JSX.Element {
  return (
    <button
      type="button"
      className="image-gallery-icon image-gallery-left-nav"
      disabled={props.disabled}
      onClick={props.onClick}
      aria-label="Previous Slide"
    >
      <img src={leftNav}/>
    </button>
  );
}
