import {TFilter} from "../../common/struct/models/TFilter";
import React, {Dispatch, SetStateAction, useState} from "react";
import {Box, Button, Dialog, DialogContent, IconButton, Typography} from "@mui/material";
import {MobileTransition} from "./shopProps";
import ClearIcon from '@mui/icons-material/Clear';
import {TUniverse} from "../../common/struct/models/TUniverse";
import UniverseChoice from "./universeChoice";
import CachedIcon from "@mui/icons-material/Cached";
import {useTranslation} from "react-i18next";

type MobileUniverseMenuProps = {
  open: boolean,
  setOpen: (open: boolean) => void,
  universes: TUniverse[],
  filters: TFilter,
  setFilters: Dispatch<SetStateAction<TFilter>>,
  setReload: Dispatch<SetStateAction<boolean>>
}

export default function MobileUniverseMenu(props: MobileUniverseMenuProps): JSX.Element {
  const {t} = useTranslation();
  const {open, setOpen, universes, filters, setFilters, setReload} = props;
  const [selectedUniverses, setSelectedUniverses] = useState(filters.universIds);

  const resetFilters = (): void => {
    setSelectedUniverses([]);
  }

  const applyFilters = (): void => {
    setFilters(prevState => ({...prevState, universIds: selectedUniverses}));
    setReload(true);
    setOpen(false);
  }

  return (
    <Dialog open={open} fullScreen TransitionComponent={MobileTransition} sx={{mx: 8, mr: "0px", "& .MuiBackdrop-root": {backgroundColor: "#363739"}}}>
      <IconButton sx={{color: "ornament.light", position: "fixed", top: 0, left: 0, mt: 2, mx: 2, p: "0px"}} onClick={(): void => setOpen(false)}>
        <ClearIcon />
      </IconButton>
      <DialogContent sx={{display: "flex", flexDirection: "column", p: "0px"}}>
        <Typography variant="body2" color="neutral.dark" sx={{pt: 2, px: 3, mb: 3, fontWeight: "bold", textTransform: "uppercase"}}>
          {t("shop.filters.universes.title")}
        </Typography>
        {universes.map(universe => <UniverseChoice key={universe.parent.x_name} universe={universe} filters={filters} setFilters={setFilters}
          selectedUniverses={selectedUniverses} setSelectedUniverses={setSelectedUniverses}/>)}
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", py: 3, px: 2}}>
          <Button variant="outlined" startIcon={<CachedIcon/>} onClick={resetFilters} sx={{width: "100%", mr: 1, pt: 2, pb: 2, px: 5, pr: 5}}>
            {t("shop.filters.reset")}
          </Button>
          <Button variant="contained" onClick={applyFilters} sx={{width: "100%", pt: 2, pb: 2, px: 5, pr: 5, boxShadow: 0}}>
            {t("shop.filters.apply")}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
