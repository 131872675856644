import React, {Dialog, DialogContent, Divider, IconButton, Menu, Typography} from "@mui/material";
import {useContext} from "react";
import {AppContext} from "../../App";
import {MobileTransition} from "../../pages/shop/shopProps";
import ClearIcon from "@mui/icons-material/Clear";
import {Box} from "@mui/system";
import {SecondaryBigButton} from "../../components/buttons/mainButton";
import {PUBLIC_URL} from "../../common/struct/urlManager";
import {useTranslation} from "react-i18next";
import emptyBasket from "../../assets/empty_basket.svg";
import CardMediaLoader from "../../components/image/cardMediaLoader";
import {PreviewTooltip} from "../../components/tooltip/tooltip";

type BasketMenuDesktopProps = {
  basketMenuOpen: boolean;
  closeBasketMenu: () => void;
  anchorEl: null|HTMLElement;
}

export default function BasketMenu(props: BasketMenuDesktopProps): JSX.Element {
  const {basketMenuOpen, closeBasketMenu, anchorEl} = props;
  const {t} = useTranslation();

  const STORE = useContext<any>(AppContext);
  const [store] = STORE;

  return !store.isMobile ?
    <Menu id="menu-basket" open={basketMenuOpen} anchorEl={anchorEl} transformOrigin={{horizontal: 'center', vertical: 'top'}} anchorOrigin={{horizontal: 'center', vertical: 'bottom'}} onClose={closeBasketMenu}
      sx={{"& .MuiPaper-root": {boxShadow: 2, borderTopLeftRadius: "0", borderTopRightRadius: "0"}, "& ul": {p: "0px"}}}>
      <Box sx={{display: "flex", flexDirection: "column"}}>
        {store.isPreview !== null && store.isPreview ? <PreviewTooltip backgroundColor={"primary.main"} color={"primary.contrastText"}>
          <SecondaryBigButton label={t("basket.menu.button")} disabled={true} href={PUBLIC_URL.BASKET} sx={{mx: 4, mb: 2, mt: 3, boxSizing: "border-box", width: "-webkit-fill-available"}}/>
        </PreviewTooltip> : <SecondaryBigButton label={t("basket.menu.button")} disabled={store.isPreview !== null && store.isPreview} href={PUBLIC_URL.BASKET} sx={{mx: 4, mb: 2, mt: 3, boxSizing: "border-box", width: "-webkit-fill-available"}}/>}
        {store.basket[0] ?
          <Box sx={{px: 4, mb: 3, display: "flex", alignItems: "center", flexDirection: "column"}}>
            <Typography variant="body1" color="neutral.main" sx={{mr: "auto", textDecoration: "underline", fontWeight: "medium"}}>{t("basket.menu.title")}</Typography>
            {store.basket.map((gift: any, index: number) => {
              return <Box key={index} sx={{width: "100%", maxWidth: "15vw"}}>
                <Box sx={{display: "flex", mt: 2, width: "100%", justifyContent: "space-between"}}>
                  <Typography variant="body1" color="neutral.main" sx={{mr: 6, overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>{gift.name}</Typography>
                  <Typography variant="body1" color="neutral.main">{t("basket.menu.pt", {count: gift.points*gift.quantity})}</Typography>
                </Box>
                <Typography variant="body2" color="neutral.main">{t("basket.menu.quantity", {quantity: gift.quantity})}</Typography>
              </Box>
            })}
            <Divider sx={{backgroundColor: "neutral.light", borderColor: "neutral.main", height: "1px", my: 2}} flexItem/>
            <Box sx={{width: "100%"}}>
              {store.basket.reduce((a: any, b: any) => a + (b["points"]*b["quantity"] || 0), 0)-store.user.rewardPoints > 0 && <Box sx={{display: "flex", width: "100%", mb: 1, justifyContent: "space-between"}}>
                <Typography variant="body1" color="neutral.main" sx={{fontWeight: "medium", mr: 6}}>{t("basket.menu.my_points")}</Typography>
                <Typography variant="body1" color="neutral.main" sx={{fontWeight: "medium"}}>{t("basket.menu.point", {count: store.user.rewardPoints})}</Typography>
              </Box>}
              <Box sx={{display: "flex", width: "100%", justifyContent: "space-between"}}>
                <Typography variant="body1" color="neutral.main" sx={{fontWeight: "medium", mr: 6}}>{t("basket.menu.total")}</Typography>
                <Typography variant="body1" color="neutral.main" sx={{fontWeight: "medium"}}>{t("basket.menu.point", {count: store.basket.reduce((a: any, b: any) => a + (b["points"]*b["quantity"] || 0), 0)})}</Typography>
              </Box>
              {store.basket.reduce((a: any, b: any) => a + (b["points"]*b["quantity"] || 0), 0)-store.user.rewardPoints > 0 && <Box sx={{display: "flex", width: "100%", justifyContent: "space-between", mt: 1}}>
                <Typography variant="body1" color="neutral.main" sx={{fontWeight: "medium", mr: 6}}>{t("basket.menu.difference")}</Typography>
                <Typography variant="body1" color="neutral.main" sx={{fontWeight: "medium"}}>{t("basket.menu.difference_amount", {amount: store.basket.reduce((a: any, b: any) => a + (b["points"]*b["quantity"] || 0), 0)-store.user.rewardPoints})}</Typography>
              </Box>}
            </Box>
          </Box>
          :
          <Box sx={{px: 3, mb: 3, display: "flex", alignItems: "center", flexDirection: "column"}}>
            <CardMediaLoader image={emptyBasket} isMobile={store.isMobile} sx={{width: 150, height: 150}}/>
            <Typography variant="body1" color="neutral.main" sx={{fontWeight: "medium", mt: 2}}>
              {t("basket.your_basket.empty_menu")}
            </Typography>
          </Box>
        }
      </Box>
    </Menu> :
    <Dialog open={basketMenuOpen} fullScreen TransitionComponent={MobileTransition} sx={{mx: 8, mr: "0px", "& .MuiBackdrop-root": {backgroundColor: "#363739"}}}>
      <IconButton sx={{color: "ornament.light", position: "fixed", top: 0, left: 0, mt: 2, mx: 2, p: "0px"}} onClick={closeBasketMenu}>
        <ClearIcon />
      </IconButton>
      <DialogContent sx={{display: "flex", flexDirection: "column", justifyContent: "space-between", p: "0px"}}>
        <Box sx={{display: "flex", flexDirection: "column"}}>
          {store.isPreview !== null && store.isPreview ? <PreviewTooltip backgroundColor={"primary.main"} color={"primary.contrastText"}><SecondaryBigButton label={t("basket.menu.button")} disabled={true} href={PUBLIC_URL.BASKET} sx={{mx: 4, mb: 2, mt: 3, boxSizing: "border-box", width: "-webkit-fill-available"}}/></PreviewTooltip> : <SecondaryBigButton label={t("basket.menu.button")} href={PUBLIC_URL.BASKET} sx={{mx: 4, mb: 2, mt: 3, boxSizing: "border-box", width: "-webkit-fill-available"}}/>}
          {store.basket[0] ?
            <Box sx={{px: 4, mb: 3, display: "flex", alignItems: "center", flexDirection: "column"}}>
              <Typography variant="body1" color="neutral.main" sx={{mr: "auto", textDecoration: "underline", fontWeight: "medium"}}>{t("basket.menu.title")}</Typography>
              {store.basket.map((gift: any, index: number) => {
                return <Box key={index} sx={{width: "100%", maxWidth: `${store.isMobile ? "100%" : "15vw"}`}}>
                  <Box sx={{display: "flex", mt: 2, width: "100%", justifyContent: "space-between"}}>
                    <Typography variant="body1" color="neutral.main" sx={{mr: 6, overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>{gift.name}</Typography>
                    <Typography variant="body1" color="neutral.main">{t("basket.menu.pt", {count: gift.points*gift.quantity})}</Typography>
                  </Box>
                  <Typography variant="body2" color="neutral.main">{t("basket.menu.quantity", {quantity: gift.quantity})}</Typography>
                </Box>
              })}
              <Divider sx={{backgroundColor: "neutral.light", borderColor: "neutral.main", height: "1px", my: 2}} flexItem/>
              <Box sx={{width: "100%"}}>
                {store.basket.reduce((a: any, b: any) => a + (b["points"]*b["quantity"] || 0), 0)-store.user.rewardPoints > 0 && <Box sx={{display: "flex", width: "100%", mb: 1, justifyContent: "space-between"}}>
                  <Typography variant="body1" color="neutral.main" sx={{fontWeight: "medium", mr: 6}}>{t("basket.menu.my_points")}</Typography>
                  <Typography variant="body1" color="neutral.main" sx={{fontWeight: "medium"}}>{t("basket.menu.point", {count: store.user.rewardPoints})}</Typography>
                </Box>}
                <Box sx={{display: "flex", width: "100%", justifyContent: "space-between"}}>
                  <Typography variant="body1" color="neutral.main" sx={{fontWeight: "medium", mr: 6}}>{t("basket.menu.total")}</Typography>
                  <Typography variant="body1" color="neutral.main" sx={{fontWeight: "medium"}}>{t("basket.menu.point", {count: store.basket.reduce((a: any, b: any) => a + (b["points"]*b["quantity"] || 0), 0)})}</Typography>
                </Box>
                {store.basket.reduce((a: any, b: any) => a + (b["points"]*b["quantity"] || 0), 0)-store.user.rewardPoints > 0 && <Box sx={{display: "flex", width: "100%", mt: 1, justifyContent: "space-between"}}>
                  <Typography variant="body1" color="neutral.main" sx={{fontWeight: "medium", mr: 6}}>{t("basket.menu.difference")}</Typography>
                  <Typography variant="body1" color="neutral.main" sx={{fontWeight: "medium"}}>{t("basket.menu.difference_amount", {amount: store.basket.reduce((a: any, b: any) => a + (b["points"]*b["quantity"] || 0), 0)-store.user.rewardPoints})}</Typography>
                </Box>}
              </Box>
            </Box>
            :
            <Box sx={{px: 3, mb: 3, display: "flex", alignItems: "center", flexDirection: "column"}}>
              <CardMediaLoader image={emptyBasket} isMobile={store.isMobile} sx={{width: 150, height: 150}}/>
              <Typography variant="body1" color="neutral.main" sx={{fontWeight: "medium", mt: 2}}>
                {t("basket.your_basket.empty_menu")}
              </Typography>
            </Box>
          }
        </Box>
      </DialogContent>
    </Dialog>
}
