import React, {useContext} from 'react';
import {Box, Divider, Typography} from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import {PrimaryBigButton} from '../../components/buttons/mainButton';
import {IconPosition} from '../../components/buttons/buttonProps';
import {AppContext} from "../../App";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {PUBLIC_URL} from "../../common/struct/urlManager";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';


export default function Footer(): JSX.Element {
  const {t} = useTranslation();
  const [store] = useContext<any>(AppContext);
  const operation = store.operation;
  const isMobile = store.isMobile;

  return (
    <Box sx={{height: !isMobile ? "112px" : "252px", display: "flex", justifyContent: "center", position: "relative", pt: 4, boxSizing: "border-box", backgroundColor: "ornament.light", boxShadow: "inset 0px 4px 0px #E0E3E8"}}>
      <Box sx={{display: "flex", justifyContent: "space-between", alignItems: !isMobile ? "none" : "center", flexDirection: !isMobile ? "row" : "column", width: "100%", maxWidth: "745px", height: !isMobile ? "32px" : "172px", px: 5}}>
        <Link to={PUBLIC_URL.FAQ} style={{textDecoration: "none"}}>
          <Typography variant="body2" color="neutral.main" sx={{fontWeight: "medium", width: "fit-content", py: !isMobile ? "5px" : "0px"}}>
            {t("footer.faq")}
          </Typography>
        </Link>
        <Box sx={{py: !isMobile ? "10px" : "10.5px", maxWidth: !isMobile ? "none" : "12px", width: !isMobile ? "0px" : "100%"}}>
          <Divider orientation={!isMobile ? "vertical" : "horizontal"} flexItem sx={{height: "100%", borderColor: "neutral.light", backgroundColor: "neutral.light"}}/>
        </Box>
        <Box sx={{width: "155px", display: "flex", borderWidth: "1px", borderColor: "ornament.dark", borderStyle: "solid", borderRadius: "4px"}}>
          <Box sx={{width: "73px", py: "5px", px: "8px", boxSizing: "border-box"}}>
            <Typography variant="caption" sx={{color: "neutral.main"}}>
              {t("footer.reference")}
            </Typography>
          </Box>
          <Box sx={{width: "82px", py: "4px", px: "12px", boxSizing: "border-box", backgroundColor: "ornament.dark", borderTopRightRadius: "2px", borderBottomRightRadius: "2px"}}>
            <Typography variant="body2" color="neutral.dark" sx={{fontWeight: "bold", textAlign: "center"}}>
              {operation.reference}
            </Typography>
          </Box>
        </Box>
        <Box sx={{py: !isMobile ? "10px" : "10.5px", maxWidth: !isMobile ? "none" : "12px", width: !isMobile ? "0px" : "100%"}}>
          <Divider orientation={!isMobile ? "vertical" : "horizontal"} flexItem sx={{height: "100%", borderColor: "neutral.light"}}/>
        </Box>
        <Box sx={{width: "185px", display: "flex", borderRadius: "4px", backgroundColor: "ornament.dark", height: "30px", my: !isMobile ? "1px" : "0px", cursor: "pointer"}}>
          <EmailIcon sx={{mr: 1, width: "18px", height: "18px", py: "6px", pl: 1, color: "neutral.main"}}/>
          <Link to={PUBLIC_URL.CONTACT} style={{textDecoration: "none"}}>
            <Typography variant="body2" color="neutral.dark" sx={{fontWeight: "medium", py: 0, pr: 1}}>
              {t("footer.contact")}
            </Typography>
          </Link>
        </Box>
        <Box sx={{display: "flex", py: !isMobile ? "10px" : "10.5px", maxWidth: !isMobile ? "none" : "12px", width: !isMobile ? "0px" : "100%"}}>
          <Divider orientation={!isMobile ? "vertical" : "horizontal"} flexItem sx={{height: "100%", borderColor: "neutral.light"}}/>
        </Box>
        <Box sx={{display: "flex", py: !isMobile ? "5px" : "0px"}}>
          <Link to={PUBLIC_URL.CGVU} style={{textDecoration: "none"}} download target="_blank">
            <Typography variant="body2" color="neutral.main" sx={{mr: 2, fontWeight: "medium"}}>
              {t("footer.cgvu")}
            </Typography>
          </Link>
          <Link to={PUBLIC_URL.PERSONAL_DATA} style={{textDecoration: "none"}} download target="_blank">
            <Typography variant="body2" color="neutral.main" sx={{fontWeight: "medium"}}>
              {t("footer.personal_data")}
            </Typography>
          </Link>
        </Box>
      </Box>
      <PrimaryBigButton sx={{width: "168px", height: "40px", position: "absolute", bottom: "0%", left: "50%", transform: 'translateX(-50%)', borderTopLeftRadius: "8px", borderTopRightRadius: "8px", borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px", py: "8px"}} icon={(): JSX.Element => <ArrowUpwardIcon fill={"primary.ContrastColor"} />} position={IconPosition.MIDDLE} action={(): void => window.scroll(0, 0)}/>
    </Box>
  )
}
