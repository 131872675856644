export interface TSupportTicketCreate {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  subject: string;
  object: string|null;
  description: string;
  giftOrderLine: string|null;
  operation: string;
}

export enum TSupportSubjectOffline {
  CONNECTION = "CONNECTION"
}

export enum TSupportSubjectOnline {
  PRODUCT = "PRODUCT",
  ORDER_STATUS = "ORDER_STATUS",
  ORDER_ISSUE = "ORDER_ISSUE",
  ONLINE_PAYMENT = "ONLINE_PAYMENT",
  BUG = "BUG",
  POINTS = "POINTS",
  RULES = "RULES",
  OTHER = "OTHER",
}
