import React from "react";
import {Box, CircularProgress} from "@mui/material";

interface LoaderConfig {
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit';
  size?: number | string;
  sx?: any
}

export default function Loader({color, size, sx}: LoaderConfig): JSX.Element {
  return (
    <Box className="loader-container" sx={{display: "flex", justifyContent: "center", alignItems: "center", ...sx}}>
      <CircularProgress color={color} size={size} />
    </Box>
  )
}

export const loaderButton = <Box sx={{height: "35px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
  <CircularProgress size={35} sx={{color: "primary.main"}} />
</Box>
