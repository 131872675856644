import {useTranslation} from "react-i18next";
import {Box, Typography} from "@mui/material";
import React, {useContext} from "react";
import {AppContext} from "../../App";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import {FaqSection} from "./faqSection";


export function FaqCardSectionTitle(props: {section: FaqSection, index: number, isNotFirst?: boolean}): JSX.Element {
  const {t} = useTranslation();

  return (
    <Typography variant="body1" sx={{fontWeight: "bold", mb: 1, mt: props.isNotFirst ? 8 : "0px"}}>{t(`faq.${props.section}.section_${props.index}.title`)}</Typography>
  )
}

export function FaqCardTitle(props: {section: FaqSection, show: boolean, setShow: (show: boolean) => void}): JSX.Element {
  const {section, show, setShow} = props;
  const {t} = useTranslation();
  const STORE = useContext<any>(AppContext);
  const [store] = STORE;

  if (!store.isMobile) {
    return <Typography id={props.section} variant="h2" sx={{fontWeight: "bold", p: 7, borderBottom: "1px solid", borderColor: "ornament.dark"}}>
      {t(`faq.${section}.title`)}
    </Typography>
  }

  return (
    <Box id={props.section} onClick={(): void => setShow(!show)} sx={{display: "flex", alignItems: "center", justifyContent: "space-between",
      py: 4, px: 3, borderBottom: "1px solid", borderColor: "ornament.dark", cursor: "pointer"}}>
      <Typography variant="h2" sx={{fontWeight: "bold"}}>{t(`faq.${section}.title`)}</Typography>
      {show ? <RemoveIcon sx={{color: "neutral.dark"}}/> : <AddIcon sx={{color: "neutral.main"}}/>}
    </Box>
  )
}
