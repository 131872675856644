import React, {useContext, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Box, Dialog, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {AppContext} from "../../App";
import {globalStoreReducer} from "../../common/methods/context/globalStoreReducer";
import {addGiftToBasket, getOperationParticipantId} from "../../common/struct/globalVar";
import {StoreActions, StoreContext} from "../../common/struct/store";
import {PUBLIC_URL} from "../../common/struct/urlManager";
import {TGift} from "../../common/struct/models/TGift";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import CardMediaLoader from "../../components/image/cardMediaLoader";
import {updateParticipantBasket} from "../../services/ParticipantService";

type AddGiftToBasketDialogProps = {
  gift: TGift,
  addedQuantity: number,
  isMobile: boolean,
  params: any,
  open: boolean,
  setOpen: (open: boolean) => void
}

export default function AddGiftToBasketDialog(props: AddGiftToBasketDialogProps): JSX.Element {
  const {gift, isMobile, addedQuantity, params, open, setOpen} = props;
  const {t} = useTranslation();
  const navigate = useNavigate();
  const giftImage = isMobile ? gift.image_urls[0]?.small??null : gift.image_urls[0]?.medium??null;

  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;

  useEffect(() => {
    const operationParticipantId = getOperationParticipantId();
    if (open && operationParticipantId) {
      const basket = addGiftToBasket(store.basket, gift, addedQuantity);
      updateParticipantBasket(operationParticipantId, basket)
        .then(basket => globalStoreReducer(STORE, StoreActions.UPDATE_BASKET, {basket: basket}))
        .catch(() => navigate(PUBLIC_URL.HOME));
    }
  }, [open]);

  const closeDialog = (): void => setOpen(false);
  const redirectToStore = (): void => navigate(PUBLIC_URL.HOME, {state: {page: params.page, filters: params.filters, sorting: params.sorting, simplesearch: params.simplesearch, scroll: params.scroll}});
  const redirectToBasket = (): void => navigate(PUBLIC_URL.BASKET);

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <Typography color="neutral.dark" sx={{fontWeight: "bold"}} variant="h2">
            {t('gift_add_to_basket.title')}
          </Typography>
          <IconButton onClick={closeDialog}><CloseIcon/></IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{display: "flex", flexDirection: "column"}}>
        <Box sx={{display: "flex", mb: 3}}>
          <CardMediaLoader image={giftImage} isMobile={isMobile} sx={{width: 180, height: 180}}/>
          <Box sx={{display: "flex", flexDirection: "column", ml: 3}}>
            <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold"}}>
              {gift.name}
            </Typography>
            {!store.operation?.shop.hidePricing && <Box sx={{display: "flex", mt: 2}}>
              <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", mr: 0}}>
                {gift.sellingPointPrice}
              </Typography>
              <Typography variant="caption" color="neutral.dark" sx={{fontWeight: "bold"}}>
                {t("gift_add_to_basket.points")}
              </Typography>
            </Box>}
          </Box>
        </Box>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <PrimaryBigButton sx={{width: "100%"}} label={t("gift_add_to_basket.continue_shopping")} action={redirectToStore}/>
        </Box>
        <Typography variant="body2" sx={{fontWeight: "medium", width: "100%", textAlign: "center", py: 0, mt:3, mb: 1, cursor: "pointer"}} onClick={redirectToBasket}>
          {t("gift_add_to_basket.show_basket")}
        </Typography>
      </DialogContent>
    </Dialog>
  )
}
