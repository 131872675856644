import React from "react";
import {Box, SxProps, Tooltip} from "@mui/material";
import {Theme} from "@mui/material/styles";

export default function BasicTooltip(props: {title: string, followCursor: boolean, componentsProps?: any, children: any, sx?: SxProps<Theme>}): JSX.Element {

  const {title, followCursor, componentsProps, sx} = props;
    
  return (
    <Tooltip 
      title={title} 
      followCursor={followCursor}
      componentsProps={componentsProps}
    >
      <Box sx={{...sx}}>
        {props.children}
      </Box>
    </Tooltip>
  )
}

export function PreviewTooltip(props: {backgroundColor: string, color: string, children: any, sx?: SxProps<Theme>}): JSX.Element {

  const {backgroundColor, color, sx} = props;
    
  return (
    <BasicTooltip 
      title={"Non disponible en mode prévisualisation"} 
      followCursor={true}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: backgroundColor,
            maxWidth: "120px",
            textAlign: "center",
            color: color
          },
        },
      }}
      sx={{...sx}}
    >
      {props.children}
    </BasicTooltip>
  )
}
