import jwt from 'jwt-decode';
import {Token} from "../../services/AuthenticationService";
import {TBasket} from "./models/TBasket";
import {TGift} from "./models/TGift";
import {MOBILE_SWITCH_SIZE} from "../../tokens/libertyTheme";
import moment from "moment";
import {TOperation} from './models/TOperation';
import {TOperationParticipant} from './models/TOperationParticipant';

export function getToken(): string|null {
  const auth = localStorage.getItem("auth");
  if (auth) {
    const item: string = auth ?? "";
    if (typeof JSON.parse(item) === "string") {
      return JSON.parse(item);
    }
  }
  return null;
}

export function isAuthenticated(): boolean {
  return localStorage.getItem("auth") != null;
}

export function isProd(): boolean {
  return process.env.REACT_APP_ENVIRONMENT == "production";
}

export function stayConnected(): boolean {
  return localStorage.getItem("stayConnected") === "true";
}

export function mustChangePassword(): boolean {
  const token = localStorage.getItem("auth");
  if (!token) {
    return false;
  }
  const jwtToken = jwt<Token>(JSON.parse(token));
  return jwtToken.mustChangePassword??false;
}

export function getOperationParticipantId(): string | null {
  return localStorage.getItem("id");
}

export const checkIsMobile = (): boolean => window.innerWidth <= MOBILE_SWITCH_SIZE;

export const checkIsShopOpen = (operation?: TOperation|null): boolean => {
  if (operation) {
    if (!operation.endDate && moment(operation.startDate, 'YYYY-MM-DD HH:mm:ss').toDate() <= new Date()) {
      return true;
    } else if (operation.endDate && moment(operation.startDate, 'YYYY-MM-DD HH:mm:ss').toDate() <= new Date() && moment(operation.endDate, 'YYYY-MM-DD HH:mm:ss').toDate() >= new Date()) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const checkIsUserSav= (operation?: TOperation|null, user?: TOperationParticipant|null): boolean => {
  if (operation && user) {
    if ('savLimitDate' in user && user.savLimitDate && moment(user.savLimitDate, 'YYYY-MM-DD HH:mm:ss').toDate() >= new Date() && moment(operation.startDate, 'YYYY-MM-DD HH:mm:ss').toDate() <= new Date()) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export function setScrollPosition(scrollPosition: number): void {
  localStorage.setItem('scroll', JSON.stringify(scrollPosition));
}

export function getScrollPosition(): number | null {
  const scroll = localStorage.getItem("scroll");
  if (!scroll) {
    return null;
  }
  return parseInt(scroll);
}

export function getBasketSize(basket: TBasket): number {
  const giftQuantities = basket.map(gift => gift.quantity)??[];

  if (giftQuantities.length == 0) {
    return 0;
  }
  return giftQuantities.reduce((total, actual) => total + actual);
}

export function getBasketTotalPrice(basket: TBasket): number {
  const giftPrices = basket.map(gift => gift.quantity * gift.points)??[];

  if (giftPrices.length == 0) {
    return 0;
  }
  return giftPrices.reduce((total, actual) => total + actual);
}

export function addGiftToBasket(basket: TBasket, gift: TGift, quantity: number): TBasket {
  const existingGift = basket.find(g => g.id === gift.id);

  if (existingGift) {
    existingGift.quantity += quantity;
  } else {
    basket.unshift({
      id: gift.id,
      name: gift.name,
      quantity,
      deliveryTime: gift.sale_delay,
      deliveryByEmail: gift.x_email_delivery??false,
      image: gift.image_urls[0],
      points: gift.sellingPointPrice,
      euros: gift.sellingEuroNetPrice
    });
  }

  return basket;
}

export function updateBasketGift(basket: TBasket, id: number, quantity: number): TBasket {
  const existingGift = basket.find(g => g.id === id);

  if (existingGift) {
    existingGift.quantity = quantity;
  }

  return basket;
}

export function removeGiftFromBasket(basket: TBasket, giftId: number): TBasket {
  const oldGift = basket.find(gift => gift.id === giftId);

  if (oldGift) {
    basket.splice(basket.indexOf(oldGift), 1);
  }

  return basket;
}
