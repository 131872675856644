import {TGift} from "../../common/struct/models/TGift";
import {Box, Chip, Typography} from "@mui/material";
import FrenchFlagIcon from "../../assets/french_flag.svg";
import LeafIcon from "../../assets/leaf.svg";
import React from "react";
import {useTranslation} from "react-i18next";

export default function GiftDescription(props: {gift: TGift, isMobile: boolean}): JSX.Element {
  const {gift, isMobile} = props;
  const {t} = useTranslation();

  return <>
    {(gift.x_french || gift.x_ecofriendly) && <Box sx={{mb: 6, display: "flex"}}>
      {gift.x_french && <Chip icon={<img src={FrenchFlagIcon} alt=""/>} label={t("gift_modal.french")} sx={{".MuiChip-icon": {pl: 1, m: "0px"}, backgroundColor: "ornament.light", border: "1px solid", borderColor: "ornament.dark"}} />}
      {gift.x_ecofriendly && <Chip icon={<img src={LeafIcon} alt=""/>} label={t("gift_modal.ecofriendly")} sx={{".MuiChip-icon": {pl: 1, m: "0px"}, ml: 1, backgroundColor: "ornament.light", border: "1px solid", borderColor: "ornament.dark"}} />}
    </Box>}
    <Box sx={{mb: 1}}>
      <Typography variant="body1" component="h2" sx={{height: "36px", color: "neutral.main", fontWeight: "bold", p: "0px", pt: 2, boxSizing: "border-box", boxShadow: 6}}>
        {t("gift_modal.presentation")}
      </Typography>
      <Typography variant="body2" component="div" sx={{p: "0px", pt: 1, display: "inline-block", boxSizing: "border-box", color: "neutral.dark", fontWeight: "400"}}
        dangerouslySetInnerHTML={{__html: gift.x_website_description}} />
    </Box>
    {gift.x_website_caracteristics && gift.x_website_caracteristics != "<p><br></p>" && <Box sx={{mb: isMobile ? 1 : 3}}>
      <Typography variant="body1" component="h2" sx={{height: "36px", color: "neutral.main", fontWeight: "bold", p: "0px", pt: 2, boxSizing: "border-box", boxShadow: 6}}>
        {t("gift_modal.characteristics")}
      </Typography>
      <Typography variant="body2" component="div" sx={{p: "0px", pt: 1, display: "inline-block", boxSizing: "border-box", color: "neutral.dark", fontWeight: "400"}}
        dangerouslySetInnerHTML={{__html: gift.x_website_caracteristics}} />
    </Box>}
  </>
}
