import React, {useContext, useState} from 'react';
import {Typography, Dialog, DialogTitle, DialogContent, DialogActions} from "@mui/material";
import {useTranslation} from 'react-i18next';
import {PrimaryBigButton, SecondaryBigButton} from '../../components/buttons/mainButton';
import {PUBLIC_URL} from '../../common/struct/urlManager';
import {useNavigate} from "react-router-dom";
import {AppContext} from '../../App';
import {getOperationParticipantId} from "../../common/struct/globalVar";
import {updateUserUnsuscribeDate} from '../../services/ParticipantService';

export default function Unsuscribe(): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const STORE = useContext<any>(AppContext);
  const [store] = STORE;

  const [loading, setLoading] = useState<boolean>(false);

  const handleUnsuscribeUser = (): void => {
    setLoading(true);
    if (store.user.participant.id) {
      updateUserUnsuscribeDate(getOperationParticipantId() ?? "", store.user.participant.id)
        .then(() => navigate(PUBLIC_URL.HOME))
        .finally(() => setLoading(false))  
    }
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={true}>
      <DialogTitle sx={{p: 4, mb: 4, backgroundColor: 'ornament.main', borderBottom: "1px solid", borderColor: "ornament.dark"}}>
        <Typography sx={{fontWeight: "bold", color: 'neutral.main'}} variant="h2">{t('unsuscribe.title')}</Typography>
      </DialogTitle>
      <DialogContent sx={{p: 4}}>
        <Typography sx={{fontWeight: "bold", color: 'neutral.main'}} variant="body2">{t('unsuscribe.text')}</Typography>
      </DialogContent>
      <DialogActions>
        <SecondaryBigButton submit={false} label={t("unsuscribe.button.cancel")} action={(): void => navigate(PUBLIC_URL.HOME)}/>
        <PrimaryBigButton submit={true} label={t("unsuscribe.button.validate")} loading={loading} action={(): void => handleUnsuscribeUser()} />
      </DialogActions>
    </Dialog>
  )
}
