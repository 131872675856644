import React from 'react';
import {Box, Typography} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Link} from 'react-router-dom';
import {PUBLIC_URL} from "../../common/struct/urlManager";

export default function FaqQuestion(props: {label: string, section: string}): JSX.Element {
  const label = props.label;
  const section = props.section;

  return (
    <Box sx={{py: 2, cursor: "pointer", borderBottom: "1px solid", borderColor: "ornament.dark"}}>
      <Link to={{pathname:PUBLIC_URL.FAQ, hash:section}} style={{display: "flex", justifyContent: "space-between", alignItems: "center", textDecoration: "none"}}>
        <Typography variant="body2" color="neutral.main" sx={{mr: 1}}>{label}</Typography>
        <ChevronRightIcon sx={{color: "neutral.main", width: "18px", height: "18px"}} />
      </Link>
    </Box>
  )
}
