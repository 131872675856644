import React, {useContext, useEffect, useState} from "react";
import {Alert, Box, Card, useMediaQuery} from "@mui/material";
import {AppContext} from "../../App";
import Loader from "../../components/loader/loader";
import {PUBLIC_URL} from "../../common/struct/urlManager";
import {useTranslation} from "react-i18next";
import {MAX_DESKTOP_SIZE} from "../../tokens/libertyTheme";
import {TOrder} from "../../common/struct/models/TOrder";
import OrderCard from "../orders/orderCard";
import {useNavigate, useParams} from "react-router-dom";
import {getGiftOrder} from "../../services/OrderService";
import {errorManager, manageStringError} from "../../common/methods/ApiService";
import AccountHeader from "../../patterns/header/accountHeader";
import backgroundOrder from "../../assets/background_order.svg";
import {ShippingAddressOverview} from "../basket/shippingAddressOverview";

export default function Order(): JSX.Element {
  const {t} = useTranslation();
  const [store] = useContext<any>(AppContext);
  const navigate = useNavigate();
  const {giftOrderId} = useParams<any>();

  useEffect(() => {
    document.title = t("tab_title.order");
  }, []);

  const maxWidth = (store.isMobile) ? "100vw" : MAX_DESKTOP_SIZE;
  const matchesM = useMediaQuery('(min-width:1200px)');

  const [order, setOrder] = useState<TOrder | null>(null);
  const [loadingOrder, setLoadingOrder] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (giftOrderId == undefined) {
      navigate(PUBLIC_URL.ORDERS);
      return;
    }

    getGiftOrder(giftOrderId)
      .then((data: any) => setOrder(data))
      .catch((error) => {
        manageStringError(errorManager(error, t, navigate, store), setError, t);
        navigate(PUBLIC_URL.HOME)
      })
      .finally((): void => setLoadingOrder(false));

  }, [giftOrderId]);

  return (
    <Box sx={{minHeight: !store.isMobile ? "calc(100vh - 182px)" : "calc(100vh - 292px)", pb: !store.isMobile ? "40px" : "32px", boxSizing: "border-box"}}>
      {loadingOrder ?
        <Loader size={75} sx={{height: "calc(100vh - 149px)"}} />
        :
        <Box>
          <AccountHeader background={backgroundOrder} title={t(store.isMobile ? "orders.order.date_mobile" : "orders.order.date", {date: order && new Date(order.createdAt)})} breadcrumb={[
            {label: t("account.my_account"), url: PUBLIC_URL.ACCOUNT},
            {label: t("orders.title"), url: PUBLIC_URL.ORDERS},
            {label: t(store.isMobile ? "orders.order.date_mobile" : "orders.order.date", {date: order && new Date(order.createdAt)})}]} />
          <Box sx={{display: "flex", margin: "0 auto", width: "100%", maxWidth: maxWidth}}>
            {error && <Alert variant="filled" severity="error" sx={{mt: "40px", width: "100%"}}>{error}</Alert>}
          </Box>
          <Box sx={{maxWidth: maxWidth, px: !store.isMobile ? 4 : 2, display: "flex", justifyContent: "center", margin: "0 auto", mt: !store.isMobile ? "-20px" : "-12px", position: "relative", zIndex: "1"}}>
            <Box sx={{width: "100%", maxWidth: "974px", mr: !store.isMobile ? 4 : ""}}>
              {order && <OrderCard order={order} details />}
            </Box>
            {!store.isMobile && !error && order?.deliveryAddress &&
              <Card sx={{width: matchesM ? "100%" : "45%", maxWidth: "386px", ml: 4, height: "fit-content", borderWidth: "1px", borderStyle: "solid", borderColor: "ornament.dark", p: 6}}>
                <ShippingAddressOverview address={order.deliveryAddress}/>
              </Card>
            }
          </Box>
        </Box>
      }
    </Box>
  )
}
