import React, {Dispatch, SetStateAction, useState} from "react";
import {Box, Button, Card, Typography} from "@mui/material";
import {TUniverse} from "../../common/struct/models/TUniverse";
import {TFilter} from "../../common/struct/models/TFilter";
import UniverseChoice from "./universeChoice";
import {useTranslation} from "react-i18next";
import CachedIcon from '@mui/icons-material/Cached';
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

export default function DesktopUniverses(props: {universes: TUniverse[], filters: TFilter, setFilters: Dispatch<SetStateAction<TFilter>>, reload: boolean, setReload: Dispatch<SetStateAction<boolean>>}): JSX.Element {
  const {t} = useTranslation();
  const {universes, filters, setFilters, reload, setReload} = props;
  const [universesAreOpen, setUniversesAreOpen] = useState(true);

  const resetUniverses = (): void => {
    setReload(true);
    setFilters(prevState => ({
      ...prevState,
      universIds: []
    }));
  }

  const updateOpen = (): void => {
    setUniversesAreOpen(!universesAreOpen);
  }

  return (
    <Card sx={{boxShadow: 0, pt: 1, pb: !universesAreOpen ? 1 : "0px", borderWidth: 1, borderStyle: "solid", borderColor: "ornament.dark"}}>
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", cursor: "pointer", p: 3}} onClick={updateOpen}>
        <Typography variant="body2" color="neutral.dark" sx={{fontWeight: "bold", textTransform: "uppercase"}}>{t("shop.filters.universes.title")}</Typography>
        {universesAreOpen ? <RemoveIcon sx={{color: "neutral.main"}}/> : <AddIcon sx={{color: "neutral.main"}}/>}
      </Box>
      {universesAreOpen && <>
        {universes.map(universe => <UniverseChoice key={universe.parent.x_name} universe={universe} filters={filters} setFilters={setFilters} reload={reload} setReload={setReload}/>)}
        <Box sx={{p: 3}}>
          <Button disabled={reload} variant="outlined" startIcon={<CachedIcon/>} onClick={resetUniverses} sx={{p: 0, width: "100%"}}>
            {t("shop.filters.reset")}
          </Button>
        </Box>
      </>}
    </Card>
  )
}
