import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import React, {useContext} from "react";
import {AppContext} from "../../App";
import {PUBLIC_URL} from "../../common/struct/urlManager";
import {Box, Button, Typography} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";

export default function AskForMore(): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const STORE = useContext<any>(AppContext);
  const [store] = STORE;

  const redirectToContact = (): void => navigate(PUBLIC_URL.CONTACT);
  return (
    <Box sx={{display: "flex", flexDirection: store.isMobile ? "column" : "row", alignItems: "center", justifyContent: "center", py: store.isMobile ? 3 : 4, px: store.isMobile ? 2 : 4, backgroundColor: "info.light"}}>
      <Typography color="info.dark" sx={{fontWeight: "bold", mr: store.isMobile ? "0px" : 4, mb: store.isMobile ? 4 : "0px", textAlign: store.isMobile ? "center" : "inherit"}}>
        {t("faq.precisions")}
      </Typography>
      <Button variant="outlined" color="info" startIcon={<EmailIcon sx={{width: 24, height: 24}} color="info"/>} onClick={redirectToContact}
        sx={{py: 3, px: 5, borderColor: "info.main", ":hover": {backgroundColor: "info.main", "& *": {color: "info.contrastText"}}}}>
        <Typography color="info.main" sx={{fontWeight: "bold"}}>{t("faq.contact_button")}</Typography>
      </Button>
    </Box>
  )
}
