import {Store, StoreActions} from "../../struct/store";
import {checkIsMobile, checkIsShopOpen, checkIsUserSav} from "../../struct/globalVar";
import {TOperation} from "../../struct/models/TOperation";
import {TOperationParticipant} from "../../struct/models/TOperationParticipant";
import {TUser} from "../../struct/models/TUser";
import {Theme} from "@mui/material/styles";
import {TBasket} from "../../struct/models/TBasket";
import {TAddress} from "../../struct/models/TAddress";

interface GlobalStoreReducerProps {
  operation?: TOperation,
  user?: TOperationParticipant,
  theme?: Theme,
  participant?: TUser,
  basket?: TBasket,
  basketTotal?: number,
  basketMonetaryComplement?: number,
  stayConnected?: boolean,
  basketConfirmed?: boolean,
  address?: TAddress,
  paymentIntentId?: string
  isPreview?: boolean
}

export function globalStoreReducer(STORE: any, type: StoreActions, props: GlobalStoreReducerProps = {}): void {
  const {operation, user, theme, participant, basket, stayConnected, basketConfirmed, basketTotal, basketMonetaryComplement,
    address, paymentIntentId, isPreview} = props;
  const [, setStore] = STORE;
  switch (type) {
  case StoreActions.SET_OPERATION: {
    setStore((prevState: Store) => ({...prevState, operation, isShopOpen: checkIsShopOpen(operation)}));
    return;
  }
  case StoreActions.SET_USER: {
    setStore((prevState: Store) => ({...prevState, user: {...prevState.user, participant: participant}}));
    return;
  }
  case StoreActions.SET_THEME: {
    setStore((prevState: Store) => ({...prevState, theme}));
    return;
  }
  case StoreActions.LOGIN: {
    if (user) {
      setStore((prevState: Store) => ({...prevState, user, isLoggedIn: true, basket: user.basket, basketConfirmed: false, isUserSav: checkIsUserSav(prevState.operation, user)}));
      if (stayConnected) localStorage.setItem('stayConnected', stayConnected.toString());
    }
    return;
  }
  case StoreActions.LOGOUT: {
    localStorage.removeItem('auth');
    localStorage.removeItem('id');
    localStorage.removeItem('stayConnected');
    setStore((prevState: Store) => ({...prevState, user: null, isLoggedIn: false, basket: [], basketConfirmed: false, isUserSav: null, isPreview: null}));
    return;
  }
  case StoreActions.UPDATE_IS_MOBILE: {
    setStore((prevState: Store) => ({...prevState, isMobile: checkIsMobile()}));
    return;
  }
  case StoreActions.UPDATE_BASKET: {
    setStore((prevState: Store) => {
      const basketIsConfirmed = basketConfirmed == undefined ? prevState.basketConfirmed : basketConfirmed;
      return {...prevState, basket: basket, basketConfirmed: basketIsConfirmed}
    });
    return;
  }
  case StoreActions.EMPTY_BASKET: {
    setStore((prevState: Store) => ({
      ...prevState,
      basket: [],
      basketConfirmed: false,
      paymentIntentId: null,
      paymentConfirmed: false,
      shippingAddress: null,
      billingAddress: null
    }));
    return;
  }
  case StoreActions.UPDATE_BASKET_CONFIRMED: {
    setStore((prevState: Store) => ({...prevState, basketConfirmed: basketConfirmed}));
    return;
  }
  case StoreActions.UPDATE_PAYMENT_INTENT: {
    setStore((prevState: Store) => ({...prevState, paymentIntentId: paymentIntentId??null}));
    return;
  }
  case StoreActions.UPDATE_BASKET_TOTAL: {
    setStore((prevState: Store) => ({...prevState, basketTotal: basketTotal??0, basketMonetaryComplement: basketMonetaryComplement??0}));
    return;
  }
  case StoreActions.UPDATE_SHIPPING_ADDRESS: {
    setStore((prevState: Store) => ({...prevState, shippingAddress: address}));
    return;
  }
  case StoreActions.UPDATE_BILLING_ADDRESS: {
    setStore((prevState: Store) => ({...prevState, billingAddress: address}));
    return;
  }
  case StoreActions.UPDATE_IS_PREVIEW: {
    setStore((prevState: Store) => ({...prevState, isPreview: isPreview}));
    return;
  }
  }
}

export default {globalStoreReducer};
