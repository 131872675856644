import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {AppContext} from "../../App";
import {Alert, Box, Card, CardActions, CardContent, Typography} from "@mui/material";
import {TOrder, TOrderLine} from "../../common/struct/models/TOrder";
import {PrimaryBigButton, SecondaryBigButton} from "../../components/buttons/mainButton";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import OrderLineCard from './orderLineCard';
import {useNavigate} from "react-router-dom";
import {orderUrl, PUBLIC_URL} from "../../common/struct/urlManager";
import {IconPosition} from "../../components/buttons/buttonProps";
import {downloadOrderBill, downloadOrderReceipt} from "../../services/OrderService";
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import {getExportDocument} from "../../common/methods/exportDocument";

export default function OrderCard(props: {order: TOrder, key?: number, details?: boolean}): JSX.Element {
  const {t} = useTranslation();
  const [store] = useContext<any>(AppContext);
  const navigate = useNavigate();

  const {order, details = false} = props;
  const [loadingReceipt, setLoadingReceipt] = useState<boolean>(false);
  const [loadingBill, setLoadingBill] = useState<boolean>(false);
  const [error, setError] = useState<string|null>(null);

  const redirectToOrder = (): void => navigate(orderUrl(PUBLIC_URL.ORDER, order.id.toString()));

  const downloadReceipt = (): void => {
    setLoadingReceipt(true);
    setError(null);
    downloadOrderReceipt(order.id)
      .then(getExportDocument)
      .catch(() => setError(t("orders.order.no_receipt")))
      .finally(() => setLoadingReceipt(false));
  }

  const downloadBill = (): void => {
    setLoadingBill(true);
    setError(null);
    downloadOrderBill(order.id)
      .then(getExportDocument)
      .catch(() => setError(t("orders.order.no_bill")))
      .finally(() => setLoadingBill(false));
  }

  return (
    <Card sx={{display: "flex", flexDirection: "column", backgroundColor: "ornament.light", boxShadow: 1, borderWidth: 1, borderStyle: "solid", borderColor: "ornament.dark", mb: 4}}>
      <CardContent sx={{width: "100%", p: !store.isMobile ? 6 : 3, pb: !store.isMobile ? "20px !important" : "16px !important", boxSizing: "border-box"}}>
        {!details &&
          <Box sx={{display: "flex", justifyContent: "space-between", width: "100%", pb: !store.isMobile ? 4 : 3, flexDirection: !store.isMobile ? "row" : "column"}}>
            <Box sx={{display: "flex", flexDirection: "column"}}>
              <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold", mb: 1}}>
                {t("orders.order.date", {date: new Date(order.createdAt)})}
              </Typography>
              {!store.operation.shop.hidePricing && <Box sx={{display: "flex", flexWrap: "wrap", height: "100%", py: 1, mb: !store.isMobile ? "0px" : 4}}>
                <Typography variant="body1" color="neutral.dark" sx={{mr: 0}}>
                  {t("orders.total")}
                </Typography>
                <Box sx={{display: "flex"}}>
                  <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold"}}>
                    {Object.values(order.lines).reduce((r, {pointsPrice, quantity}) => r + pointsPrice * quantity, 0)}
                  </Typography>
                  <Typography variant="caption" color="neutral.dark" sx={{fontWeight: "bold", ml: 0, fontVariantPosition: "super"}}>
                    {t('orders.order.point', {count: Object.values(order.lines).reduce((r, {pointsPrice, quantity}) => r + pointsPrice * quantity, 0)})}
                  </Typography>
                </Box>
              </Box>}
              {parseFloat(order.monetaryComplement) > 0 && <Box sx={{display: "flex", flexWrap: "wrap", height: "100%", py: 1, mb: !store.isMobile ? "0px" : 4, mt: !store.isMobile ? -1 : -3}}>
                <Typography variant="body1" color="neutral.dark" sx={{mr: 0}}>
                  {t("orders.complement")}
                </Typography>
                <Box sx={{display: "flex"}}>
                  <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold"}}>
                    {order.monetaryComplement}
                  </Typography>
                  <Typography variant="caption" color="neutral.dark" sx={{fontWeight: "bold", ml: 0, fontVariantPosition: "super"}}>
                    €
                  </Typography>
                </Box>
              </Box>}
            </Box>
            <PrimaryBigButton label={t("orders.order.detail_button")} icon={ChevronRightIcon} position={IconPosition.RIGHT} sx={{height: "fit-content"}} action={redirectToOrder} />
          </Box>
        }
        <Box>
          {order.lines.map((line: TOrderLine, index: number) =>
            <OrderLineCard key={index} line={line} lineLength={order.lines.length} index={index} details={details}/>
          )}
        </Box>
      </CardContent>
      {error && <Alert severity="error" variant="filled" sx={{mx: !store.isMobile ? 6 : 3, mb: 4}}>{error}</Alert>}
      {details &&
        <CardActions sx={{minHeight: "96px", px: !store.isMobile ? 6 : 3, pt: 4, boxSizing: "border-box", backgroundColor: "ornament.main", display: "flex", justifyContent: !store.isMobile ? "space-between" : "center", flexWrap: "wrap", flexDirection: !store.isMobile ? "row" : "column"}}>
          <Box sx={{display: "flex", flexDirection: !store.isMobile ? "row" : "column", order: !store.isMobile ? 1 : 2, mb: 2}}>
            <SecondaryBigButton label={t("orders.order.download_receipt")} loading={loadingReceipt} disabled={loadingBill} action={downloadReceipt} icon={ReceiptLongOutlinedIcon} position={IconPosition.LEFT} sx={{mr: !store.isMobile ? 5 : "0px", mb: !store.isMobile ? "0px" : 2, backgroundColor: "ornament.light", height: "fit-content"}}/>
            {parseFloat(order.monetaryComplement) > 0 && <SecondaryBigButton label={t("orders.order.download_bill")} loading={loadingBill} disabled={loadingReceipt} action={downloadBill} icon={ReceiptLongOutlinedIcon} position={IconPosition.LEFT} sx={{mr: !store.isMobile ? 5 : "0px", mb: !store.isMobile ? "0px" : 2, backgroundColor: "ornament.light", height: "fit-content"}}/>}
          </Box>
          <Box sx={{order: !store.isMobile ? 3 : 2, mb: 4}}>
            {!store.operation.shop.hidePricing && <Box sx={{display: "flex", flexWrap: "wrap", alignItems: "center"}}>
              <Typography variant="body1" color="neutral.dark" sx={{mr: 0}}>
                {t("orders.total")}
              </Typography>
              <Box sx={{display: "flex"}}>
                <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold"}}>
                  {Object.values(order.lines).reduce((r, {pointsPrice, quantity}) => r + pointsPrice * quantity, 0)}
                </Typography>
                <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold", fontSize: "14px", ml: "5px", mt: "-4px"}}>
                  {t('orders.order.point', {count: Object.values(order.lines).reduce((r, {pointsPrice, quantity}) => r + pointsPrice * quantity, 0)})}
                </Typography>
              </Box>
            </Box>}
            {parseFloat(order.monetaryComplement) > 0 && <Box sx={{display: "flex", flexWrap: "wrap", alignItems: "center"}}>
              <Typography variant="body1" color="neutral.dark" sx={{mr: 0}}>
                {t("orders.complement")}
              </Typography>
              <Box sx={{display: "flex"}}>
                <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold"}}>
                  {order.monetaryComplement}
                </Typography>
                <Typography variant="caption" color="neutral.dark" sx={{fontWeight: "bold", ml: 0, fontVariantPosition: "super"}}>
                  €
                </Typography>
              </Box>
            </Box>}
            <Box sx={{display: "flex", flexWrap: "wrap"}}>
              <Typography variant="body2" color="neutral.main" sx={{mr: 0}}>
                {t("orders.order.quantity")}
              </Typography>
              <Typography variant="body2" color="neutral.main">
                {t("orders.order.giftWithCount", {count: Object.values(order.lines).reduce((r, {quantity}) => r + quantity, 0)})}
              </Typography>
            </Box>
          </Box>
        </CardActions>
      }
    </Card>
  )
}
