import {TAddress} from "../../common/struct/models/TAddress";
import {Box, Typography} from "@mui/material";
import React from "react";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import {useTranslation} from "react-i18next";

export function BillingAddressOverview(props: {address: TAddress, sx?: SxProps<Theme>}): JSX.Element {
  const {t} = useTranslation();
  return (
    <Box sx={{display: "flex", flexDirection: "column", ...props.sx}}>
      <Box sx={{display: "flex"}}>
        <CreditCardIcon sx={{width: 24, height: 24, color: "neutral.dark"}}/>
        <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold", ml: 1}}>
          {t("basket.billing.label")}
        </Typography>
      </Box>
      <BillingAddressOverviewInfo address={props.address}/>
    </Box>
  )
}

export function BillingAddressOverviewWithoutLabel(props: {address: TAddress, sx?: SxProps<Theme>}): JSX.Element {
  return (
    <Box sx={{display: "flex", flexDirection: "column", ...props.sx}}>
      <BillingAddressOverviewInfo address={props.address}/>
    </Box>
  )
}

function BillingAddressOverviewInfo(props: {address: TAddress}): JSX.Element {
  const {address} = props;
  return (
    <>
      {props.address.society && <Typography variant="body2" color="neutral.dark" sx={{fontWeight: "medium", mb: -3, mt: 4}}>
        {props.address.society}
      </Typography>}
      <Typography variant="body2" color="neutral.dark" sx={{fontWeight: "medium", mb: 1, mt: 4}}>
        {props.address.firstName} {props.address.lastName}
      </Typography>
      <Typography variant="body2" color="neutral.dark">{address.street}</Typography>
      <Typography variant="body2" color="neutral.dark">{address.street2}</Typography>
      <Typography variant="body2" color="neutral.dark">{address.street3}</Typography>
      <Typography variant="body2" color="neutral.dark">
        {address.zip} {address.city}
      </Typography>
      <Typography variant="body2" color="neutral.dark">{address.vat}</Typography>
      <Typography variant="body2" color="neutral.dark">{address.phone}</Typography>
    </>
  )
}
