import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {TFilter} from "../../common/struct/models/TFilter";
import {Alert, Box, Button, Card, Typography} from "@mui/material";
import CachedIcon from '@mui/icons-material/Cached';
import {PrimarySmallButton} from "../../components/buttons/mainButton";
import FilterCriteria from "./filterCriteria";
import FilterPoints from "./filterPoints";
import FilterBrands from "./filterBrands";
import {useTranslation} from "react-i18next";
import {TOperation} from "../../common/struct/models/TOperation";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

export default function DesktopFilters(props: {filters: TFilter, setFilters: Dispatch<SetStateAction<TFilter>>, brands: string[], operation: TOperation, setReload: Dispatch<SetStateAction<boolean>>, reload: boolean}): JSX.Element {
  const {t} = useTranslation();
  const {filters, setFilters, brands, operation, setReload, reload} = props;
  const [ecoFriendlySelected, setEcoFriendlySelected] = useState(filters.ecofriendly);
  const [madeInFranceSelected, setMadeInFranceSelected] = useState(filters.french);
  const [minPoints, setMinPoints] = useState<number|null>(filters.minPoints);
  const [maxPoints, setMaxPoints] = useState<number|null>(filters.maxPoints);
  const [error, setError] = useState<string|null>(null);
  const [filtersAreOpen, setFiltersAreOpen] = useState(true);

  const [reloadFilter, setReloadFilter] = useState<boolean>(false);

  const handleExcludedBrands = (brands: string[]): void => {
    setFilters(prevState => ({...prevState, brands: brands}));
  }

  const applyPriceFilter = (): void => {
    if (minPoints && maxPoints && minPoints > maxPoints) {
      setError(t("shop.filters.points_error"));
    } else {
      setError(null);
      setReload(true);
      setFilters(prevState => ({...prevState, minPoints: minPoints, maxPoints: maxPoints}));
    }
  }

  const resetFilters = (): void => {
    setEcoFriendlySelected(false);
    setMadeInFranceSelected(false);
    setMinPoints(null);
    setMaxPoints(null);
    setReload(true);
    setFilters(prevState => ({
      ...prevState,
      simplesearch: "",
      brands: [],
      french: false,
      ecofriendly: false,
      maxPoints: null,
      minPoints: null
    }));
  }

  useEffect(() => {
    setFilters(prevState => ({
      ...prevState,
      ecofriendly: ecoFriendlySelected,
      french: madeInFranceSelected
    }));
    if (reloadFilter) {
      setReload(true);
    }
  }, [ecoFriendlySelected, madeInFranceSelected])

  const updateOpen = (): void => {
    setFiltersAreOpen(!filtersAreOpen);
  }

  return (
    <Card sx={{display: "flex", flexDirection: "column", boxShadow: 0, pt: 1, pb: !filtersAreOpen ? 1 : "0px", mt: 4, borderWidth: 1, borderStyle: "solid", borderColor: "ornament.dark"}}>
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", cursor: "pointer", p: 3}} onClick={updateOpen}>
        <Typography variant="body2" color="neutral.dark" sx={{fontWeight: "bold", textTransform: "uppercase"}}>{t("shop.filters.title")}</Typography>
        {filtersAreOpen ? <RemoveIcon sx={{color: "neutral.main"}}/> : <AddIcon sx={{color: "neutral.main"}}/>}
      </Box>
      {filtersAreOpen && <>
        <FilterCriteria ecoFriendlySelected={ecoFriendlySelected} setEcoFriendlySelected={setEcoFriendlySelected} madeInFranceSelected={madeInFranceSelected} setMadeInFranceSelected={setMadeInFranceSelected} setReload={setReloadFilter} reload={reload}/>
        {/** Filtrer par point **/}
        {operation.shop && operation.shop.pricing !== "UNIQUE" && <Box sx={{display: "flex", flexDirection: "column", pb: 2}}>
          <FilterPoints minPoints={minPoints} setMinPoints={setMinPoints} maxPoints={maxPoints} setMaxPoints={setMaxPoints} reload={reload}/>
          {error && <Alert sx={{mx: 3, mb: 3}} variant="filled" severity="error">{error}</Alert>}
          <PrimarySmallButton sx={{mx: 3}} label={t("shop.filters.validate")} action={applyPriceFilter} disabled={reload}/>
        </Box>}
        {/** Filtrer par marque **/}
        <Box sx={{display: "flex", flexDirection: "column", pb: 2, borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "ornament.dark"}}>
          <FilterBrands brands={brands} selectedBrands={filters.brands} setSelectedBrands={handleExcludedBrands} placeholder={t("shop.filters.brands.placeholder_desktop")} setReload={setReload} reload={reload}/>
        </Box>
        <Button variant="outlined" startIcon={<CachedIcon/>} disabled={reload} onClick={resetFilters} sx={{m: 3, p: 0}}>
          {t("shop.filters.reset")}
        </Button>
      </>}
    </Card>
  )
}
