import {Dispatch, SetStateAction} from "react";
import {Theme} from "@mui/material/styles";
import {checkIsMobile, isAuthenticated} from "./globalVar";
import {TOperationParticipant} from "./models/TOperationParticipant";
import {TOperation} from "./models/TOperation";
import {TBasket} from "./models/TBasket";
import {TAddress} from "./models/TAddress";

export enum StoreActions {
  SET_OPERATION,
  SET_USER,
  SET_THEME,
  LOGIN,
  LOGOUT,
  UPDATE_IS_MOBILE,
  UPDATE_BASKET,
  EMPTY_BASKET,
  UPDATE_BASKET_CONFIRMED,
  UPDATE_PAYMENT_INTENT,
  UPDATE_BASKET_TOTAL,
  UPDATE_SHIPPING_ADDRESS,
  UPDATE_BILLING_ADDRESS,
  UPDATE_IS_PREVIEW,
  UPDATE_COMPANY
}

export type Store = {
  user: TOperationParticipant|null,
  operation: TOperation|null,
  theme: Theme|null,
  basket: TBasket,
  basketConfirmed: boolean,
  basketTotal: number,
  basketMonetaryComplement: number,
  paymentIntentId: string|null,
  shippingAddress: TAddress|null,
  billingAddress: TAddress|null,
  isLoggedIn: boolean,
  isMobile: boolean;
  isShopOpen: boolean|null;
  isUserSav: boolean|null;
  isPreview: boolean|null;
}

export type StoreContext = [Store, Dispatch<SetStateAction<Store>>];

export const initialStore: Store = {
  user: null,
  operation: null,
  theme: null,
  basket: [],
  basketConfirmed: false,
  basketTotal: 0,
  basketMonetaryComplement: 0,
  paymentIntentId: null,
  shippingAddress: null,
  billingAddress: null,
  isLoggedIn: isAuthenticated(),
  isMobile: checkIsMobile(),
  isShopOpen: null,
  isUserSav: null,
  isPreview: null
};


