import {Autocomplete, Box, TextField, Typography} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import React, {Dispatch, SetStateAction} from "react";
import {useTranslation} from "react-i18next";

type FilterBrandsProps = {
  brands: string[],
  selectedBrands: string[],
  setSelectedBrands: (brands: string[]) => void,
  placeholder: string,
  setReload?: Dispatch<SetStateAction<boolean>>,
  reload?: boolean
}

export default function FilterBrands(props: FilterBrandsProps): JSX.Element {
  const {t} = useTranslation();
  const {brands, selectedBrands, setSelectedBrands, placeholder, setReload, reload = false} = props;

  return (
    <>
      <Typography variant="caption" color="neutral.dark" sx={{px: 3, pr: 3, mt: 3, fontWeight: "regular", textTransform: "uppercase"}}>
        {t("shop.filters.brands.filter")}
      </Typography>
      <Box sx={{px: 3, pr: 3, pb: 2, pt: 1}}>
        <Autocomplete multiple options={brands} value={selectedBrands} disabled={reload}
          sx={{"& .MuiChip-root": {color: "neutral.dark", fontSize: "12px", fontWeight: "medium", borderRadius: "4px"}}}
          onChange={(_, brands: string[]): void => {
            setSelectedBrands(brands);
            if (setReload) setReload(true);
          }}
          ChipProps={{deleteIcon: <ClearIcon sx={{fill: "unset", width: 12, height: 12}}/>}}
          renderInput={(params: any): JSX.Element => (
            <TextField {...params} variant="outlined" placeholder={placeholder}/>
          )}/>
      </Box>
    </>
  )
}
