import {Alert, Box, Card, Grid, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {PUBLIC_URL} from "../../common/struct/urlManager";
import FaqPreview from "../../patterns/faq/faqPreview";
import {useTranslation} from "react-i18next";
import {AppContext} from "../../App";
import {MAX_DESKTOP_SIZE} from "../../tokens/libertyTheme";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import contactImage from "../../assets/contact.svg";
import FormInput from "../../patterns/form/formInput";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import {TViolation} from "../../common/struct/models/TViolation";
import {StoreContext} from "../../common/struct/store";
import {createSupportTicket} from "../../services/OperationService";
import {errorManager, manageStringError} from "../../common/methods/ApiService";
import {useNavigate} from "react-router-dom";
import {
  TSupportSubjectOffline,
  TSupportSubjectOnline,
  TSupportTicketCreate
} from "../../common/struct/models/TSupportTicket";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import {verifyCaptcha} from "../../services/AuthenticationService";
import {TOrder, TOrderLine} from "../../common/struct/models/TOrder";
import {getOperationParticipantId} from "../../common/struct/globalVar";
import {getOperationParticipantOrders} from "../../services/OrderService";
import {format} from 'date-fns'
import {INPUT_TYPES} from "../../patterns/form/formConstants";
import {PreviewTooltip} from "../../components/tooltip/tooltip";

export default function Contact(): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;

  useEffect(() => {
    document.title = t("tab_title.contact");
  }, []);

  const [saving, setSaving] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState<string|null>(null);
  const [success, setSuccess] = useState<string|null>(null);
  const [firstName, setFirstName] = useState(store.user?.participant.firstName??"");
  const [lastName, setLastName] = useState(store.user?.participant.lastName??"");
  const [email, setEmail] = useState(store.user?.participant.email??"");
  const [phone, setPhone] = useState(store.user?.participant.phone??"");
  const [subject, setSubject] = useState(store.user ? TSupportSubjectOnline.ORDER_STATUS : TSupportSubjectOffline.CONNECTION);
  const [object, setObject] = useState("");
  const [description, setDescription] = useState("");
  const [violations, setViolations] = useState<TViolation[]>([]);

  const [captchaChecked, setCaptchaChecked] = useState<boolean>(store.user != null);
  const [captchaToken, setCaptchaToken] = useState<string|null>(null);
  const [orderTicket, setOrderTicket] = useState<string|undefined>(undefined);
  const maxWidth = (store.isMobile) ? "100vw" : MAX_DESKTOP_SIZE;

  const subjectsOnline = Object.values(TSupportSubjectOnline).map(subject => ({label: subject, value: subject}));
  const subjectsOffline = Object.values(TSupportSubjectOffline).map(subject => ({label: subject, value: subject}));

  const subjects = store.user ? subjectsOnline : [...subjectsOffline, ...subjectsOnline];
  const [orderLines, setOrderLines] = useState<TOrderLine[]>([]);
  const envDev = process.env.REACT_APP_API_HOST;

  useEffect(() => {
    if (captchaToken) {
      verifyCaptcha(captchaToken)
        .then(() => setCaptchaChecked(true))
        .catch(error => {
          const errorResult = errorManager(error, t, STORE, false);
          if (typeof errorResult === 'string') setError(errorResult);
        })
    }
  }, [captchaToken]);

  const loadOrders = (): void => {
    const operationParticipantId = getOperationParticipantId();
    if(operationParticipantId) {
      getOperationParticipantOrders(operationParticipantId)
        .then((data: TOrder[]) => setOrderLines(data.flatMap(order => order.lines)))
        .catch((error) => manageStringError(errorManager(error, t, navigate, STORE), setError, t));
    }
  }

  useEffect(() => {
    !store.isPreview && loadOrders();
  }, [])

  useEffect(() => {
    setOrderTicket(undefined);
  }, [subject])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    setSubmitted(true);
    event.preventDefault();
    if (store.operation == null) {
      return;
    }

    if (orderTicket == undefined && (subject === TSupportSubjectOnline.ORDER_ISSUE || subject === TSupportSubjectOnline.ORDER_STATUS)) {
      setViolations(prevState => [...prevState, {field: 'order', error: t("error.empty")}]);
      setSubmitted(false);
      return;
    }

    const body: TSupportTicketCreate = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      subject: subject,
      object: object.length > 0 ? object : null,
      description: description,
      giftOrderLine: orderTicket ? '/fo/giftorderlines/' + orderTicket : null,
      operation: '/fo/operations/' + store.operation.id,
    }

    setViolations([]);
    setError(null);
    setSuccess(null);
    setSaving(true);
    createSupportTicket(body)
      .then(() => {
        window.scrollTo(0, 0);
        setSuccess(t("contact.form.success"));
        setDescription("");
        setSubmitted(false);
      })
      .catch(error => {
        const errorResult = errorManager(error, t, navigate, STORE);
        if (typeof errorResult === 'string') setError(errorResult);
        else if (setViolations && Array.isArray(errorResult)) setViolations(errorResult);
      })
      .finally(() => { setSaving(false), setSubmitted(false) })
  }

  return (
    <Box sx={{minHeight: !store.isMobile ? "calc(100vh - 182px)" : "calc(100vh - 292px)", pb: !store.isMobile ? 7 : 6}}>
      <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        <Box sx={{width: "100%", display: "flex", justifyContent: "center", background: "linear-gradient(91.15deg, #6A6E72 0%, #91969C 100%)"}}>
          <Box sx={{width: "100%", maxWidth: maxWidth, display: "flex", flexDirection: store.isMobile ? "column" : "row", justifyContent: "space-between", py: !store.isMobile ? 4 : 3}}>
            <Box sx={{display: "flex", flexDirection: "column", px: !store.isMobile ? "0px" : 3}}>
              <Breadcrumb breadcrumb={[{label: t("contact.form.home"), url: PUBLIC_URL.HOME}, {label: t("contact.form.menu")}]} sx={{mb: 2}} />
              <Typography variant="h1" color="ornament.light" sx={{fontWeight: "bold", zIndex: "1", pb: !store.isMobile ? 4 : 3}}>
                {t("contact.form.title")}
              </Typography>
              <Typography variant="body2" color="ornament.light">{t("contact.form.description")}</Typography>
            </Box>
            <img style={{padding: !store.isMobile ? "0 20px" : "0 16px"}} src={contactImage} alt="contact-image"/>
          </Box>
        </Box>
      </Box>
      <Grid container justifyContent="center" columns={10} columnSpacing={store.isMobile ? 0 : 5} sx={{width: "100%", maxWidth: maxWidth, px: !store.isMobile ? 4 : 2, mt: !store.isMobile ? -4 : -3, mx: "auto"}}>
        <Grid item xs={10} sm={7} sx={{pl: "0px !important"}}>
          <Card sx={{border: "1px solid", borderColor: "ornament.dark", p: 4}}>
            {new Date() > new Date('2024-08-09T23:59:59') && new Date() < new Date('2024-08-18T23:59:59') && <Alert variant="filled" severity="info" sx={{mb: 2}}>{"L'agence est fermée du 12 au 18 août. Votre demande sera traitée à notre retour."}</Alert>}
            {error && <Alert variant="filled" severity="error" sx={{mb: 2}}>{error}</Alert>}
            {success && <Alert variant="filled" severity="success" sx={{mb: 2}}>{success}</Alert>}
            <form onSubmit={handleSubmit}>
              {store.isPreview !== null && store.isPreview ? <PreviewTooltip backgroundColor={'primary.main'} color={"primary.contrastText"}><FormInput disabled={true} required name="firstName" value={firstName} setValue={setFirstName} violations={violations}/></PreviewTooltip> : <FormInput disabled={(orderLines.length > 0 && firstName.length > 0)} required name="firstName" value={firstName} setValue={setFirstName} violations={violations}/>}
              {store.isPreview !== null && store.isPreview ? <PreviewTooltip backgroundColor={'primary.main'} color={"primary.contrastText"}><FormInput disabled={true} required name="lastName" value={lastName} setValue={setLastName} violations={violations}/></PreviewTooltip> : <FormInput disabled={(orderLines.length > 0 && lastName.length > 0)} required name="lastName" value={lastName} setValue={setLastName} violations={violations}/>}
              {store.isPreview !== null && store.isPreview ? <PreviewTooltip backgroundColor={'primary.main'} color={"primary.contrastText"}><FormInput disabled={true} required name="email" value={email} setValue={setEmail} violations={violations} type={INPUT_TYPES.EMAIL}/></PreviewTooltip> : <FormInput disabled={(orderLines.length > 0 && email.length > 0)} required name="email" value={email} setValue={setEmail} violations={violations} type={INPUT_TYPES.EMAIL}/>}
              {store.isPreview !== null && store.isPreview ? <PreviewTooltip backgroundColor={'primary.main'} color={"primary.contrastText"}><FormInput disabled={true} required name="phone" value={phone} setValue={setPhone} violations={violations} type={INPUT_TYPES.TELEPHONE}/></PreviewTooltip> : <FormInput disabled={(orderLines.length > 0 && store.user?.participant.phone == null)} required name="phone" value={phone} setValue={setPhone} violations={violations} type={INPUT_TYPES.TELEPHONE}/>}
              {store.isPreview !== null && store.isPreview ? <PreviewTooltip backgroundColor={'primary.main'} color={"primary.contrastText"}><FormInput disabled={true} required name="subject" value={subject} setValue={setSubject} options={subjects} violations={violations}/></PreviewTooltip> : <FormInput required name="subject" value={subject} setValue={setSubject} options={subjects} violations={violations}/>}
              {store.user && orderLines.length > 0 && (subject == TSupportSubjectOnline.ORDER_STATUS || subject == TSupportSubjectOnline.ORDER_ISSUE) &&
                <FormInput required name="order" value={orderTicket} translate={false} setValue={setOrderTicket} violations={violations}
                  options={orderLines.map(key => ({label: `${key.odooOrderName ?? t("contact.form.command_pending")} / ${format(new Date(key.createdAt), 'dd-MM-yyyy')} / ${key.name}`, value: key.id}))}/>}
              {subject == TSupportSubjectOnline.OTHER && <FormInput name="object" required={subject == TSupportSubjectOnline.OTHER} value={object} setValue={setObject} violations={violations}/>}
              {store.isPreview !== null && store.isPreview ? <PreviewTooltip backgroundColor={'primary.main'} color={"primary.contrastText"}><FormInput disabled={true} required multiline name="description" value={description} setValue={setDescription} violations={violations}/></PreviewTooltip> : <FormInput required multiline name="description" value={description} setValue={setDescription} violations={violations}/>}
              {store.user == null && envDev !== 'http://localhost' && <Box sx={{my: store.isMobile ? "0px" : 2, mx: "auto"}}>
                <HCaptcha
                  sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY??""}
                  languageOverride="fr"
                  onError={(event): void => setError(event)}
                  onVerify={setCaptchaToken}/>
              </Box>}
              {store.isPreview !== null && store.isPreview ? <PreviewTooltip backgroundColor={'primary.main'} color={"primary.contrastText"}><PrimaryBigButton submit={true} loading={saving} disabled={true} sx={{mt: store.isMobile ? "0px" : 2, width: store.isMobile ? "100%" : "inherit"}} label={t("contact.form.send_button")}/></PreviewTooltip> : <PrimaryBigButton submit={true} loading={saving} disabled={!captchaChecked && envDev !== 'http://localhost' || submitted} sx={{mt: store.isMobile ? "0px" : 2, width: store.isMobile ? "100%" : "inherit"}} label={t("contact.form.send_button")}/>}
            </form>
          </Card>
        </Grid>
        <Grid item xs={10} sm={3}>
          <FaqPreview/>
        </Grid>
      </Grid>
    </Box>
  )
}
