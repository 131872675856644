import {TAddress} from "./TAddress";

export enum TOrderLineStatus {
  ONGOING = 'ONGOING',
  VALIDATED = 'VALIDATED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
  REFUNDED = 'REFUNDED'
}

export interface TOrderLine {
  "@id": string;
  "@type": string;
  id: string;
  mainImageUrl: string;
  name: string;
  odooProductId: number;
  pointsPrice: number;
  quantity: number;
  reference: string;
  vat: number;
  odooOrderName: string;
  odooTrackingReference: string;
  odooTrackingUrl: string;
  createdAt: string;
  updatedAt: string;
  status: TOrderLineStatus;
}

export interface TOrder {
  "@type": string;
  "@id": string;
  id: string;
  odooOrderName: string;
  deliveryAddress: TAddress;
  invoiceAddress: TAddress;
  lines: TOrderLine[];
  monetaryComplement: string;
  createdAt: string;
  updatedAt: string;
}

export interface TOrderPayment {
  stripeSecret: string;
  paymentIntentId: string;
}
