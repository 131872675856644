import {Alert, Box} from "@mui/material";
import React, {useContext, useState} from "react";
import {PasswordForm} from "../resetPassword/passwordForm";
import {getOperationParticipantId} from "../../common/struct/globalVar";
import {updateParticipantPassword} from "../../services/ParticipantService";
import {globalStoreReducer} from "../../common/methods/context/globalStoreReducer";
import {StoreActions} from "../../common/struct/store";
import {errorManager} from "../../common/methods/ApiService";
import {AppContext} from "../../App";
import {TViolation} from "../../common/struct/models/TViolation";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function InformationPassword(): JSX.Element {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const STORE = useContext<any>(AppContext);
  const [store] = STORE;

  const [error, setError] = useState<string|null>(null);
  const [success, setSuccess] = useState<string|null>(null);
  const [violations, setViolations] = useState<TViolation[]>([]);

  const changePassword = (password: string, oldPassword: string): Promise<void> => {
    setError(null);
    setSuccess(null);
    setViolations([]);

    return updateParticipantPassword(getOperationParticipantId() ?? "", oldPassword, password)
      .then((participant) => {
        globalStoreReducer(STORE, StoreActions.SET_USER, {user: store.user, participant: participant.participant});
        setSuccess(t("reset_password.success"));
      })
      .catch((error: any) => {
        const errorResult = errorManager(error, t, navigate, STORE);
        if (typeof errorResult === 'string') setError(errorResult);
        else if (Array.isArray(errorResult)) setViolations(errorResult);
      })
  }

  return (
    <>
      {error && <Alert variant="filled" severity="error" sx={{mx: 5, mt: 3, mb: -4}}>{error}</Alert>}
      {success && <Alert variant="filled" severity="success" sx={{mx: 5, mt: 3, mb: -4}}>{success}</Alert>}
      <Box sx={{p: store.isMobile ? -1 : 1}}>
        <PasswordForm changePassword={changePassword} violations={violations} setViolations={setViolations} reset={false}/>
      </Box>
    </>
  )
}
