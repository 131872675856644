import {TSubUniverse, TUniverse} from "../../common/struct/models/TUniverse";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import CheckboxMenu from "../../components/menu/checkboxMenu";
import {Avatar, Box, Typography} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {TFilter} from "../../common/struct/models/TFilter";

type UniverseChoiceProps = {
  universe: TUniverse,
  filters: TFilter,
  reload?: boolean,
  setReload?: Dispatch<SetStateAction<boolean>>
  setFilters: Dispatch<SetStateAction<TFilter>>,
  selectedUniverses?: number[],
  setSelectedUniverses?: Dispatch<SetStateAction<number[]>>,
}

export default function UniverseChoice(props: UniverseChoiceProps): JSX.Element {
  const {universe, filters, setFilters, selectedUniverses, setSelectedUniverses, setReload, reload = false} = props;
  const [universeIsOpen, setUniverseOpen] = useState(false);
  const actualSelectedUniverses = selectedUniverses??filters.universIds;

  const computeSelectedSubUniverses = (): number => {
    let selected = 0;
    actualSelectedUniverses.forEach(universeId => {
      universe.children.forEach(child => {
        if (universeId === child.id) selected += 1;
      })
    })
    return selected;
  }
  const [subUniversesCount, setSubUniversesCount] = useState(computeSelectedSubUniverses());
  const addSubUniverse = (): void => setSubUniversesCount(subUniversesCount + 1);
  const removeSubUniverse = (): void => setSubUniversesCount(subUniversesCount - 1);

  useEffect(() => {
    setSubUniversesCount(computeSelectedSubUniverses());
  }, [actualSelectedUniverses])

  const handleOpen = (): void => {
    if (!reload) setUniverseOpen(!universeIsOpen);
  }
  const backgroundColor = universeIsOpen ? "ornament.main" : "transparent";
  const displayChildren = universeIsOpen ? "grid" : "none";

  const universeIsSelected = (id: number): boolean => {
    return actualSelectedUniverses.find(universeId => universeId == id) != null;
  }

  const subUniverseChoice = (subUniverse: TSubUniverse): JSX.Element => {
    const [isSelected, setSelected] = useState(universeIsSelected(subUniverse.id));

    const handleSelect = (): void => {
      setSelected(!isSelected);
      if (setReload) setReload(true);
      if (isSelected) {
        if (setSelectedUniverses && setSelectedUniverses) setSelectedUniverses(prevState => [...prevState.slice(0, actualSelectedUniverses.indexOf(subUniverse.id)), ...prevState.slice(actualSelectedUniverses.indexOf(subUniverse.id) + 1)]);
        else setFilters(prevState => ({...prevState, universIds: [...prevState.universIds.slice(0, filters.universIds.indexOf(subUniverse.id)), ...prevState.universIds.slice(filters.universIds.indexOf(subUniverse.id) + 1)]}));
        removeSubUniverse();
      } else {
        if (selectedUniverses && setSelectedUniverses) setSelectedUniverses(prevState => [...prevState, subUniverse.id]);
        else setFilters(prevState => ({...prevState, universIds: [...prevState.universIds, subUniverse.id]}));
        addSubUniverse();
      }
    }

    useEffect(() => {
      setSelected(universeIsSelected(subUniverse.id));
    }, [actualSelectedUniverses])

    return <CheckboxMenu key={subUniverse.id} id={subUniverse.id} name={subUniverse.x_name} isSelected={isSelected} handleSelect={handleSelect} disabled={reload}/>
  }

  return <Box key={universe.parent.x_name} sx={{display: "flex", flexDirection: "column",
    backgroundColor: backgroundColor, borderBottomStyle: "solid", borderBottomWidth: "1px", borderBottomColor: "ornament.dark"}}>
    <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", pr: 3, px: 3, pb: 2, pt: 2, cursor: "pointer"}} onClick={handleOpen}>
      <Box sx={{display: "flex", alignItems: "center"}}>
        <Typography variant="body2" color="neutral.main" sx={{fontWeight: "bold"}}>{universe.parent.x_name}</Typography>
        {subUniversesCount > 0 && <Avatar sx={{width: 18, height: 18, fontSize: 14, fontWeight: "bold", mx: 1, backgroundColor: "primary.main", color: "primary.contrastText"}}>
          {subUniversesCount}
        </Avatar>}
      </Box>
      {universeIsOpen ? <KeyboardArrowUpIcon sx={{color: "neutral.main"}}/> : <KeyboardArrowDownIcon sx={{color: "neutral.main"}}/>}
    </Box>
    <Box sx={{p: 3, display: displayChildren, rowGap: 3}}>
      {universe.children.map(child => subUniverseChoice(child))}
    </Box>
  </Box>
}
