import React, {ElementType} from "react";
import {OverridableStringUnion} from "@mui/types";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

export enum IconPosition {
  LEFT,
  MIDDLE,
  RIGHT
}

export enum ButtonType {
  PRIMARY,
  SECONDARY,
  GHOST,
  ORNAMENT
}

export enum ButtonSize {
  SMALL,
  BIG
}

export type MainButtonProps = {
  type: ButtonType,
  size: ButtonSize,
  label?: string,
  icon?: ElementType,
  position?: IconPosition,
  disabled?: boolean,
  action?: () => any,
  href?: string,
  sx?: SxProps<Theme>,
  backgroundColor?: string,
  selected?: boolean,
  submit?: boolean,
  loading?: boolean
}

export type ButtonProps = {
  label?: string,
  icon?: ElementType,
  position?: IconPosition,
  disabled?: boolean,
  action?: any,
  href?: string,
  sx?: SxProps<Theme>,
  backgroundColor?: string,
  selected?: boolean,
  submit?: boolean,
  loading?: boolean
}

export type SizeProperties = {
  height: string,
  padding: string,
  iconSize: string,
  fontSize: string
}

export type TypeProperties = {
  variant: OverridableStringUnion<'text' | 'outlined' | 'contained'>,
  fontWeight: string,
  color: string,
  borderColor: string,
  backgroundColor: string,
  disabledColor: string,
  disabledBorderColor: string,
  disabledBackgroundColor: string,
  hoverColor: string,
  hoverBorderColor: string,
  hoverBackgroundColor: string,
  focusColor: string,
  focusBorderColor: string,
  focusBackgroundColor: string
}

export function getStartIcon(props: ButtonProps, fontSize: string): JSX.Element | null {
  if (props.position !== IconPosition.LEFT) {
    return null;
  }
  return getIcon(props, fontSize);
}

export function getEndIcon(props: ButtonProps, fontSize: string): JSX.Element | null {
  if (props.position !== IconPosition.RIGHT) {
    return null;
  }
  return getIcon(props, fontSize);
}

export function getIcon(props: ButtonProps, fontSize: string): JSX.Element | null {
  if (props.icon === undefined) {
    return null;
  }
  const Icon = props.icon;
  return <Icon fontSize={fontSize}/>;
}
