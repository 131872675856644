import {Box, Checkbox, Typography} from "@mui/material";
import React from "react";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

type CheckboxMenuProps = {
  id: string|number,
  name: string,
  isSelected: boolean,
  handleSelect: () => void,
  disabled?: boolean,
  sx?: SxProps<Theme>
}

export default function CheckboxMenu(props: CheckboxMenuProps): JSX.Element {
  const {id, name, isSelected, handleSelect, sx, disabled = false} = props;
  const color = isSelected ? "neutral.dark" : "neutral.main";

  const select = (): void => {
    if (!disabled) handleSelect()
  }
  return (
    <Box key={id} sx={{display: "flex", cursor: "pointer", "&:hover .MuiTypography-body2": {color: "neutral.dark"}, ...sx}} onClick={select}>
      <Checkbox disabled={disabled} checked={isSelected} sx={{color: "primary.main", width: "24px", height: "24px", mr: 1}}/>
      <Typography variant="body2" color={color} sx={{fontWeight: "medium"}}>{name}</Typography>
    </Box>
  )
}
