export const getExportDocument = (response: any): any => {
  if (response == undefined) {
    throw new Error();
  }

  const [, filename] = response.headers.get('content-disposition').split('filename=');
  return response.blob().then((blob: Blob) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    const clickHandler = (): void => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
  })
}
