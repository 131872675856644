import {ShippingType, TAddress} from "../../common/struct/models/TAddress";
import {Box, Typography} from "@mui/material";
import ShippingTypeLabel from "./shippingTypeLabel";
import React from "react";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

export function ShippingAddressOverview(props: {address: TAddress, sx?: SxProps<Theme>}): JSX.Element {
  return (
    <Box sx={{display: "flex", flexDirection: "column", ...props.sx}}>
      <ShippingTypeLabel shippingType={props.address.society ? ShippingType.SOCIETY : ShippingType.HOME}/>
      <ShippingAddressOverviewInfo address={props.address}/>
    </Box>
  )
}

export function ShippingAddressOverviewWithoutLabel(props: {address: TAddress}): JSX.Element {
  return (
    <Box sx={{display: "flex", flexDirection: "column"}}>
      <ShippingAddressOverviewInfo address={props.address}/>
    </Box>
  )
}

function ShippingAddressOverviewInfo(props: {address: TAddress}): JSX.Element {
  return (
    <>
      {props.address.society && <Typography variant="body2" color="neutral.dark" sx={{fontWeight: "medium", mb: -3, mt: 4}}>
        {props.address.society}
      </Typography>}
      <Typography variant="body2" color="neutral.dark" sx={{fontWeight: "medium", mb: 1, mt: 4}}>
        {props.address.firstName} {props.address.lastName}
      </Typography>
      <Typography variant="body2" color="neutral.dark">{props.address.street}</Typography>
      {props.address.street2 && <Typography variant="body2" color="neutral.dark">{props.address.street2}</Typography>}
      {props.address.street3 && <Typography variant="body2" color="neutral.dark">{props.address.street3}</Typography>}
      <Typography variant="body2" color="neutral.dark">
        {props.address.zip} {props.address.city}
      </Typography>
      <Typography variant="body2" color="neutral.dark">{props.address.phone}</Typography>
    </>
  )
}
