import {postWithoutToken} from "../common/methods/ApiService";
import {API_URL, GLOBAL_OPERATION_ID} from "../common/struct/urlManager";
import jwt from "jwt-decode";
import {postExternalWithUrlEncoded} from "../common/methods/ApiService";
import {HCAPTCHA_VERIFY} from "../common/struct/urlManager";
import {getMinimalUserInfo} from "../services/ParticipantService";
import {getMinimalAdminInfo} from "../services/AdminService";
import {TOperationParticipant} from "../common/struct/models/TOperationParticipant";
import {globalStoreReducer} from "../common/methods/context/globalStoreReducer";
import {StoreActions} from "../common/struct/store";

export type Token = {
  username: string,
  roles: string[],
  mustChangePassword?: boolean,
  id: string,
  operationParticipant?: string,
  admin?: string,
  refreshToken: string
}

export async function login(STORE: any, email: string, password: string, operationId: string): Promise<string> {
  return postWithoutToken(API_URL.LOGIN.replace(":" + GLOBAL_OPERATION_ID, operationId), {email: email, password: password, operation: operationId}).then((auth: any) => {
    loginStorage(STORE, auth.token);
    return auth.token;
  });
}

export function loginStorage(STORE: any, token: string): Promise<TOperationParticipant> {
  const authJSON = JSON.stringify(token);
  const jwtToken = jwt<Token>(token);

  localStorage.removeItem('basket');
  localStorage.removeItem('stayConnected');
  localStorage.setItem('auth', authJSON);

  if (jwtToken.operationParticipant) {
    localStorage.setItem('id', jwtToken.operationParticipant);
    return getMinimalUserInfo(jwtToken.operationParticipant).then((res) => {
      globalStoreReducer(STORE, StoreActions.LOGIN, {user: res});
      globalStoreReducer(STORE, StoreActions.UPDATE_IS_PREVIEW, {isPreview: false});
      return res;
    });
  } else {
    localStorage.setItem('id', jwtToken.admin!); // eslint-disable-line
    return getMinimalAdminInfo(jwtToken.admin!).then((res) => { // eslint-disable-line
      globalStoreReducer(STORE, StoreActions.LOGIN, {user: res});
      globalStoreReducer(STORE, StoreActions.UPDATE_IS_PREVIEW, {isPreview: true});
      return res;
    })
  }
}

export async function refreshToken(token: string): Promise<string> {
  return postWithoutToken(API_URL.REFRESH_TOKEN, {refreshToken: jwt<Token>(token).refreshToken}).then((auth: any) => {
    const authJSON = JSON.stringify(auth.token);
    const jwtToken = jwt<Token>(token);

    localStorage.setItem('auth', authJSON);
    if (jwtToken.operationParticipant) {
      localStorage.setItem('id', jwtToken.operationParticipant);
    }
    return auth.token;
  });
}

export function resetPassword(email: string, operation: string): Promise<string> {
  return postWithoutToken(API_URL.RESET_PASSWORD, {email: email, operation: operation});
}

export async function verifyCaptcha(response: string): Promise<void> {
  return postExternalWithUrlEncoded(HCAPTCHA_VERIFY, {response: response, secret: process.env.REACT_APP_HCAPTCHA_SECRET});
}

export function loginWithToken(STORE: any, token: string, operationId: string): Promise<TOperationParticipant> {
  return postWithoutToken(API_URL.LOGIN_WITH_TOKEN.replace(":" + GLOBAL_OPERATION_ID, operationId), {connectionToken: token}).then((auth: any) => {
    return loginStorage(STORE, auth.token);
  });
}

export async function saveCookieConsent(): Promise<void> {
  return postWithoutToken(API_URL.COOKIES, {})
}
