import {Alert, Avatar, Box, Card, Chip, Divider, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {getOperationParticipantId} from "../../common/struct/globalVar";
import {TBasketGift} from "../../common/struct/models/TBasket";
import {useTranslation} from "react-i18next";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import {getGiftQuantities, Violation} from "./nonEmptyBasket";
import {AppContext} from "../../App";
import {useNavigate} from "react-router-dom";
import {PUBLIC_URL} from "../../common/struct/urlManager";
import {TAddress} from "../../common/struct/models/TAddress";
import {addOrderAddress, orderGifts, updateOrderAddress} from "../../services/OrderService";
import {errorManager} from "../../common/methods/ApiService";
import {TViolation} from "../../common/struct/models/TViolation";
import {addParticipantAddress, getMinimalUserInfo} from "../../services/ParticipantService";
import {ShippingAddressOverview} from "./shippingAddressOverview";
import BasketTotal from "./basketTotal";
import {globalStoreReducer} from "../../common/methods/context/globalStoreReducer";
import {StoreActions, StoreContext} from "../../common/struct/store";
import CheckConditions from "./checkConditions";
import PaymentInformation from "./paymentInformation";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

function BasketGiftLine(props: {gift: TBasketGift}): JSX.Element {
  const STORE = useContext<any>(AppContext);
  const [store] = STORE;

  const {t} = useTranslation();
  return (
    <Box sx={{display: "flex", alignItems: "start", width: "100%", justifyContent: "space-between", mb: 4}}>
      <Typography variant="body1" color="neutral.dark">
        {props.gift.name}
      </Typography>
      {!store.operation.shop.hidePricing && <Box sx={{display: "flex", alignItems: "start", ml: 4}}>
        <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold", mr: 0}}>
          {props.gift.points * props.gift.quantity}
        </Typography>
        <Typography variant="caption" color="neutral.dark" sx={{fontWeight: "bold", fontSize: "10px"}}>
          {t("basket.overview.points")}
        </Typography>
      </Box>}
    </Box>
  )
}

type BasketOverviewProps = {
  step: number,
  error?: string|null,
  violations?: Violation[]|null,
  setViolations?: (violations: TViolation[]) => void,
  shippingAddress?: TAddress|null,
  billingAddress?: TAddress|null,
  favoriteShippingAddress?: boolean,
  favoriteBillingAddress?: boolean,
  validatePayment?: () => Promise<void>,
  sx?: SxProps<Theme>
}

export default function BasketOverview(props: BasketOverviewProps): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {step, error, violations, setViolations, shippingAddress, billingAddress,
    favoriteShippingAddress = true, favoriteBillingAddress = true, validatePayment, sx} = props;

  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;

  const [total, setTotal] = useState(0);
  const [monetaryComplement, setMonetaryComplement] = useState(0);
  const [errorGifts, setErrorGifts] = useState<string|null>(null);
  const [errorConfirm, setErrorConfirm] = useState<string|null>(null);
  const [checkConditions, setCheckConditions] = useState<boolean>(false);
  const [disableNextStep, setDisableNextStep] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTotal(store.basketTotal??0);
    setMonetaryComplement(store.basketMonetaryComplement??0);
  }, [store.basketTotal, store.basketMonetaryComplement])

  useEffect(() => {
    if (violations && violations.length > 0) {
      setErrorGifts(t("basket.overview.validate_error", {number: violations.length}));
    } else {
      setErrorGifts(null);
    }
  }, [violations])

  useEffect(() => {
    if (step === 1) {
      setDisableNextStep(error !== null || errorGifts !== null);
    }
    if (step === 2) {
      setDisableNextStep(((violations??[].length) > 0 || errorConfirm !== null) || (monetaryComplement > 0 ? false : !checkConditions));
    }
    if (step === 3) {
      setDisableNextStep((violations??[].length) > 0 || errorConfirm !== null);
    }
    if (step === 4) {
      setDisableNextStep(!checkConditions);
    }
  }, [step, error, errorGifts, errorConfirm, violations, checkConditions, monetaryComplement])

  useEffect(() => {
    if (step === 4 && store.shippingAddress?.id && store.billingAddress?.id && store.paymentIntentId) {
      setLoading(true);
      setDisableNextStep(true);
      createOrder(store.shippingAddress.id, store.billingAddress.id, store.paymentIntentId)
    }
  }, [store.paymentIntentId])

  useEffect(() => {
    if (step === 2 && store.shippingAddress?.id && store.basketMonetaryComplement > 0) {
      navigate(PUBLIC_URL.BASKET_BILLING_ADDRESS);
    }
  }, [store.shippingAddress, store.basketMonetaryComplement])

  useEffect(() => {
    if (step === 3 && store.billingAddress?.id) {
      navigate(PUBLIC_URL.BASKET_PAYMENT);
    }
  }, [store.billingAddress])

  const validateBasket = (): void => {
    const operationParticipantId = getOperationParticipantId();
    if (operationParticipantId == null) {
      return;
    }

    const shippingAddressId = store.shippingAddress?.id;
    const billingAddressId = store.billingAddress?.id;
    if (step === 1) {
      globalStoreReducer(STORE, StoreActions.UPDATE_BASKET_CONFIRMED,{basketConfirmed: true});
      navigate(PUBLIC_URL.BASKET_SHIPPING_ADDRESS);
    }
    if (step === 2 && shippingAddress) {      
      manageAddress(shippingAddress, operationParticipantId, favoriteShippingAddress)
        .then(address => {
          const addressId = address?.id;
          if (address == null || addressId == null) {
            return Promise.reject(t("global.default_error_message"));
          }

          if (monetaryComplement > 0) {
            globalStoreReducer(STORE, StoreActions.UPDATE_SHIPPING_ADDRESS, {address: address});
          } else {
            setDisableNextStep(true);
            createOrder(addressId);
          }
        })
        .catch(error => {
          const errorResult = errorManager(error, t, navigate, STORE);
          if (setViolations && Array.isArray(errorResult)) setViolations(errorResult);
          else if (typeof errorResult === 'string') setErrorConfirm(errorResult)
          setLoading(false)
          setDisableNextStep(false)
        })
    }
    if (step === 3 && billingAddress && store.shippingAddress) {
      manageAddress(billingAddress, operationParticipantId, favoriteBillingAddress)
        .then(address => globalStoreReducer(STORE, StoreActions.UPDATE_BILLING_ADDRESS, {address: address}))
        .catch(error => {
          const errorResult = errorManager(error, t, navigate, STORE);
          if (setViolations && Array.isArray(errorResult)) setViolations(errorResult);
          else if (typeof errorResult === 'string') setErrorConfirm(errorResult)
        })
        .finally(() => setLoading(false))
    }
    if (step === 4 && shippingAddressId && billingAddressId && validatePayment) {
      setErrorConfirm(null);
      setLoading(true);

      validatePayment()
        .catch(error => {
          setLoading(false);
          const errorResult = errorManager(error, t, navigate, STORE);
          if (setViolations && Array.isArray(errorResult)) navigate(PUBLIC_URL.BASKET);
          else if (typeof errorResult === 'string') setErrorConfirm(errorResult)
        })
    }
  }

  const manageAddress = (address: TAddress, operationParticipantId: string, favoriteAddress: boolean): Promise<TAddress|undefined> => {
    setErrorConfirm(null);
    setLoading(true);
    const addressId = address.id;
    if (addressId) {
      return favoriteAddress ? addParticipantAddress(operationParticipantId, address).then(user => user.addresses.find(a => a.id == addressId))
        : updateOrderAddress(addressId, address);
    } else {
      return favoriteAddress ? addParticipantAddress(operationParticipantId, address).then(user => user.addresses.find(a => a.type == address.type))
        : addOrderAddress(address);
    }
  }

  const createOrder = (shippingAddressId: string, billingAddressId?: string, intentId?: string): void => {
    if (store.operation == null) {
      return;
    }

    orderGifts(store.operation.shop.id, store.basket, shippingAddressId, billingAddressId, intentId)
      .then(result => {
        getMinimalUserInfo(getOperationParticipantId() ?? "")
          .then((participant) => {
            globalStoreReducer(STORE, StoreActions.LOGIN, {user: participant});
          });
        navigate(PUBLIC_URL.ORDER_DONE.replace(':giftOrderId', result.id));
        setDisableNextStep(false);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        setDisableNextStep(false);
        const errorResult = errorManager(error, t, navigate, STORE);
        if (setViolations && Array.isArray(errorResult)) navigate(PUBLIC_URL.BASKET);
        else if (typeof errorResult === 'string') setErrorConfirm(t("global.order_error_message"));
      })
  }

  return store.operation == null || store.user == null ? <></> :
    <Box sx={{position: "relative", height: "100%"}}>
      <Box sx={{position: "sticky", top: "0%", ...sx}}>
        <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", mb: 4}}>
          {t("basket.overview.title")}
        </Typography>
        <Card sx={{p: store.isMobile ? 4 : 6, border: "1px solid", borderColor: "ornament.dark", display: "flex", flexDirection: "column"}}>
          <Box sx={{display: "flex", alignItems: "baseline", mb: 4}}>
            <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold", mr: 2}}>
              {t("basket.overview.basket")}
            </Typography>
            <Divider orientation="vertical" variant="middle"
              sx={{backgroundColor: "neutral.light", borderColor: "neutral.light", width: "1px", height: "12px", mr: 2}}/>
            <Typography variant="body1" color="neutral.dark">{getGiftQuantities(store.basket)}</Typography>
          </Box>
          {store.basket.map((gift, index) => <BasketGiftLine gift={gift} key={index} />)}
          {step === 2 && shippingAddress && (shippingAddress?.id?.length ?? 0) > 0 && <ShippingAddressOverview address={shippingAddress} sx={{py: 4, borderTop: "1px solid", borderColor: "ornament.dark"}}/>}
          {step >= 3 && store.shippingAddress && <ShippingAddressOverview address={store.shippingAddress} sx={{py: 4, borderTop: "1px solid", borderColor: "ornament.dark"}}/>}
          {!store.operation.shop.hidePricing && <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", py: 4,
            borderTop: "1px solid", borderColor: "ornament.dark"}}>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Avatar sx={{backgroundColor: "ornament.dark", mr: 2, width: 24, height: 24}}>
                <Typography variant="body2" color="neutral.dark" sx={{fontWeight: "bold"}}>
                  {store.user.participant.firstName?.substring(0, 1)??""}
                </Typography>
              </Avatar>
              <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold", mr: 2}}>
                {t("basket.overview.my_balance")}
              </Typography>
            </Box>
            <Chip color="primary" label={<Box sx={{display: "flex", alignItems: "start"}}>
              <Typography variant="body1" color="primary.contrastText" sx={{fontWeight: "bold", mr: 0}}>
                {store.user.rewardPoints}
              </Typography>
              <Typography variant="caption" color="primary.contrastText" sx={{fontWeight: "bold"}}>
                {t("basket.overview.points")}
              </Typography>
            </Box>}/>
          </Box>}
          {!store.operation.shop.hidePricing && <BasketTotal total={total} complement={monetaryComplement} sx={{py: 4}}/>}
          {((monetaryComplement == 0 && step === 2) || step === 4) && <CheckConditions checkConditions={checkConditions} setCheckConditions={setCheckConditions}/>}
          {errorGifts && !error && <Alert severity="error" variant="filled" sx={{mb: 4}}>{errorGifts}</Alert>}
          {errorConfirm && !error && <Alert severity="error" variant="filled" sx={{mb: 4}}>{errorConfirm}</Alert>}
          {error && <Alert severity="error" variant="filled" sx={{mb: 4}}>{error}</Alert>}
          <PrimaryBigButton disabled={disableNextStep} loading={loading} action={(): void => { validateBasket(), setDisableNextStep(true) }}
            label={t("basket.overview.validate_step_" + step, {amount: monetaryComplement})} sx={{width: "100%"}}/>
          {step === 4 && !store.operation.shop.hidePricing && monetaryComplement > 0 && <PaymentInformation isMobile={store.isMobile}/>}
        </Card>
      </Box>
    </Box>
}
