import {FormControlLabel, Radio} from "@mui/material";
import React from "react";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

type RadioButtonProps = {
  label: string,
  isSelected: boolean,
  handleSelect: () => void,
  disabled?: boolean,
  sx?: SxProps<Theme>
}

export default function RadioButton(props: RadioButtonProps): JSX.Element {
  const {label, isSelected, handleSelect, sx, disabled = false} = props;

  const color = (isSelected) ? "neutral.dark" : "neutral.main";
  const backgroundColor = (isSelected) ? "ornament.main" : "transparent";
  return (
    <FormControlLabel control={<Radio sx={{mr: 1}}/>} label={label} onClick={handleSelect} checked={isSelected} disabled={disabled}
      sx={{m: "unset", "& span": {p: "unset"}, backgroundColor: backgroundColor,
        borderColor: "ornament.dark", borderBottomStyle: "solid", borderWidth: "1px", ...sx}}
      componentsProps={{typography: {color: color, variant: "body2", fontWeight: "medium"}}}/>
  )
}
