import React, {useContext} from 'react';
import {Card} from "@mui/material";
import {AppContext} from "../../App";

export default function Rules(): JSX.Element {
  const [store] = useContext<any>(AppContext);
  return (
    <Card sx={{m: 5, p: 5, overflowX: "auto", fontFamily: "Mulish", boxShadow: 2}}
      dangerouslySetInnerHTML={{__html: store.operation.rules?.content ?? ''}}/>
  )
}
