import React from "react";
import {ShippingType} from "../../common/struct/models/TAddress";
import {Box, Typography} from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import HomeIcon from "@mui/icons-material/Home";
import {useTranslation} from "react-i18next";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

export default function ShippingTypeLabel(props: {shippingType: string, sx?: SxProps<Theme>}): JSX.Element {
  const {t} = useTranslation();
  const {shippingType, sx} = props;
  return (
    <Box sx={{display: "flex", ...sx}}>
      {shippingType === ShippingType.SOCIETY && <ApartmentIcon sx={{width: 24, height: 24, color: "neutral.dark"}}/>}
      {shippingType === ShippingType.HOME && <HomeIcon sx={{width: 24, height: 24, color: "neutral.dark"}}/>}
      <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold", ml: 1}}>
        {t("basket.shipping.title")} {t("basket.shipping." + shippingType)}
      </Typography>
    </Box>
  )
}
