import {TFilter} from "../../common/struct/models/TFilter";
import React, {Dispatch, SetStateAction, useState} from "react";
import {Alert, Box, Button, Dialog, DialogContent, IconButton, Typography} from "@mui/material";
import {MobileTransition} from "./shopProps";
import ClearIcon from '@mui/icons-material/Clear';
import CachedIcon from "@mui/icons-material/Cached";
import Search from "./search";
import FilterCriteria from "./filterCriteria";
import FilterPoints from "./filterPoints";
import FilterBrands from "./filterBrands";
import {useTranslation} from "react-i18next";

type MobileFilterMenuProps = {
  open: boolean,
  setOpen: (open: boolean) => void,
  brands: string[],
  filters: TFilter,
  setFilters: Dispatch<SetStateAction<TFilter>>,
  search: string|null,
  setSearch: Dispatch<SetStateAction<string|null>>,
  setReload: Dispatch<SetStateAction<boolean>>
}

export default function MobileFilterMenu(props: MobileFilterMenuProps): JSX.Element {
  const {t} = useTranslation();
  const {open, setOpen, brands, filters, setFilters, search, setSearch, setReload} = props;
  const [searchValue, setSearchValue] = useState<string|null>(search);
  const [ecoFriendlySelected, setEcoFriendlySelected] = useState(filters.ecofriendly);
  const [madeInFranceSelected, setMadeInFranceSelected] = useState(filters.french);
  const [minPoints, setMinPoints] = useState<number|null>(filters.minPoints);
  const [maxPoints, setMaxPoints] = useState<number|null>(filters.maxPoints);
  const [selectedBrands, setSelectedBrands] = useState<string[]>(filters.brands);
  const [error, setError] = useState<string|null>(null);

  const resetFilters = (): void => {
    setSearchValue("");
    setEcoFriendlySelected(false);
    setMadeInFranceSelected(false);
    setMinPoints(null);
    setMaxPoints(null);
    setSelectedBrands([]);
  }

  const applyFilters = (): void => {
    if (minPoints && maxPoints && minPoints > maxPoints) {
      setError("Le maximum doit être supérieur au minimum");
    } else {
      setError(null);
      setReload(true);
      setFilters(prevState => ({
        ...prevState,
        ecofriendly: ecoFriendlySelected,
        french: madeInFranceSelected,
        minPoints: minPoints,
        maxPoints: maxPoints,
        brands: selectedBrands
      }))
      setSearch(searchValue);
      setOpen(false);
    }
  }

  return (
    <Dialog open={open} fullScreen TransitionComponent={MobileTransition} sx={{mx: 8, mr: "0px", "& .MuiBackdrop-root": {backgroundColor: "#363739"}}}>
      <IconButton sx={{color: "ornament.light", position: "fixed", top: 0, left: 0, mt: 2, mx: 2, p: "0px"}} onClick={(): void => setOpen(false)}>
        <ClearIcon />
      </IconButton>
      <DialogContent sx={{display: "flex", flexDirection: "column", p: "0px"}}>
        <Typography variant="body2" color="neutral.dark" sx={{py: 2, px: 3, mb: 3, fontWeight: "bold", textTransform: "uppercase",
          borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "ornament.dark"}}>
          {t("shop.filters.title")}
        </Typography>
        <Box sx={{display: "flex", flexDirection: "column", px: 3, py: 2, mb: 3, borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "ornament.dark"}}>
          <Typography variant="caption" color="neutral.main" sx={{mb: 1, fontWeight: "regular", textTransform: "uppercase"}}>
            {t("shop.filters.search.title")}
          </Typography>
          <Search search={search} setSearch={setSearch} label={t("shop.filters.search.label_mobile")} searchValue={searchValue} setSearchValue={setSearchValue}/>
        </Box>
        <FilterCriteria ecoFriendlySelected={ecoFriendlySelected} setEcoFriendlySelected={setEcoFriendlySelected} madeInFranceSelected={madeInFranceSelected} setMadeInFranceSelected={setMadeInFranceSelected}/>
        <Box sx={{display: "flex", flexDirection: "column", mb: 3, borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "ornament.dark"}}>
          <FilterPoints minPoints={minPoints} setMinPoints={setMinPoints} maxPoints={maxPoints} setMaxPoints={setMaxPoints}/>
          {error && <Alert sx={{mx: 3, mb: 3}} variant="filled" severity="error">{error}</Alert>}
        </Box>
        <FilterBrands brands={brands} selectedBrands={selectedBrands} setSelectedBrands={setSelectedBrands} placeholder="Marque"/>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", py: 3, px: 2}}>
          <Button variant="outlined" startIcon={<CachedIcon/>} onClick={resetFilters} sx={{width: "100%", mr: 1, pt: 2, pb: 2, px: 5, pr: 5}}>
            {t("shop.filters.reset")}
          </Button>
          <Button variant="contained" onClick={applyFilters} sx={{width: "100%", pt: 2, pb: 2, px: 5, pr: 5, boxShadow: 0}}>
            {t("shop.filters.apply")}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
