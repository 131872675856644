import {Box, Card, Typography} from "@mui/material";
import {
  GiftDeliveredIcon,
  IncludedTaxesIcon,
  SelectGiftIcon,
  SelectLocationIcon
} from "../../components/banners/withdrawGiftBanners";
import React from "react";
import {useTranslation} from "react-i18next";

export default function GiftProcess(props: {isMobile: boolean}): JSX.Element {
  const {t} = useTranslation();
  const {isMobile} = props;

  return (
    <Card sx={{mb: !isMobile ? 4 : 3, border: "1px solid", borderColor: "ornament.dark", p: 4}}>
      <Box sx={{mb: 6, display: "flex"}}>
        <Box sx={{mr: 2, width: !isMobile ? "auto" : "50%"}}>
          <SelectGiftIcon sx={{mb: 1, maxHeight: !isMobile ? "unset" : "48px"}}/>
          <Typography variant="body2" color="neutral.dark" sx={{fontWeight: 400, maxWidth: !isMobile ? "unset" : "200px", textAlign: "center"}}>
            {t("gift_modal.process.validate")}
          </Typography>
        </Box>
        <Box sx={{width: !isMobile ? "auto" : "50%"}}>
          <SelectLocationIcon sx={{mb: 1, maxHeight: !isMobile ? "unset" : "48px"}}/>
          <Typography variant="body2" color="neutral.dark" sx={{fontWeight: 400, maxWidth: !isMobile ? "unset" : "200px", textAlign: "center"}}>
            {t("gift_modal.process.address")}
          </Typography>
        </Box>
      </Box>
      <Box sx={{display: "flex"}}>
        <Box sx={{mr: 2, width: !isMobile ? "auto" : "50%"}}>
          <GiftDeliveredIcon sx={{mb: 1, maxHeight: !isMobile ? "unset" : "48px"}}/>
          <Typography variant="body2" color="neutral.dark" sx={{fontWeight: 400, maxWidth: !isMobile ? "unset" : "200px", textAlign: "center"}}>
            {t("gift_modal.process.shipping")}
          </Typography>
        </Box>
        <Box sx={{width: !isMobile ? "auto" : "50%"}}>
          <IncludedTaxesIcon sx={{mb: 1, maxHeight: !isMobile ? "unset" : "48px"}}/>
          <Typography variant="body2" color="neutral.dark" sx={{fontWeight: 400, maxWidth: !isMobile ? "unset" : "200px", textAlign: "center"}}>
            {t("gift_modal.process.price")}
          </Typography>
        </Box>
      </Box>
    </Card>
  )
}
