import {Box, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {ReactComponent as VisaIcon} from "../../assets/payment_visa.svg";
import {ReactComponent as CbIcon} from "../../assets/payment_cb.svg";
import {ReactComponent as MasterCardIcon} from "../../assets/payment_mastercard.svg";

export default function PaymentInformation(props: {isMobile: boolean}): JSX.Element {
  const {t} = useTranslation();
  const {isMobile} = props;
  const label = t("basket.overview.paiement");

  return (
    <Box sx={{display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: isMobile ? "unset" : "center", justifyContent: "space-between", mt: 4}}>
      <Typography variant="body2" color="neutral.main" sx={{mb: isMobile ? 0 : "0px", mr: isMobile ? "0px" : 2}}
        dangerouslySetInnerHTML={{__html: isMobile ? label.replace("<br>", " ") : label}}/>
      <Box sx={{display: "flex", alignItems: "center"}}>
        <Box sx={{width: "auto", maxWidth: isMobile ? "unset" : 70, mr: 1}}><VisaIcon/></Box>
        <Box sx={{width: "auto", maxWidth: isMobile ? "unset" : 70, mr: 1}}><CbIcon/></Box>
        <Box sx={{width: "auto", maxWidth: isMobile ? "unset" : 70}}><MasterCardIcon/></Box>
      </Box>
    </Box>
  )
}
