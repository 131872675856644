import React from "react";
import {Navigate} from "react-router-dom";
import {PUBLIC_URL} from "../struct/urlManager";
import {TOperationParticipant} from "../struct/models/TOperationParticipant";
import {TOperation} from "../struct/models/TOperation";
import moment from "moment";

const customRoute = (user: TOperationParticipant|null, operation: TOperation, component: JSX.Element, noRestriction = false, isSAV = false, isPreview = false): JSX.Element => {
  const isShopOpen = operation && ((moment(operation.startDate, 'YYYY-MM-DD HH:mm:ss').toDate() <= new Date() && !operation.endDate) || (moment(operation.startDate, 'YYYY-MM-DD HH:mm:ss').toDate() <= new Date() && moment(operation.endDate, 'YYYY-MM-DD HH:mm:ss').toDate() >= new Date()));
  const isUserSav = user && user.savLimitDate && moment(user.savLimitDate, 'YYYY-MM-DD HH:mm:ss').toDate() >= new Date();

  const savRoute = (): JSX.Element => (isShopOpen || (!isShopOpen && noRestriction) || (!isShopOpen && isUserSav) || (isPreview)) ? (user ? component : <Navigate to={PUBLIC_URL.LOGIN}/>) : <Navigate to={PUBLIC_URL.HOME}/>

  return (
    user?.participant?.mustChangePassword ?
      <Navigate to={PUBLIC_URL.RESET_PASSWORD}/>
      : isSAV ? savRoute() : user ? component : <Navigate to={PUBLIC_URL.LOGIN}/>
  );
}

export default customRoute;
