import React, {useContext} from 'react';
import {Box, Button, Typography, useMediaQuery} from "@mui/material";
import GlobalHeader from "./globalHeader";
import {useLocation, useNavigate} from "react-router-dom";
import {PUBLIC_URL} from "../../common/struct/urlManager";
import BasketHeader from "./basketHeader";
import {AppContext} from '../../App';
import {globalStoreReducer} from "../../common/methods/context/globalStoreReducer";
import {StoreActions} from "../../common/struct/store";
import {useTranslation} from 'react-i18next';

export default function Header(): JSX.Element {
  const {t} = useTranslation();
  const location = useLocation();
  const STORE = useContext<any>(AppContext);
  const navigate = useNavigate();
  const [store] = STORE;

  const breakpoint = useMediaQuery('(min-width:900px)');

  const handleLogout = (): void => {
    navigate(PUBLIC_URL.HOME);
    globalStoreReducer(STORE, StoreActions.LOGOUT);
  }

  const previewMode = <Box sx={{height: "fit-content", backgroundColor: "primary.main", display: "flex", justifyContent: "center", position: "sticky", top: "0px", zIndex: 10}}>
    <Box sx={{width: "1400px", padding: "0 24px", display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: breakpoint ? "row" : "column"}}>
      <Box sx={{width: breakpoint ? "80%" : "100%", padding: breakpoint ? "16px 24px 16px 0" : "16px 0 16px"}}>
        <Typography variant="body1" color="primary.contrastText">{t("preview.banner.text1")}</Typography>
        <Typography variant="body1" color="primary.contrastText">{
          store.operation.endDate ? t("preview.banner.text2_dates", {startDate: new Date(store.operation.startDate), endDate: new Date(store.operation.endDate)}) :
            new Date() > new Date(store.operation.startDate) ? t("preview.banner.text2_startDate_past", {startDate: new Date(store.operation.startDate)}) :
              t("preview.banner.text2_startDate", {startDate: new Date(store.operation.startDate)})
        }</Typography>
      </Box>
      <Button variant="text" sx={{width: "fit-content", margin: "16px 0 16px 24px", color: "primary.contrastText", fontSize: "16px", borderWidth: "1px", borderStyle: "solid", borderColor: "primary.contrastText"}} onClick={handleLogout}>{t("preview.banner.button")}</Button>
    </Box>
  </Box>

  return (
    <>
      {store.isPreview && previewMode}
      <Box sx={{display: "flex", justifyContent: "center", backgroundColor: "ornament.light", boxShadow: 4, zIndex: 6, opacity: 1, top: 0, position: "sticky"}}>
        <meta name="viewport" content="width=device-width, user-scalable=no" />
        <meta name="viewport" content="initial-scale=1, maximum-scale=1" />
        {!location.pathname.startsWith(PUBLIC_URL.BASKET) && <GlobalHeader/>}
        {location.pathname.startsWith(PUBLIC_URL.BASKET) && <BasketHeader/>}
      </Box>
    </>
  )
}
