import React, {useContext} from "react";
import {Box} from "@mui/material";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {Link} from 'react-router-dom';
import {AppContext} from "../../App";

type BreadcrumbProps = {
  label: string,
  url?: string
}

export const PrimaryArrowBreadcrumb = (props: BreadcrumbProps, length?: number): JSX.Element => {

  const {label, url} = props;
  const [store] = useContext<any>(AppContext);

  return (
    <Box sx={{display: "block", whiteSpace: "nowrap", margin: "0 10px 0 0"}}>
      <Box sx={{
        padding: "0 8px 0 8px",
        display: "inline-block",
        position: "relative",
        lineHeight: "22px",
        textDecoration: "none",
        color: "neutral.dark",
        backgroundColor: "neutral.light",
        borderRadius: "4px 0 0 4px",
        fontFamily: "Mulish",
        fontSize: "14px",
        fontWeight: 600,
        "&:hover": {
          cursor: "pointer",
          "&:after": {
            cursor: "pointer"
          }
        },
        "&::after": {
          content: '""',
          position: "absolute",
          left: "100%",
          top: 0,
          width: 0,
          height: 0,
          display : "inline-block",
          borderTopStyle: "solid",
          borderTopWidth: "11px",
          borderTopColor: "transparent",
          borderBottomStyle: "solid",
          borderBottomWidth: "11px",
          borderBottomColor: "transparent",
          borderLeftStyle: "solid",
          borderLeftWidth: "14px",
          borderLeftColor: "neutral.light",
          clear: "both"
        }
      }}>
        {url ? <Link to={url} style={{textDecoration: "none", color: store.theme.palette.neutral.dark}}>{(store.isMobile && length && length > 2) ? "..." : label}</Link> : (store.isMobile && length && length > 2) ? "..." : label}
      </Box>
    </Box>
  )
}

export const SecondaryArrowBreadcrumb = (props: BreadcrumbProps): JSX.Element => {
  const {label, url} = props;
  const [store] = useContext<any>(AppContext);

  return (
    <Box sx={{display: "block", whiteSpace: "nowrap", margin: "0 10px 0 10px"}}>
      <Box sx={{
        padding: "0 0 0 2px",
        display: "inline-block",
        position: "relative",
        lineHeight: "22px",
        textDecoration: "none",
        backgroundColor: "neutral.light",
        fontFamily: "Mulish",
        fontSize: "14px",
        fontWeight: 600,
        width: "fit-content",
        "&:hover": {
          cursor: "pointer",
          "&:after": {
            cursor: "pointer"
          }
        },
        "&::before": {
          content: '""',
          position: "absolute",
          right: "100%",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 1,
          borderTopStyle: "solid",
          borderTopWidth: "11px",
          borderTopColor: "neutral.light",
          borderRightStyle: "solid",
          borderRightWidth: "0px",
          borderRightColor: "neutral.light",
          borderBottomStyle: "solid",
          borderBottomWidth: "11px",
          borderBottomColor: "neutral.light",
          borderLeftStyle: "solid",
          borderLeftWidth: "14px",
          borderLeftColor: "transparent",
          clear: "both"
        },
        "&::after": {
          content: '""',
          position: "absolute",
          left: "100%",
          top: 0,
          width: 0,
          height: 0,
          display : "inline-block",
          borderTopStyle: "solid",
          borderTopWidth: "11px",
          borderTopColor: "transparent",
          borderBottomStyle: "solid",
          borderBottomWidth: "11px",
          borderBottomColor: "transparent",
          borderLeftStyle: "solid",
          borderLeftWidth: "14px",
          borderLeftColor: "neutral.light",
          clear: "both"
        }
      }}>
        <Box sx={{
          padding: "0 6px 0 8px",
          display: "inline-block",
          position: "relative",
          lineHeight: "20px",
          textDecoration: "none",
          color: "neutral.dark",
          backgroundColor: "ornament.light",
          fontFamily: "Mulish",
          fontSize: "14px",
          fontWeight: 600,
          width: "fit-content",
          "&:hover": {
            cursor: "pointer"
          },
          "&::before": {
            content: '""',
            position: "absolute",
            right: "100%",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 1,
            borderTopStyle: "solid",
            borderTopWidth: "10px",
            borderTopColor: "ornament.light",
            borderRightStyle: "solid",
            borderRightWidth: "0px",
            borderRightColor: "ornament.light",
            borderBottomStyle: "solid",
            borderBottomWidth: "10px",
            borderBottomColor: "ornament.light",
            borderLeftStyle: "solid",
            borderLeftWidth: "12.7px",
            borderLeftColor: "transparent",
            clear: "both"
          },
          "&::after": {
            content: '""',
            position: "absolute",
            left: "100%",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 1,
            display : "inline-block",
            borderTopStyle: "solid",
            borderTopWidth: "10px",
            borderTopColor: "transparent",
            borderBottomStyle: "solid",
            borderBottomWidth: "10px",
            borderBottomColor: "transparent",
            borderLeftStyle: "solid",
            borderLeftWidth: "12.7px",
            borderLeftColor: "ornament.light",
            clear: "both"
          }
        }}>
          {url ? <Link to={url} style={{textDecoration: "none", color: store.theme.palette.neutral.dark}}>{label}</Link> : label}
        </Box>
      </Box>
    </Box>
  )
}

export default function Breadcrumb(props: {breadcrumb: BreadcrumbProps[], sx?: SxProps<Theme>}): JSX.Element {
  const {breadcrumb, sx} = props;

  return (
    <Box sx={{padding: "0 50px 0 0", overflow: "hidden", display: "flex", fontSize: "1.3rem", position: "relative", zIndex: 1, mb: 2, ...sx}}>
      {breadcrumb.map((element: {label: string, url?: string}, index: number) =>
        index === 0 ?
          <PrimaryArrowBreadcrumb key="first-nav" label={element.label} url={element.url ? element.url : ""}/>
          :
          <SecondaryArrowBreadcrumb key={"nav-" + index} label={element.label} url={element.url ? element.url : ""}/>
      )}
    </Box>
  )
}
