import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../../App";
import {Alert, Box} from '@mui/material';
import Loader from '../../components/loader/loader';
import {useTranslation} from 'react-i18next';
import {MAX_DESKTOP_SIZE} from "../../tokens/libertyTheme";
import {getOperationParticipantId} from '../../common/struct/globalVar';
import {getOperationParticipantOrders} from "../../services/OrderService";
import {errorManager, manageStringError} from '../../common/methods/ApiService';
import {useNavigate} from "react-router-dom";
import {TOrder} from '../../common/struct/models/TOrder';
import OrderCard from './orderCard';
import {PUBLIC_URL} from '../../common/struct/urlManager';
import backgroundOrder from "../../assets/background_order.svg";
import FaqPreview from "../../patterns/faq/faqPreview";
import AccountHeader from '../../patterns/header/accountHeader';
import NoOrder from "./noOrder";

export default function Orders(): JSX.Element {
  const {t} = useTranslation();
  const STORE = useContext<any>(AppContext);
  const [store] = STORE;
  const navigate = useNavigate();
  const maxWidth = (store.isMobile) ? "100vw" : MAX_DESKTOP_SIZE;

  useEffect(() => {
    document.title = t("tab_title.orders");
  }, []);

  const [orders, setOrders] = useState<TOrder[] | null>(null);
  const [loadingOrders, setLoadingOrders] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const loadOrders = (): void => {
    const operationParticipantId = getOperationParticipantId();

    if (operationParticipantId == null) {
      redirectToHome();
      return;
    }

    getOperationParticipantOrders(operationParticipantId)
      .then((data: TOrder[]) => {
        setOrders(data);
        setLoadingOrders(false);
      })
      .catch((error) => {
        manageStringError(errorManager(error, t, navigate, STORE), setError, t);
        setLoadingOrders(false);
      });
  }

  const redirectToHome = (): void => navigate(PUBLIC_URL.HOME);

  useEffect(() => {
    loadOrders();
  }, [])

  return (
    <Box sx={{minHeight: !store.isMobile ? "calc(100vh - 182px)" : "calc(100vh - 292px)", pb: !store.isMobile ? "40px" : "32px", boxSizing: "border-box"}}>
      {loadingOrders ?
        <Loader size={75} sx={{height: "calc(100vh - 149px)"}} />
        :
        <Box>
          <AccountHeader background={backgroundOrder} title={t("orders.title")} breadcrumb={[
            {label: t("account.my_account"), url: PUBLIC_URL.ACCOUNT},
            {label: t("orders.title")}]} />
          <Box sx={{maxWidth: maxWidth, px: !store.isMobile ? 4 : 2, display: "flex", justifyContent: "space-between", margin: "0 auto", mt: "-20px", position: "relative", zIndex: "1"}}>
            {error && <Alert variant="filled" severity="error">{error}</Alert>}
            <Box sx={{width: "100%", maxWidth: "974px", mr: !store.isMobile ? 4 : ""}}>
              {orders ? orders.map((order: TOrder, index: number) => <OrderCard order={order} key={index} />) : <NoOrder/>}
            </Box>
            {!store.isMobile && <FaqPreview/>}
          </Box>
        </Box>
      }
    </Box>
  )
}
