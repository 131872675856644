import {Box, Typography} from "@mui/material";
import React from "react";
import EmptySearch from "../../assets/empty_search.svg";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {PUBLIC_URL} from "../../common/struct/urlManager";
import {PrimaryBigButton} from "../../components/buttons/mainButton";

export default function ExpiredToken(props: {isMobile: boolean}): JSX.Element {
  const {isMobile} = props;
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleNav = (): void => {
    navigate(PUBLIC_URL.LOGIN);
  }

  return (
    <Box sx={{minHeight: "calc(100vh - 182px)", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
      <img src={EmptySearch} style={{height: isMobile ? "250px" : "378px"}} alt="Invitation expirée"/>
      <Typography variant="h1" sx={{fontWeight: "bold", color: 'neutral.main', mt: 3}}>
        {t('expired.text')}
      </Typography>
      <Typography variant="h1" sx={{fontWeight: "bold", color: 'neutral.main', mt: 3, textAlign: "center", mb: 3}} dangerouslySetInnerHTML={{__html: t('expired.contact')}} />
      <PrimaryBigButton action={(): void => handleNav()} label={t('expired.button')}/>
    </Box>
  )
}
