import React, {useContext, useState} from "react";
import {Alert, Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import {AppContext} from "../../App";
import FormInput from "../../patterns/form/formInput";
import {TViolation} from "../../common/struct/models/TViolation";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import {updateUserInfo} from "../../services/ParticipantService";
import {getOperationParticipantId} from "../../common/struct/globalVar";
import {TOperationParticipant} from "../../common/struct/models/TOperationParticipant";
import {globalStoreReducer} from "../../common/methods/context/globalStoreReducer";
import {StoreActions} from "../../common/struct/store";
import {TUserUpdate} from "../../common/struct/models/TUserUpdate";
import {errorManager} from "../../common/methods/ApiService";
import {useNavigate} from "react-router-dom";
import {loginStorage} from "../../services/AuthenticationService";
import {INPUT_TYPES} from "../../patterns/form/formConstants";

export default function InformationProfile(): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const STORE = useContext<any>(AppContext);
  const [store] = STORE;

  const [firstName, setFirstName] = useState(store.user.participant.firstName);
  const [lastName, setLastName] = useState(store.user.participant.lastName);
  const [email, setEmail] = useState(store.user.participant.email);
  const [phone, setPhone] = useState(store.user.participant.phone);
  const [error, setError] = useState<string|null>(null);
  const [success, setSuccess] = useState<string|null>(null);
  const [violations, setViolations] = useState<TViolation[]>([]);
  const [loading, setLoading] = useState(false);

  const updateProfile = (): void => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    setViolations([]);

    if (firstName.length > 0 && lastName.length > 0 && email.length > 0 && phone.length > 0) {
      const user: TUserUpdate = {
        id: store.user.participant.id,
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
      }
      updateUserInfo(getOperationParticipantId() ?? "", user)
        .then((operationParticipant: TOperationParticipant) => {
          globalStoreReducer(STORE, StoreActions.LOGIN, {user: operationParticipant});
          if (operationParticipant.participant.token) loginStorage(STORE, operationParticipant.participant.token);
          setSuccess(t("success.profile"));
        }).catch((error) => {
          const errorResult = errorManager(error, t, navigate, STORE);
          if (setViolations && Array.isArray(errorResult)) setViolations(errorResult);
          else if (typeof errorResult === 'string') setError(errorResult);
        }).finally(() => {
          setLoading(false);
        })
    } else {
      firstName.length == 0 && setViolations(prevState => [...prevState, {field: "firstname", error: t("error.empty")}]);
      lastName.length == 0 && setViolations(prevState => [...prevState, {field: "lastname", error: t("error.empty")}]);
      email.length == 0 && setViolations(prevState => [...prevState, {field: "email", error: t("error.empty")}]);
      phone.length == 0 && setViolations(prevState => [...prevState, {field: "phone", error: t("error.empty")}]);
      setLoading(false);
    }
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    updateProfile();
  }

  return (
    <>
      {error && <Alert variant="filled" severity="error" sx={{mx: 7, mt: 3, mb: -4}}>{error}</Alert>}
      {success && <Alert variant="filled" severity="success" sx={{mx: 7, mt: 3, mb: -4}}>{success}</Alert>}
      <Box sx={{p: store.isMobile ? 3 : 7}}>
        <form onSubmit={handleSubmit}>
          <FormInput required name="firstName" value={firstName} setValue={setFirstName} violations={violations} disabled={loading} />
          <FormInput required name="lastName" value={lastName} setValue={setLastName} violations={violations} disabled={loading} />
          <FormInput required name="email" value={email} setValue={setEmail} violations={violations} disabled={loading} />
          <FormInput required name="phone" value={phone} setValue={setPhone} violations={violations} disabled={loading} type={INPUT_TYPES.TELEPHONE} />
          <PrimaryBigButton submit={true} loading={loading} sx={{mt: store.isMobile ? "0px" : 2, width: store.isMobile ? "100%" : "inherit"}} label={t("information.profile_update")}/>
        </form>
      </Box>
    </>
  )
}
