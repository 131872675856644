import {Card, Typography} from "@mui/material";
import {InfoOutlined} from "@mui/icons-material";
import React from "react";

export default function InfoAlert(props: {text: string, isMobile: boolean, oneLine?: boolean}): JSX.Element {
  const {text, isMobile, oneLine = false} = props;

  return (
    <Card sx={{display: "flex", flexDirection: oneLine ? "row" : "column", backgroundColor: "info.light", border: "2px solid",
      borderLeft: "6px solid", borderColor: "#A8C2EC", boxShadow: 0, boxSizing: "border-box", px: isMobile ? 3 : 4, py: oneLine ? 2 : isMobile ? 3 : 4, pl: isMobile ? 1 : 3, mb: isMobile ? 2 : 4}}>
      <InfoOutlined sx={{color: "info.main", width: 24, height: 24, mb: oneLine ? "0px" : 1, mr: oneLine ? 1 : "0px"}}/>
      <Typography variant="body2" color="info.dark" sx={{fontWeight: 400}} dangerouslySetInnerHTML={{__html: text}}/>
    </Card>
  )
}
