import {TBasketGift} from "../../common/struct/models/TBasket";
import {useTranslation} from "react-i18next";
import {Alert, Box, Divider, Grid, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import BasketGiftCard from "./basketGiftCard";
import BasketOverview from "./basketOverview";
import {AppContext} from "../../App";
import {validateOrder} from "../../services/OrderService";
import {manageStringError} from "../../common/methods/ApiService";
import Loader from "../../components/loader/loader";
import {globalStoreReducer} from "../../common/methods/context/globalStoreReducer";
import {StoreActions, StoreContext} from "../../common/struct/store";
import {getMinimalUserInfo} from "../../services/ParticipantService";
import {getOperationParticipantId} from "../../common/struct/globalVar";

export const getGiftQuantities = (gifts: TBasketGift[]): number => {
  return gifts.map(gift => gift.quantity).reduce((total, actual) => total + actual, 0);
}

export type Violation = {
  value: {odooProductId: number, quantity: number},
  violations: {message: string, code: string}
}

export default function NonEmptyBasket(): JSX.Element {
  const {t} = useTranslation();

  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<null|string>(null);
  const [violations, setViolations] = useState<Violation[]|null>(null);

  useEffect(() => {
    if (success) {
      setTimeout(() => setSuccess(false), 5000);
    }
  }, [success])

  useEffect(() => {
    const operation = store.operation;
    if (operation == null) {
      return;
    }

    getMinimalUserInfo(getOperationParticipantId() ?? "")
      .then((user) => {
        globalStoreReducer(STORE, StoreActions.LOGIN, {user: user??undefined});
        return Promise.resolve();
      }).then(() => validateOrder(operation.id, store.basket))
      .then(result => {
        setViolations(result.violations??[]);
        globalStoreReducer(STORE, StoreActions.UPDATE_BASKET_TOTAL, {basketTotal: result.totalSellingPointPrice, basketMonetaryComplement: result.monetaryComplement});
      })
      .catch(error => manageStringError(error, setError, t))
      .finally(() => setLoading(false))
  }, [])

  return (
    <>
      <Grid item xs={10} sm={6} sx={{display: "flex", flexDirection: "column"}}>
        {success && <Alert variant="filled" severity="success" sx={{mb: 4}}>{t("basket.your_basket.delete_success")}</Alert>}
        <Box sx={{display: "flex", alignItems: "baseline", mb: 4}}>
          <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", mr: 2}}>
            {t("basket.your_basket.title")}
          </Typography>
          <Divider orientation="vertical" variant="middle"
            sx={{backgroundColor: "neutral.light", borderColor: "neutral.light", width: "1px", height: "12px", mr: 2}}/>
          <Typography variant="body1" color="neutral.dark">
            {t("basket.your_basket.items_count", {count: getGiftQuantities(store.basket)})}
          </Typography>
        </Box>
        {store.basket.map(gift => <BasketGiftCard key={gift.id} gift={gift} setSuccess={setSuccess} violations={violations} isMobile={store.isMobile}/>)}
      </Grid>
      <Grid item xs={10} sm={3} sx={{ml: store.isMobile ? "0px" : 6}}>
        {loading ? <Loader sx={{height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}/> : <BasketOverview step={1} violations={violations} error={error}/>}
      </Grid>
    </>
  )
}
