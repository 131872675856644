import {Box, Typography} from "@mui/material";
import noOrders from "../../assets/no_orders.svg";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {PUBLIC_URL} from "../../common/struct/urlManager";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {AppContext} from "../../App";

export default function NoOrder(): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [store] = useContext<any>(AppContext);

  const redirectToHome = (): void => navigate(PUBLIC_URL.HOME);
  const isShopOpen = store.operation && store.operation.shop.blockedDate == null && ((moment(store.operation.startDate, 'YYYY-MM-DD HH:mm:ss').toDate() <= new Date() && !store.operation.endDate) || (moment(store.operation.startDate, 'YYYY-MM-DD HH:mm:ss').toDate() <= new Date() && moment(store.operation.endDate, 'YYYY-MM-DD HH:mm:ss').toDate() >= new Date()));

  return (
    <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "ornament.light", boxShadow: 1, borderRadius: "4px", borderWidth: 1, borderStyle: "solid", borderColor: "ornament.dark", p: 4, mb: 4}}>
      <Box sx={{width: "220px", height: "220px", mb: 3}}><img src={noOrders} alt="no-order"/></Box>
      <Typography variant="h2" sx={{color: "neutral.main", fontWeight: "medium", textAlign: "center"}}>{t("orders.no_orders_title")}</Typography>
      {isShopOpen && <>
        <Typography variant="body1" sx={{color: "neutral.main", fontWeight: 400, mt: 0, mb: 3}}>{t("orders.no_orders_sub_title")}</Typography>
        <PrimaryBigButton label={t("orders.no_orders_button")} action={redirectToHome}/>
      </>}
    </Box>
  )
}
