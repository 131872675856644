import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {IconButton, InputAdornment, TextField} from "@mui/material";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {Visibility, VisibilityOff} from "@mui/icons-material";

export type ElementInputProps = {
  name: string,
  label: string,
  type: string,
  value?: any | undefined,
  updateValue: any,
  violations?: any[],
  required?: boolean,
  disabled?: boolean,
  password?: boolean,
  sx?: SxProps<Theme>
}

const ElementInput = (props: ElementInputProps): JSX.Element => {
  const {t} = useTranslation();
  const {name, label, type, value, updateValue, violations, required, disabled, password, sx} = props;
  const [errorMessage, setErrorMessage] = useState(null);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = (): void => setShowPassword(!showPassword);
  const handleMouseDownPassword = (): void => setShowPassword(!showPassword);

  useEffect(() => {
    setErrorMessage(violations?.find((violation: any) => violation.field === name)?.error);
  }, [violations]);

  return <TextField
    sx={{"&input": {p: 3}, "&label": {color: "neutral.main"}, ...sx}}
    color="info"
    type={showPassword ? "text" : type}
    fullWidth
    error={errorMessage !== undefined}
    helperText={errorMessage}
    id={name}
    placeholder={t(label)}
    defaultValue={value}
    onChange={updateValue}
    required={required ?? false}
    disabled={disabled ?? false}
    InputProps={{
      endAdornment: (
        password ?
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment> : ''
      )
    }}
  />
}

export default ElementInput;
