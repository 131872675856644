import {TGift} from "../../common/struct/models/TGift";
import {Box, Card, CardContent, Typography} from "@mui/material";
import React, {useContext} from "react";
import CardMediaLoader from "../../components/image/cardMediaLoader";
import {useTranslation} from "react-i18next";
import {AppContext} from "../../App";


export default function GiftCard(props: {gift: TGift, isMobile: boolean}): JSX.Element {
  const STORE = useContext<any>(AppContext);
  const [store] = STORE;

  const {t} = useTranslation();
  const {gift, isMobile} = props;
  const image = (gift.image_urls.length == 0) ? null : gift.image_urls[0].small;

  return <Card sx={{display: "flex", flexDirection: "column", backgroundColor: "ornament.light", height: isMobile ? "316px" : "374px",
    boxShadow: 0, borderWidth: 1, borderStyle: "solid", borderColor: "ornament.dark", cursor: "pointer", transition: "all 0.3s", "&:hover": {mt: "-4px", mb: 0, boxShadow: 5}}}>
    <CardContent sx={{p: 1, height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", "&:last-child": {pb: 1}}}>
      <CardMediaLoader image={image} isMobile={isMobile} sx={{maxHeight: "264px", width: "100%", maxWidth: "none", flexGrow: 1, ".MuiAvatar-img": {objectFit: "contain"}}}/>
      <Box sx={{p: 2, mt: 1, boxSizing: "border-box", minHeight: "80px"}}>
        <Typography variant="body2" color="neutral.dark" sx={{fontWeight: "medium", mb: 0}}>
          {gift.name}
        </Typography>
        {!store.operation.shop.hidePricing && <Box sx={{display: "flex", alignItems: "baseline", height: "30px"}}>
          <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", mr: 0,
            borderBottomStyle: "solid", borderBottomWidth: "3px", borderBottomColor: "primary.main"}}>
            {gift.sellingPointPrice}
          </Typography>
          <Typography variant="caption" color="neutral.main" sx={{fontWeight: "medium"}}>
            {t("shop.gifts.points", {count: gift.sellingPointPrice})}
          </Typography>
        </Box>}
      </Box>
    </CardContent>
  </Card>
}
