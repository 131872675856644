export enum ShippingType {
  SOCIETY = "society",
  HOME = "home"
}

export enum TAddressType {
  DELIVERY = "delivery",
  INVOICE = "invoice"
}

export interface TAddress {
  id?: string,
  society?: string,
  type: string,
  firstName: string,
  lastName: string,
  email: string,
  country: string,
  zip: string,
  street: string,
  street2?: string,
  street3?: string,
  city: string,
  phone: string,
  vat?: string
}
