import React, {useContext, useEffect, useState} from "react";
import {Box, Card, Divider, Typography, useMediaQuery} from "@mui/material";
import {Navigate, useLocation, useNavigate, useParams} from "react-router-dom";
import {getShopGift} from "../../services/ShopService";
import {PUBLIC_URL} from "../../common/struct/urlManager";
import {useTranslation} from "react-i18next";
import Loader from "../../components/loader/loader";
import {SecondarySmallButton} from "../../components/buttons/mainButton";
import {IconPosition} from "../../components/buttons/buttonProps";
import {TBrand, TGift, TGiftImage} from "../../common/struct/models/TGift";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BoxCircleIcon from "../../assets/box_circle.svg";
import AddGiftToBasketDialog from "./addGiftToBasketDialog";
import {AppContext} from "../../App";
import {errorManager, manageStringError} from "../../common/methods/ApiService";
import {StoreContext} from "../../common/struct/store";
import GiftPictures from "./giftPictures";
import GiftDescription from "./giftDescription";
import GiftSelector from "./giftSelector";

export default function Gift(): JSX.Element {
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;

  const {t} = useTranslation();
  const navigate = useNavigate();
  const {state} = useLocation() as any;
  const {giftId} = useParams<any>();

  const [gift, setGift] = useState<TGift|null>();
  const [images, setImages] = useState<TGiftImage[]>([]);
  const [brand, setBrand] = useState<TBrand|null>(null);
  const [quantity, setQuantity] = useState(1);
  const [error, setError] = useState<string | null>(null);
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);

  const matchesM = useMediaQuery('(min-width:1000px)');

  useEffect(() => {
    document.title = t("tab_title.gift");
  }, []);

  useEffect(() => {
    if (giftId !== undefined && store.operation?.shop != null) {
      getShopGift(store.operation.shop.id, parseInt(giftId), quantity, store.isPreview ? true : false).then((res) => {
        if (res.id) {
          setGift(res);
          setImages(res.image_urls);
          setBrand(res.brand??null);
        }
      }).catch((error) => {
        manageStringError(errorManager(error, t, navigate, STORE), setError, t);
      });
    }
  }, [giftId])

  useEffect(() => {
    if (gift && store.operation?.shop != null) {
      getShopGift(store.operation.shop.id, gift.id, quantity, store.isPreview ? true : false).then((res) => {
        if (res.id) {
          setGift(res);
        }
      }).catch((error) => {
        manageStringError(errorManager(error, t, navigate, STORE), setError, t);
      });
    }
  }, [quantity, store.basket])

  const handleShop = (params?: any): void => {
    if (params) {
      navigate(PUBLIC_URL.HOME, {state: {page: params.page, filters: params.filters, sorting: params.sorting, simplesearch: params.simplesearch, scroll: params.scroll}})
    } else {
      navigate(PUBLIC_URL.HOME)
    }
  };

  const addToBasket = (): void => {
    if (giftId) {
      setOpenAddDialog(true);
    }
  }

  return store.operation == null ? <></> :
    <Box>
      {!gift && <Box sx={{width: "100%", height: !store.isMobile ? "calc(100vh - 76.5px)" : "calc(100vh - 40px)", backgroundColor: "#FFFFFF"}}>
        <Loader size={75} sx={{height: "100%", width: "100%"}}/>
      </Box>}
      {gift && !error &&
        <>
          <AddGiftToBasketDialog gift={gift} addedQuantity={quantity} isMobile={store.isMobile} params={state} open={openAddDialog} setOpen={setOpenAddDialog}/>
          {!store.isMobile ?
            <Box sx={{width: "100%", height: "100%", minHeight: "100%", backgroundColor: "#FFFFFF", display: "flex", justifyContent: "center"}}>
              <Box sx={{width: "60.86%", maxWidth: "852px", height: "fit-content", minHeight: "100%", padding: `${matchesM ? "32px 48px 75px 48px" : "32px 24px 75px 24px"}`, boxSizing: "border-box"}}>
                <SecondarySmallButton label={t("gift_modal.return_button")} icon={ArrowBackIcon} position={IconPosition.LEFT} sx={{borderRadius: '40px', mb: 4, padding: "4px 8px 4px 8px", boxShadow: 1}} action={(): void => handleShop(state && (state.page || state.filters || state.sorting || state.simplesearch || state.scroll) && state)}/>
                <Typography variant="h1" component="h1" sx={{mb: 4, color: "neutral.dark", fontWeight: 800, fontSize: "40px", lineHeight: "48px"}}>{gift.name}</Typography>
                <GiftPictures images={images} brand={brand} isMobile={store.isMobile}/>
                <GiftDescription gift={gift} isMobile={store.isMobile}/>
              </Box>
              <Box sx={{width: "39.14%", maxWidth: "548px", height: "fit-content", minHeight: "100%", padding: `${matchesM ? "82px 48px 0 48px" : "82px 24px 0 24px"}`, boxSizing: "border-box", position: "sticky", top: "-60px"}}>
                {store.operation && !store.operation.shop.hidePricing && <Box sx={{height: "52px", width: "100%", mb: 4, display: "flex", alignItems: "flex-end"}}>
                  <Box sx={{height: "52px"}}>
                    <Typography variant="h1" component="div" sx={{fontWeight: "800", fontSize: "40px", lineHeight: "48px", height: "48px", color: "neutral.dark"}}>{gift.sellingPointPrice}</Typography>
                    <Divider sx={{height: "4px", width: "100%", backgroundColor: "primary.main", borderRadius: "10px"}} />
                  </Box>
                  <Typography variant="h2" component="div" sx={{fontWeight: "bold", height: "48px", color: "neutral.main", display: "flex", alignItems: "flex-end", padding: "0 0 8px 0", boxSizing: "border-box", ml: 1}}>{t("shop.gifts.points", {count: gift.sellingPointPrice})}</Typography>
                </Box>}
                <Box>
                  <GiftSelector gift={gift} operation={store.operation} user={store.user} basket={store.basket}
                    quantity={quantity} setQuantity={setQuantity} addToBasket={addToBasket} isMobile={store.isMobile}/>
                </Box>

                {gift.sale_delay && gift.x_afficher_mention_expedier_sous ? <Card sx={{display: "flex", padding: "20px 20px 20px 20px", borderStyle: "solid", borderColor: "ornament.dark", borderWidth: "1px", mt: "20px"}}>
                  <img src={BoxCircleIcon} />
                  <Typography variant="body2" component="div" sx={{fontWeight: "400", color: "neutral.main", ml: "6px", display: "flex", alignItems: "center"}}>{!gift.x_email_delivery ? t("gift_modal.shipment_infos_deliverytime_physical", {deliverytime: gift.sale_delay}) : t("gift_modal.shipment_infos")}</Typography>
                </Card> : <></>}
              </Box>
            </Box>
            :
            <Box sx={{width: "100%", height: "100%", minHeight: "100%", overflowY: "auto", backgroundColor: "#FFFFFF", display: "flex", justifyContent: "center"}}>
              <Box sx={{width: "100%", height: "fit-content", minHeight: "100%", padding: "12px 12px 36px 12px", boxSizing: "border-box"}}>
                <SecondarySmallButton icon={ArrowBackIcon} sx={{borderRadius: '40px', mb: 2, padding: "0px", minWidth: "34px", height: "34px"}} action={(): void => handleShop(state && (state.page || state.filters || state.sorting || state.scroll) && state)}/>
                <Typography variant="h1" component="h1" sx={{mb: "46px", color: "neutral.dark", fontWeight: "bold"}}>{gift.name}</Typography>
                <GiftPictures images={images} brand={brand} isMobile={store.isMobile}/>
                {store.operation && !store.operation.shop.hidePricing && <Box sx={{height: "43px", width: "100%", mb: 3, display: "flex", alignItems: "flex-end"}}>
                  <Box sx={{height: "43px"}}>
                    <Typography variant="h1" component="div" sx={{fontWeight: 800, height: "40px", color: "neutral.dark"}}>{gift.sellingPointPrice}</Typography>
                    <Divider sx={{height: "3px", width: "100%", backgroundColor: "primary.main", borderRadius: "10px"}} />
                  </Box>
                  <Typography variant="h2" component="div" sx={{fontWeight: "bold", height: "32px", color: "neutral.main", display: "flex", alignItems: "flex-end", padding: "0 0 7px 0", ml: 1}}>{t("shop.gifts.points", {count: gift.sellingPointPrice})}</Typography>
                </Box>}
                <Box sx={{mb: 6}}>
                  <GiftSelector gift={gift} operation={store.operation} user={store.user} basket={store.basket}
                    quantity={quantity} setQuantity={setQuantity} addToBasket={addToBasket} isMobile={store.isMobile}/>
                  {gift.x_email_delivery && <Card sx={{display: "flex", padding: "20px 20px 20px 20px", borderStyle: "solid", borderColor: "ornament.dark", borderWidth: "1px", mt: "20px"}}>
                    <img src={BoxCircleIcon} />
                    <Typography variant="body2" component="div" sx={{fontWeight: "400", color: "neutral.main", ml: "6px"}}>{gift.x_afficher_mention_expedier_sous && gift.sale_delay ? t("gift_modal.shipment_infos_deliverytime", {deliverytime: gift.sale_delay}) : t("gift_modal.shipment_infos")}</Typography>
                  </Card>}
                </Box>
                <GiftDescription gift={gift} isMobile={store.isMobile}/>
              </Box>
            </Box>
          }
        </>
      }
      {error && <Navigate to={PUBLIC_URL.HOME}/>}
    </Box>
}
