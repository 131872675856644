import {Box, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {GiftDeliveredBanner, PayDifferenceBanner, SelectGiftBanner} from "../../components/banners/withdrawGiftBanners";

export default function GiftTopUpProcess(props: {isMobile: boolean}): JSX.Element {
  const {t} = useTranslation();
  const {isMobile} = props;

  return (
    <>
      <Box sx={{display: "flex", justifyContent: "space-between", py: isMobile ? 3 : 4, mx: isMobile ? 3 : 4, borderBottom: "1px solid", borderColor: "ornament.dark"}}>
        <Box sx={{mr: 4, width: !isMobile ? "auto" : "50%"}}>
          <SelectGiftBanner sx={{mb: 1, maxHeight: !isMobile ? "unset" : "48px"}}/>
          <Typography variant="body2" color="info.dark" sx={{maxWidth: !isMobile ? "unset" : "200px"}}>
            {t("gift_modal.process.validate")}
          </Typography>
        </Box>
        <Box sx={{mr: 4, width: !isMobile ? "auto" : "50%"}}>
          <PayDifferenceBanner sx={{mb: 1, maxHeight: !isMobile ? "unset" : "48px"}}/>
          <Typography variant="body2" color="info.dark" sx={{maxWidth: !isMobile ? "unset" : "200px"}}>
            {t("gift_modal.process.pay")}
          </Typography>
        </Box>
        <Box sx={{width: !isMobile ? "auto" : "50%"}}>
          <GiftDeliveredBanner sx={{mb: 1, maxHeight: !isMobile ? "unset" : "48px"}}/>
          <Typography variant="body2" color="info.dark" sx={{maxWidth: !isMobile ? "unset" : "200px"}}>
            {t("gift_modal.process.shipping")}
          </Typography>
        </Box>
      </Box>
    </>
  )
}
