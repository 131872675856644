import {ShippingType, TAddress, TAddressType} from "../../common/struct/models/TAddress";
import {Box, Card, FormControlLabel, Radio, Typography} from "@mui/material";
import ShippingTypeLabel from "./shippingTypeLabel";
import React, {useEffect, useState} from "react";
import {SecondaryBigButton} from "../../components/buttons/mainButton";
import {useTranslation} from "react-i18next";
import AddressForm from "./addressForm";
import {TViolation} from "../../common/struct/models/TViolation";

interface BasketShippingAddressProps {
  address: TAddress;
  isMobile: boolean;
  favorite: boolean;
  selected: TAddress|undefined;
  setSelected: (selected: TAddress|undefined) => void;
  violations: TViolation[];
  loading: boolean;
}

export default function BasketShippingAddressCard(props: BasketShippingAddressProps): JSX.Element {
  const {address, isMobile, favorite, selected, setSelected, loading, violations} = props;
  const {t} = useTranslation();
  const [update, setUpdate] = useState(false);

  const updateAddress = (): void => {
    setSelected(selected);
    setUpdate(true);
  }

  useEffect(() => {
    if (selected?.id !== address.id) setUpdate(false);
  }, [selected])

  return (
    <Card sx={{mb: 4, border: "1px solid", borderColor: "ornament.dark", display: "flex", flexDirection: "column"}}>
      <FormControlLabel sx={{py: 4, px: isMobile ? 4 : 6, mr: "0px", borderBottom: "1px solid", borderColor: "ornament.dark"}} checked={selected?.id == address.id}
        onChange={(): void => setSelected(address)} control={<Radio sx={{fontSize: 24}}/>} disabled={loading}
        label={<Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold", ml: 1}}>
          {t("address.shipping_" + (favorite ? "favorite" : "last"))}
        </Typography>}/>
      {!update && <>
        <ShippingTypeLabel shippingType={address.society ? ShippingType.SOCIETY : ShippingType.HOME} sx={{p: isMobile ? 4 : 6, pb: "0px"}}/>
        <Box sx={{display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: isMobile ? "unset" : "center", justifyContent: "space-between", p: isMobile ? 4 : 6, pt: isMobile ? 2 : 4}}>
          <Box sx={{display: "flex", flexDirection: "column"}}>
            {address.society && <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "medium", mb: 1}}>
              {address.society}
            </Typography>}
            <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold", mb: 0}}>
              {address.firstName} {address.lastName}
            </Typography>
            <Typography variant="body1" color="neutral.dark">{address.street}</Typography>
            <Typography variant="body1" color="neutral.dark">{address.street2}</Typography>
            <Typography variant="body1" color="neutral.dark">{address.street3}</Typography>
            <Typography variant="body1" color="neutral.dark">
              {address.zip} {address.city}
            </Typography>
            <Typography variant="body1" color="neutral.dark">{address.phone}</Typography>
          </Box>
          <SecondaryBigButton label={t("address.update")} sx={{fontWeight: "bold", mt: isMobile ? 4 : "0px"}} action={updateAddress} disabled={loading}/>
        </Box>
      </>}
      {update && <Box sx={{p: isMobile ? 4 : 6}}>
        <AddressForm loading={loading} type={TAddressType.DELIVERY} address={address} setAddress={setSelected} violations={violations}/>
      </Box>}
    </Card>
  )
}
