import './languageSelector.scss';
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import en from "../../locales/en/englishMessages.json";
import fr from "../../locales/fr/frenchMessages.json";
import React from "react";
import {format as formatDate, isDate} from "date-fns";
import {enGB as english, fr as french} from "date-fns/locale";
import {isProd} from "../../common/struct/globalVar";

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    resources: {
      en: {
        translation: en
      },
      fr: {
        translation: fr
      }
    },
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if (isDate(value) && lng && format) {
          const locale = lng == "en" ? english : french;
          return formatDate(value, format, {locale});
        } else {
          return value;
        }
      }
    },
    lng: "fr",
    fallbackLng: "en"
  })

export default function LanguageSelector(): JSX.Element {
  const changeLanguage = (lng: string): void => {
    i18n.changeLanguage(lng)
  }

  return (
    <select style={{display: isProd() ? "none" : "flex"}} className="language-selector" onChange={(e): void => changeLanguage(e.target.value)} defaultValue="fr">
      <option value="en">EN</option>
      <option value="fr">FR</option>
    </select>
  )
}
