import {Box, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

export default function BasketTotal(props: {total: number, complement: number, sx?: SxProps<Theme>}): JSX.Element {
  const {t} = useTranslation();
  const {total, complement, sx} = props;

  return <>
    <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between",
      borderTop: "1px solid", borderColor: "ornament.dark", ...sx}}>
      <Typography variant={complement > 0 ? "body1" : "h2"} color="neutral.dark" sx={{fontWeight: complement > 0 ? "regular" : "bold"}}>
        {t("basket.overview.total" + (complement > 0 ? "_points" : ""))}
      </Typography>
      <Box sx={{display: "flex", alignItems: "start"}}>
        <Typography variant={complement > 0 ? "body1" : "h2"} color="neutral.dark" sx={{fontWeight: complement > 0 ? "regular" : "bold", mr: 0}}>
          {total}
        </Typography>
        <Typography variant={complement > 0 ? "caption" : "body2"} color="neutral.dark" sx={{fontWeight: complement > 0 ? "regular" : "bold"}}>
          {t("basket.overview.points")}
        </Typography>
      </Box>
    </Box>
    {complement > 0 && <>
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between",
        borderTop: "1px solid", borderColor: "ornament.dark", ...sx}}>
        <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "regular"}}>
          {t("basket.overview.complement")}
        </Typography>
        <Box sx={{display: "flex", alignItems: "start"}}>
          <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "regular", mr: 0}}>
            {complement}
          </Typography>
          <Typography variant="caption" color="neutral.dark" sx={{fontWeight: "regular"}}>
            €
          </Typography>
        </Box>
      </Box>
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between",
        borderTop: "1px solid", borderColor: "ornament.dark", ...sx}}>
        <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", textTransform: "uppercase"}}>
          {t("basket.overview.total")}
        </Typography>
        <Box sx={{display: "flex", alignItems: "start"}}>
          <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", mr: 0}}>
            {complement}
          </Typography>
          <Typography variant="body2" color="neutral.dark" sx={{fontWeight: "bold"}}>
            €
          </Typography>
        </Box>
      </Box>
    </>}
  </>
}
