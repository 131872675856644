import React from 'react';
import {Box, Button, Card, Typography} from "@mui/material";
import faqIcon from "../../assets/faq_icon.svg";
import {useTranslation} from "react-i18next";
import FaqQuestion from "./faqQuestion";
import {Link} from "react-router-dom";
import {PUBLIC_URL} from "../../common/struct/urlManager";

export default function FaqPreview(): JSX.Element {
  const {t} = useTranslation();
  return (
    <Card sx={{border: "1px solid", borderColor: "ornament.dark", height: "fit-content"}}>
      <Box sx={{px: 4, py: 2, display: "flex", alignItems: "center", borderTop: "4px solid", borderColor: "info.dark"}}>
        <img src={faqIcon} alt="faq-icon"/>
        <Typography variant="body1" sx={{ml: 2, fontWeight: "bold"}}>{t("faq.title")}</Typography>
      </Box>
      <Box sx={{px: 4}}>
        <FaqQuestion label={t("faq.sentence1")} section={"delivery"} />
        <FaqQuestion label={t("faq.sentence2")} section={"order"} />
        <FaqQuestion label={t("faq.sentence3")} section={"delivery"} />
        <FaqQuestion label={t("faq.sentence4")} section={"stock"}/>
      </Box>
      <Box sx={{p: 4}}>
        <Button component={Link} variant="outlined" to={PUBLIC_URL.FAQ}
          sx={{p: 0, fontWeight: "bold", width: "100%", borderColor: "info.main", color: "info.dark", "&:hover": {borderColor: "info.dark", backgroundColor: "info.light"}}}>
          {t("faq.button")}
        </Button>
      </Box>
    </Card>
  )
}
