import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {PUBLIC_URL} from '../../common/struct/urlManager';
import {AppContext} from "../../App";
import {Alert, Avatar, Box, Button, Card, CardMedia, Chip, MenuItem, Typography, useMediaQuery} from "@mui/material";
import {useTranslation} from "react-i18next";
import {MenuChoice} from "../../patterns/header/userMenu";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import {ReactComponent as ShopBox} from "../../assets/shop_box.svg";
import PersonIcon from "@mui/icons-material/Person";
import {MAX_DESKTOP_SIZE, MIN_MOBILE_SIZE} from "../../tokens/libertyTheme";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import {globalStoreReducer} from "../../common/methods/context/globalStoreReducer";
import {StoreActions} from "../../common/struct/store";
import {TOrder} from "../../common/struct/models/TOrder";
import {getOperationParticipantLastOrder} from "../../services/OrderService";
import {getOperationParticipantId} from "../../common/struct/globalVar";
import {manageStringError} from "../../common/methods/ApiService";
import Loader from "../../components/loader/loader";
import FaqPreview from "../../patterns/faq/faqPreview";
import contactIcon from "../../assets/contact_icon.svg";
import LastOrder from './lastOrder';
import NoOrder from "../orders/noOrder";
import JoozyBoy from "../../assets/JOOZY_BOY.svg";

export default function Account(): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [lastOrder, setLastOrder] = useState<TOrder|null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string|null>(null);

  const STORE = useContext<any>(AppContext);
  const [store] = STORE;

  const sizeM = useMediaQuery('(min-width:1000px)');
  const sizeS = useMediaQuery('(min-width:700px)');

  useEffect(() => {
    document.title = t("tab_title.account");
  }, []);

  useEffect(() => {
    const operationParticipantId = getOperationParticipantId();
    if (!operationParticipantId) {
      navigate(PUBLIC_URL.HOME);
      return;
    }

    getOperationParticipantLastOrder(operationParticipantId)
      .then(order => setLastOrder(order))
      .catch((error) => manageStringError(error, setError, t))
      .finally(() => setLoading(false))
  }, [])


  const handleLogout = (): void => {
    navigate(PUBLIC_URL.HOME);
    globalStoreReducer(STORE, StoreActions.LOGOUT);
  }

  const accountBanner = store.operation.bannerUrl && <Card sx={{boxShadow: 1, border: "1px solid", borderColor: "ornament.dark", mb: 4}}>
    <CardMedia sx={{width: "100%", borderTop: "4px solid", borderTopColor: "primary.main"}} component="img" image={store.operation.bannerUrl} alt="operation-banner"/>
    <Box sx={{display: "flex", justifyContent: "space-between"}}>
      {store.operation.logoUrl && <Card sx={{boxShadow: 1, borderColor: "ornament.dark", width: store.isMobile ? 74 : 120, height: store.isMobile ? 74 : 120, display: "flex", alignItems: "center",
        ml: store.isMobile ? 2 : 4, mt: store.isMobile ? "-37px" : "-60px",
        "& img": {width: "100%", backgroundColor: "ornament.light"}}}>
        <CardMedia component="img" image={store.operation.logoUrl} sx={{mr: "auto", mx: "auto", p: "0px"}} alt="operation-logo"/>
      </Card>}
      <Typography variant={store.isMobile ? "body1" : "h2"} color="neutral.dark" sx={{fontWeight: "bold", mt: store.isMobile ? 1 : 5, mb: store.isMobile ? 3 : 5, mx: store.isMobile ? 2 : 4}}>
        {t("account.my_account")}
      </Typography>
    </Box>
  </Card>

  const paddingH = store.isMobile ? 2 : 4;
  const paddingV = store.isMobile ? 2 : 4;
  const maxWidth = (store.isMobile) ? "100vw" : MAX_DESKTOP_SIZE;
  return (
    <Box sx={{py: paddingV, px: paddingH, minWidth: MIN_MOBILE_SIZE, maxWidth: maxWidth, mx: "auto", zIndex: 0, display: "flex", flexWrap: "wrap"}}>
      {store.isMobile && accountBanner}
      {/** Le important ici ne doit pas être supprimer pour éviter un padding left forcé par MUI */}
      <Box sx={{pl: "0px !important", width: sizeM ? "30%" : sizeS ? "50%" : "100%"}}>
        <Card sx={{boxShadow: 1, border: "1px solid", borderColor: "ornament.dark", mb: 4}}>
          <Box sx={{p: 4, mb: 2, display: "flex", alignItems: "center", justifyContent: "space-between",
            borderTop: "4px solid", borderTopColor: "primary.main", borderBottom: "1px solid", borderBottomColor: "ornament.dark"}}>
            <Box sx={{display: "flex", alignItems: "center"}}>
              {store.user?.participant.firstName ?
                <Avatar sx={{backgroundColor: "ornament.dark", mr: 1, width: 42, height: 42}}>
                  <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold"}}>
                    {store.user?.participant.firstName?.substring(0, 1)}
                  </Typography>
                </Avatar>
                :
                <Avatar sx={{backgroundColor: "ornament.dark", mr: 1, width: 42, height: 42}} src={JoozyBoy}/>
              }
              <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "medium"}}>
                {t("header.hello", {name: store.user?.participant.firstName})}
              </Typography>
            </Box>
            {!store.operation.shop.hidePricing &&
            <Chip color="primary" sx={{height: "unset"}} label={<Box sx={{display: "flex", alignItems: "start"}}>
              <Typography variant="body1" color="primary.contrastText" sx={{fontWeight: "bold", mr: 0}}>
                {store.user.rewardPoints}
              </Typography>
              <Typography variant="caption" color="primary.contrastText" sx={{fontWeight: "bold"}}>
                {t("basket.overview.points")}
              </Typography>
            </Box>}/>}
          </Box>
          {!store.operation.shop.hidePricing && <MenuChoice url={PUBLIC_URL.POINTS} label="transactions" icon={<AccountBalanceWalletIcon sx={{color: "neutral.main"}}/>}/>}
          <MenuChoice url={PUBLIC_URL.ORDERS} label="orders" icon={<ShopBox fill="currentColor"/>}/>
          <MenuChoice url={PUBLIC_URL.INFORMATION} label="information" icon={<PersonIcon sx={{color: "neutral.main"}}/>}/>
          <MenuItem onClick={handleLogout} sx={{p: 4}}>
            <ExitToAppIcon sx={{width: 18, height: 18, color: "neutral.light"}}/>
            <Typography variant="body2" color="neutral.main" sx={{fontWeight: "medium", ml: 1}}>{t("header.logout")}</Typography>
          </MenuItem>
        </Card>
      </Box>
      <Box sx={{display: "flex", flexDirection: "column", width: sizeM ? "40%" : sizeS ? "50%" : "100%", px: sizeM ? 3 : "0px", paddingLeft: sizeM ? 3 : sizeS ? 3 : "0px", boxSizing: "border-box"}}>
        {!store.isMobile && accountBanner}
        <Card sx={{boxShadow: 1, border: "1px solid", borderColor: "ornament.dark", px: store.isMobile ? 3 : 4, mb: 4}}>
          <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold", py: 4}}>
            {t("account.last_order")}
          </Typography>
          {error && <Alert severity="error" sx={{mb: 2}}>{error}</Alert>}
          {loading ? <Loader sx={{height: "200px", display: "flex", justifyContent: "center", alignItems: "center"}} /> : lastOrder ? <LastOrder order={lastOrder}/> : <NoOrder/>}
        </Card>
      </Box>
      <Box sx={{width: sizeM ? "30%" : sizeS ? "50%" : "100%", paddingTop: sizeM ? "0px" : sizeS ? 3 : "0px"}}>
        <FaqPreview/>
        <Card sx={{border: "1px solid", borderColor: "ornament.dark", height: "fit-content", mt: 5}}>
          <Box sx={{px: 4, py: 2, display: "flex", alignItems: "center", borderTop: "4px solid", borderColor: "neutral.main"}}>
            <img src={contactIcon} alt="contact-icon"/>
            <Typography variant="body1" sx={{ml: 2, fontWeight: "bold"}}>{t("contact.title")}</Typography>
          </Box>
          <Typography variant="body2" color="neutral.main" sx={{mx: 4}}>{t("contact.need_help")}</Typography>
          <Box sx={{p: 4}}>
            <Button variant="outlined" sx={{p: 0, fontWeight: "bold", width: "100%", borderColor: "neutral.light", color: "neutral.main", "&:hover": {borderColor: "neutral.main", backgroundColor: "ornament.main"}}}>
              <Link to={PUBLIC_URL.CONTACT}>{t("contact.send_message")}</Link>
            </Button>
          </Box>
        </Card>
      </Box>
    </Box>
  )
}
