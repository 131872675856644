import React, {Dispatch, SetStateAction} from "react";
import {Typography} from "@mui/material";
import CheckboxMenu from "../../components/menu/checkboxMenu";
import {useTranslation} from "react-i18next";

type FilterCriteriaProps = {
  ecoFriendlySelected: boolean,
  setEcoFriendlySelected: (selected: boolean) => void,
  madeInFranceSelected: boolean,
  setMadeInFranceSelected: (selected: boolean) => void,
  setReload?: Dispatch<SetStateAction<boolean>>,
  reload?: boolean
}

export default function FilterCriteria(props: FilterCriteriaProps): JSX.Element {
  const {t} = useTranslation();
  const {ecoFriendlySelected, setEcoFriendlySelected, madeInFranceSelected, setMadeInFranceSelected, setReload, reload = false} = props;

  const handleEcoFriendlySelect = (): void => {
    setEcoFriendlySelected(!ecoFriendlySelected);
    if (setReload) setReload(true);
  }

  const handleMadeInFranceSelect = (): void => {
    setMadeInFranceSelected(!madeInFranceSelected);
    if (setReload) setReload(true);
  }

  const ecoFriendlyBackground = ecoFriendlySelected ? "ornament.main" : "transparent";
  const madeInFranceBackground = madeInFranceSelected ? "ornament.main" : "transparent";
  return (
    <>
      <Typography variant="caption" color="neutral.main" sx={{pt: 2, pb: 2, px: 3, pr: 3, fontWeight: "regular", textTransform: "uppercase"}}>
        {t("shop.filters.criteria.filter")}
      </Typography>
      <CheckboxMenu disabled={reload} id="eco-friendly" name={t("shop.filters.criteria.eco_friendly")} isSelected={ecoFriendlySelected} handleSelect={handleEcoFriendlySelect}
        sx={{pt: 2, pb: 2, px: 3, pr: 3, backgroundColor: ecoFriendlyBackground, borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "ornament.dark"}}/>
      <CheckboxMenu disabled={reload} id="made-in-france" name={t("shop.filters.criteria.made_in_france")} isSelected={madeInFranceSelected} handleSelect={handleMadeInFranceSelect}
        sx={{pt: 2, pb: 2, px: 3, pr: 3, backgroundColor: madeInFranceBackground, borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "ornament.dark"}}/>
    </>
  )
}
