import {ESorting} from "../../common/struct/models/TFilter";
import React, {Dispatch, SetStateAction, useState} from "react";
import {Box, Button, Dialog, DialogContent, IconButton} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import CachedIcon from "@mui/icons-material/Cached";
import Sorting from "./sorting";
import {MobileTransition} from "./shopProps";
import {useTranslation} from "react-i18next";

type MobileSortingMenuProps = {
  open: boolean,
  setOpen: (open: boolean) => void,
  sorting: ESorting,
  setSorting: (sorting: ESorting) => void,
  setReload: Dispatch<SetStateAction<boolean>>
}

export default function MobileSortingMenu(props: MobileSortingMenuProps): JSX.Element {
  const {t} = useTranslation();
  const {open, setOpen, sorting, setSorting, setReload} = props;
  const [localSorting, setLocalSorting] = useState(sorting);

  const resetFilters = (): void => {
    setLocalSorting(ESorting.NEW_GIFTS);
  }

  const applyFilters = (): void => {
    setReload(true);
    setSorting(localSorting);
    setOpen(false);
  }

  return (
    <Dialog open={open} fullScreen TransitionComponent={MobileTransition} sx={{mx: 8, mr: "0px", "& .MuiBackdrop-root": {backgroundColor: "#363739"}}}>
      <IconButton sx={{color: "ornament.light", position: "fixed", top: 0, left: 0, mt: 2, mx: 2, p: "0px"}} onClick={(): void => setOpen(false)}>
        <ClearIcon />
      </IconButton>
      <DialogContent sx={{display: "flex", flexDirection: "column", p: "0px"}}>
        <Sorting sorting={localSorting} setSorting={setLocalSorting} isMobile/>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", py: 3, px: 2}}>
          <Button variant="outlined" startIcon={<CachedIcon/>} onClick={resetFilters} sx={{width: "100%", mr: 1, pt: 2, pb: 2, px: 5, pr: 5}}>
            {t("shop.filters.reset")}
          </Button>
          <Button variant="contained" onClick={applyFilters} sx={{width: "100%", pt: 2, pb: 2, px: 5, pr: 5, boxShadow: 0}}>
            {t("shop.filters.apply")}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
