import {get} from "../common/methods/ApiService";
import {TOperation} from "../common/struct/models/TOperation";
import {TOperationParticipant} from "../common/struct/models/TOperationParticipant";
import {API_URL, GLOBAL_ADMIN_ID, GLOBAL_OPERATION_HOSTNAME} from "../common/struct/urlManager";

export async function getMinimalAdminInfo(adminId: string): Promise<TOperationParticipant> {
  return get((API_URL.GET_MINIMAL_ADMIN_INFO).replace(":" + GLOBAL_ADMIN_ID, adminId), false, true).then((res) => {
    return {
      id: res.id,
      participant: {
        id: res.id,
        mustChangePassword: false,
        firstName: res.firstName,
        lastName: res.lastName,
        email: res.email,
        phone: res.phone,
        company: {
          name: res.company.name
        },
        addresses: [],
        job: ""
      },
      basket: [],
      company: {
        name: res.company.name
      },
      rewardPoints: 0,
      addresses: [],
      savLimitDate: ""
    };
  });
}

export async function initOperationAdmin(): Promise<TOperation> {
  return get(API_URL.OPERATION_INIT.replace(":" + GLOBAL_OPERATION_HOSTNAME, window.location.host), false, true);
}
