import React, {useContext, useState} from 'react';
import {
  Alert,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {AppContext} from "../../App";
import {INPUT_TYPES} from '../../patterns/form/formConstants';
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import {login} from "../../services/AuthenticationService";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {getOperationParticipantId} from "../../common/struct/globalVar";
import {manageStringError} from '../../common/methods/ApiService';
import {TOperationParticipant} from '../../common/struct/models/TOperationParticipant';
import {getMinimalUserInfo} from "../../services/ParticipantService";
import {globalStoreReducer} from "../../common/methods/context/globalStoreReducer";
import {StoreActions} from "../../common/struct/store";

type propsLoginWithPassword = {
  showForgetPassword: boolean,
  setShowForgetPassword: (arg1: boolean) => void,
}

export default function LoginWithPassword(props: propsLoginWithPassword): JSX.Element {
  const {t} = useTranslation();
  const STORE = useContext<any>(AppContext);
  const [store] = STORE;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [stayConnected, setStayConnected] = useState(false);
  const search = useLocation().search;
  const redirectUrl = new URLSearchParams(search).get('redirect');
  const navigate = useNavigate();

  const handleLogin = (): void => {
    setLoading(true);
    setError(null);    
    login(STORE, email.toLowerCase(), password, store.operation.id)
      .then(() => handleLoginSuccess())
      .catch((error) => handleLoginError(error))
  }

  const submit = (e: any): void => {  
    e.preventDefault();
    if (e.target.id === "login-form") {
      handleLogin();
    }
  }

  const handleLoginSuccess = (): void => {
    getMinimalUserInfo(getOperationParticipantId() ?? "")
      .then((participant: TOperationParticipant | undefined) => {
        globalStoreReducer(STORE, StoreActions.LOGIN, {user: participant, stayConnected: stayConnected});
        setLoading(false);
        navigate("/" + (redirectUrl && redirectUrl !== '' ? redirectUrl : ""));
      })
  }

  const handleLoginError = (error: any): void => {
    setLoading(false);
    globalStoreReducer(STORE, StoreActions.LOGOUT);
    manageStringError(error, setError, t);
  }

  const handleCheckbox = (event: any): void => {
    setStayConnected(event.target.checked);
  }

  return (
    <>
      <Box component="form" onSubmit={submit} id={"login-form"}
        sx={{display: 'flex', flexDirection: 'column', width: '100%', maxWidth: "600px", mx: "auto", backgroundColor: "ornament.light", p: 5, borderRadius: "4px", borderWidth: "1px", borderColor: "ornament.dark", borderStyle: "solid", boxShadow: "0 2px 0 0 rgb(32, 34, 36, 0.15)"}}>
        <Typography variant="body2" color="neutral.main"
          sx={{fontWeight: "bold", mb: 1}}>
          {t("login.email")}
        </Typography>
        <FormControl variant="outlined" sx={{mb: 4}}>
          <OutlinedInput
            id="email"
            type={INPUT_TYPES.DEFAULT}
            required={true}
            value={email}
            placeholder={t("login.your_email")}
            onChange={(e: any): any => setEmail(e.target.value)}
            disabled={loading}
          />
        </FormControl>
        <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", mb: 1}}>
          <Typography variant="body2" color="neutral.main" sx={{fontWeight: "bold"}}>
            {t("login.password")}
          </Typography>
          <Typography variant="body2" color="neutral.dark"
            sx={{fontWeight: "bold", textDecoration: "underline", cursor: "pointer"}}
            onClick={(): void => props.setShowForgetPassword(true)}>
            {t("login.forget_password")}
          </Typography>
        </Box>
        <FormControl variant="outlined" sx={{mb: 4}}>
          <OutlinedInput
            id="password"
            type={showPassword ? INPUT_TYPES.DEFAULT : INPUT_TYPES.PASSWORD}
            required={true}
            value={password}
            placeholder={t("login.your_password")}
            onChange={(e: any): any => setPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={(): void => setShowPassword(!showPassword)}
                  onMouseDown={(event): void => event.preventDefault()}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff/> : <Visibility/>}
                </IconButton>
              </InputAdornment>
            }
            disabled={loading}
          />
        </FormControl>
        {error && <Alert severity="error" variant="filled" sx={{mb: 4, width: "initial !important"}}>{error}</Alert>}
        <FormControlLabel control={<Checkbox checked={stayConnected} onChange={handleCheckbox} size="small" />}
          label={<Typography variant="body2" color="neutral.main">{t("login.stay_connected")}</Typography>} />
        <PrimaryBigButton submit={true} loading={loading} label={t("login.connect_button")}/>
      </Box>
    </>
  )
}
