import React, {MouseEventHandler, useEffect, useState} from "react";
import {Box} from "@mui/material";
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import Loader from "../loader/loader";
import LeftNav from './leftNav';
import RightNav from './rightNav';

export default function Carrousel(props: {images: {medium: string, small: string}[], sx?: any}): JSX.Element {
  const [images, setImages] = useState<{original: string, thumbnail: string, originalClass: string, thumbnailClass: string}[]>([]);
  const [loadingImages, setLoadingImages] = useState<boolean>(true);

  useEffect(() => {
    setLoadingImages(true);
    if (images[0]) {
      setLoadingImages(false);
    } else {
      props.images.map((image) => {
        setImages(prevState => [...prevState, {original: image.medium, thumbnail: image.small, originalClass: "original-image", thumbnailClass: "thumbnail-image"}])
      })
    }
  }, [props.images])  

  return (
    <Box sx={{...props.sx && props.sx}}>
      {loadingImages ? 
        <ImageGallery renderLeftNav={(onClick: MouseEventHandler<HTMLElement>, disabled: any): JSX.Element => (<LeftNav onClick={onClick} disabled={disabled} />)} renderRightNav={(onClick: MouseEventHandler<HTMLElement>, disabled: any): JSX.Element => (<RightNav onClick={onClick} disabled={disabled} />)} autoPlay={false} lazyLoad={false} showPlayButton={false} showFullscreenButton={false} items={images} infinite={true} onErrorImageURL={"//"+window.location.host+"/carrousel/unavailable-image.png"} />
        : <Box sx={{height: "602px"}}><Loader sx={{width: "100%", height: "100%"}} /></Box>
      }
    </Box>
  )
}
