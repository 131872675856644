import {TOrder, TOrderLine} from "../../common/struct/models/TOrder";
import {useTranslation} from "react-i18next";
import React, {useContext} from "react";
import {AppContext} from "../../App";
import {Box, Card, Typography} from "@mui/material";
import CardMediaLoader from "../../components/image/cardMediaLoader";
import {useNavigate} from "react-router-dom";
import {PUBLIC_URL} from "../../common/struct/urlManager";
import {PrimaryBigButton, SecondarySmallButton} from "../../components/buttons/mainButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {IconPosition} from "../../components/buttons/buttonProps";
import OrderLineStatus from "../order/orderLineStatus";

function LastOrderLine(props: {orderLine: TOrderLine}): JSX.Element {
  const {t} = useTranslation();
  const {orderLine} = props;
  const [store] = useContext<any>(AppContext);

  return (
    <Box sx={{display: "flex", alignItems: "start", borderTop: "1px solid", borderColor: "ornament.dark", mx: 2, py: 2}}>
      <CardMediaLoader image={orderLine.mainImageUrl} isMobile={store.isMobile} sx={{borderRadius: "4px", width: 60, height: 60}}/>
      <Box sx={{display: "flex", flexDirection: "column", width: "100%", ml: 3}}>
        <Box sx={{display: "flex", alignItems: "start", justifyContent: "space-between"}}>
          <Typography variant="body1" color="neutral.dark" sx={{mb: 1}}>{orderLine.name}</Typography>
          {!store.operation.shop.hidePricing && <Box sx={{display: "flex", alignItems: "start", mb: store.isMobile ? 3 : 1}}>
            <Typography variant={store.isMobile ? "body1" : "h2"} color="neutral.dark" sx={{fontWeight: "bold", mr: 0}}>
              {orderLine.pointsPrice}
            </Typography>
            <Typography variant={store.isMobile ? "caption" : "body2"} color="neutral.dark" sx={{fontWeight: "bold", fontSize: "small"}}>
              {t("basket.your_basket.points")}
            </Typography>
          </Box>}
        </Box>
        <OrderLineStatus status={orderLine.status}/>
      </Box>
    </Box>
  )
}

export default function LastOrder(props: {order: TOrder}): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {order} = props;
  const [store] = useContext<any>(AppContext);

  const redirectToLastOrder = (): void => navigate(PUBLIC_URL.ORDER.replace(":giftOrderId", order.id))
  const redirectToOrders = (): void => navigate(PUBLIC_URL.ORDERS)

  return (
    <>
      <Card sx={{boxShadow: 0, border: "1px solid", borderColor: "ornament.dark"}}>
        <Box sx={{display: "flex", alignItems: "start", justifyContent: "space-between", p: 2}}>
          <Box sx={{display: "flex", flexDirection: "column"}}>
            <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold", mb: 1}}>
              {t("orders.order.date", {date: new Date(order.createdAt)})}
            </Typography>
            <Box sx={{display: "flex"}}>
              <Typography variant="body2" color="neutral.main" sx={{mr: 0}}>{t("orders.order.quantity")}</Typography>
              <Typography variant="body2" color="neutral.dark">{t("orders.order.giftWithCount", {count: order.lines.length})}</Typography>
            </Box>
            {store.isMobile && <PrimaryBigButton sx={{mt: 3}} icon={KeyboardArrowRightIcon} position={IconPosition.RIGHT} label={t("account.view_last_order")} action={redirectToLastOrder}/>}
          </Box>
          {!store.isMobile && <PrimaryBigButton icon={KeyboardArrowRightIcon} position={IconPosition.RIGHT} label={t("account.view_last_order")} action={redirectToLastOrder}/>}
        </Box>
        {order.lines.map(line => <LastOrderLine key={"mobile-" + line.id} orderLine={line}/>)}
      </Card>
      <SecondarySmallButton sx={{width: "100%", my: 4}} label={t("account.view_all_orders")} action={redirectToOrders}/>
    </>
  )
}
