import {API_URL, GLOBAL_OPERATION_HOSTNAME} from "../common/struct/urlManager";
import {get, getWithoutToken, post, postWithoutToken} from "../common/methods/ApiService";
import {TOperation} from "../common/struct/models/TOperation";
import {getOperationParticipantId} from "../common/struct/globalVar";
import {TSupportTicketCreate} from "../common/struct/models/TSupportTicket";

export async function initOperationMinimal(): Promise<TOperation> {
  return getWithoutToken(API_URL.OPERATION_INIT_MINIMAL.replace(":" + GLOBAL_OPERATION_HOSTNAME, window.location.host));
}

export async function initOperation(): Promise<TOperation> {
  return get(API_URL.OPERATION_INIT.replace(":" + GLOBAL_OPERATION_HOSTNAME, window.location.host));
}

export async function createSupportTicket(ticket: TSupportTicketCreate): Promise<any> {
  if (getOperationParticipantId() == null) {
    return postWithoutToken(API_URL.CREATE_SUPPORT_TICKET, ticket);
  } else {
    return post(API_URL.CREATE_SUPPORT_TICKET, ticket);
  }
}
