import Loader from "../../components/loader/loader";
import {Box, Card, Grid, Typography} from "@mui/material";
import BasketOverview from "./basketOverview";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {StoreActions, StoreContext} from "../../common/struct/store";
import {AppContext} from "../../App";
import {getOperationParticipantId} from "../../common/struct/globalVar";
import {PUBLIC_URL} from "../../common/struct/urlManager";
import {TOrderPayment} from "../../common/struct/models/TOrder";
import {initiatePayment} from "../../services/OrderService";
import {loadStripe, StripeElementsOptions} from "@stripe/stripe-js";
import {Elements, PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {BillingAddressOverviewWithoutLabel} from "./billingAddressOverview";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import {TAddress} from "../../common/struct/models/TAddress";
import {globalStoreReducer} from "../../common/methods/context/globalStoreReducer";

function BasketPaymentContent(props: {billingAddress: TAddress, isMobile: boolean}): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const STORE = useContext(AppContext);
  const {billingAddress, isMobile} = props;
  const [loading, setLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const paddingH = isMobile ? 2 : 5;
  const paddingV = isMobile ? 4 : 6;

  const validatePayment = (): Promise<void> => {
    if (!elements || !stripe) {
      return Promise.reject(t("global.order_error_message"));
    }

    return stripe
      .confirmPayment({elements, confirmParams: undefined, redirect: "if_required"})
      .then(result => {
        if (result.error || result.paymentIntent.status !== "succeeded") {
          setLoading(false);
          throw new Error(result.error?.message??t("payment.error"));
        }
        setLoading(true);
        globalStoreReducer(STORE, StoreActions.UPDATE_PAYMENT_INTENT, {paymentIntentId: result.paymentIntent.id});
      })
  }

  return (
    <Grid container justifyContent="center" columns={10} columnSpacing={5} sx={{py: paddingV, px: paddingH}}>
      <Grid item xs={10} sm={6} sx={{display: "flex", flexDirection: "column", mb: isMobile ? 6 : "0px"}}>
        <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", mb: 4}}>
          {t("basket.payment.title")}
        </Typography>
        <Card sx={{mb: 4, border: "1px solid", borderColor: "ornament.dark", display: "flex", flexDirection: "column"}}>
          <Box sx={{display: "flex", py: 4, px: isMobile ? 4 : 6, mr: "0px", borderBottom: "1px solid", borderColor: "ornament.dark"}}>
            <CreditCardIcon sx={{width: 24, height: 24, color: "neutral.dark"}}/>
            <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold", ml: 1}}>
              {t("address.billing_address")}
            </Typography>
          </Box>
          <BillingAddressOverviewWithoutLabel address={billingAddress} sx={{p: isMobile ? 4 : 6, pt: isMobile ? 2 : 4}}/>
        </Card>
        <Card sx={{mb: 4, p: isMobile ? 4 : 6, border: "1px solid", borderColor: "ornament.dark", display: "flex", flexDirection: "column"}}>
          <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", mb: 4}}>
            {t("basket.payment.subtitle")}
          </Typography>
          {loading ? <Loader size={25} sx={{p: 5}}/> : <PaymentElement onLoadError={(): void => navigate(PUBLIC_URL.BASKET)} />}
        </Card>
      </Grid>
      <Grid item xs={10} sm={3} sx={{ml: isMobile ? "0px" : 6}}>
        <BasketOverview step={4} validatePayment={validatePayment}/>
      </Grid>
    </Grid>
  )
}

export default function BasketPayment(): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [store] = useContext<StoreContext>(AppContext);
  const [payment, setPayment] = useState<TOrderPayment|null>(null);

  useEffect(() => {
    document.title = t("tab_title.basket4");
  }, []);

  useEffect(() => {
    const operationParticipantId = getOperationParticipantId();
    if (operationParticipantId == null) {
      navigate(PUBLIC_URL.HOME);
      return;
    }
    if (store.billingAddress?.id == null || store.shippingAddress?.id == null) {
      navigate(PUBLIC_URL.BASKET);
      return;
    }
  }, [])

  useEffect(() => {
    if (store.billingAddress?.id == null || store.basketMonetaryComplement == 0) {
      return;
    }

    initiatePayment(store.basketMonetaryComplement, store.billingAddress.id)
      .then(payment => setPayment(payment))
      .catch(() => navigate(PUBLIC_URL.BASKET))
  }, [store.basketMonetaryComplement, store.billingAddress])

  const options: StripeElementsOptions = {
    clientSecret: payment?.stripeSecret,
    loader: "always",
    fonts: [{
      cssSrc: "https://fonts.googleapis.com/css2?family=Mulish:wght@300;600;700&display=swap"
    }],
    appearance: {
      variables: {
        colorPrimary: store.theme?.palette.primary.main,
        fontFamily: store.theme?.typography.fontFamily,
        borderRadius: "4px"
      }
    }
  }
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY??"", {locale: 'fr'});
  return payment == null || store.billingAddress == null ? <Loader size={75} sx={{position: "sticky", top: "0%", height: "100vh", mt: "-350px"}}/> :
    <Elements stripe={stripePromise} options={options}>
      <BasketPaymentContent billingAddress={store.billingAddress} isMobile={store.isMobile}/>
    </Elements>
}
