import React, {Dispatch, SetStateAction, useState} from "react";
import {InputAdornment, TextField} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

type SearchProps = {
  search: string|null,
  setSearch: Dispatch<SetStateAction<string|null>>,
  label: string,
  reload?: boolean,
  setReload?: Dispatch<SetStateAction<boolean>>
  searchValue?: string|null,
  setSearchValue?: Dispatch<SetStateAction<string|null>>
}

export default function Search(props: SearchProps): JSX.Element {
  const {search, setSearch, label, searchValue, setSearchValue, setReload, reload = false} = props;
  const actualValue = searchValue??search;
  const [inputValue, setInputValue] = useState(actualValue);

  const handleSearch = (event:any): void => {
    event.preventDefault();
    if (setReload) {
      setReload(true);
    }
    setSearch(inputValue);
  }

  const handleInputValue = (event: any):void => {
    if (setSearchValue) {
      setSearchValue(event.target.value);
    } else {
      setInputValue(event.target.value);
    }
  }

  return (
    <form onSubmit={handleSearch} style={{display: 'flex', width: "100%"}} >
      <TextField color="primary" value={inputValue} onChange={handleInputValue} disabled={reload}
        sx={{width: "100%", backgroundColor: "ornament.light", "& label": {fontSize: "14px"}}}
        placeholder={label}
        InputProps={{
          sx: {fontSize: "14px", pr: 1, "& input": {p: 3}},
          endAdornment:
            !searchValue && !setSearchValue && <InputAdornment onClick={handleSearch} position="end" sx={{cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "primary.main", borderRadius: "5px", maxHeight: "40px", maxWidth: "40px", height: "100%", width: "100%"}}><SearchIcon fontSize="small" sx={{color: "primary.contrastText"}}/></InputAdornment>,
        }} />
    </form>
  )
}

