import React from "react";
import {Box, Card, CardContent, Typography, Divider, useMediaQuery} from "@mui/material";
import {useTranslation} from "react-i18next";
import Complement from "../../assets/complement.png";

export default function MonetaryComplementCard(props: {isMobile: boolean}): JSX.Element {
  const {t} = useTranslation();
  const {isMobile} = props;

  const breakpointXXXXL = useMediaQuery('(min-width:1320px)');
  const breakpointXXXL = useMediaQuery('(min-width:1120px)');
  const breakpointXXL = useMediaQuery('(min-width:1280px)');
  const breakpointXL = useMediaQuery('(min-width:1086px)');
  const breakpointL = useMediaQuery('(min-width:1055px)');
  const breakpointM = useMediaQuery('(min-width:1025px)');
  const breakpointS = useMediaQuery('(min-width:1000px)');
  const breakpointXS = useMediaQuery('(min-width:945px)');
  const breakpointXXS = useMediaQuery('(min-width:845px)');

  return <Card sx={{display: "flex", flexDirection: "column", backgroundColor: "#15154B", height: isMobile ? "316px" : "374px",
    boxShadow: 0, borderWidth: 1, borderStyle: "solid", borderColor: "ornament.dark"}}>
    <CardContent sx={{p: 1, height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", position: "relative", "&:last-child": {pb: 1}}}>
      <img src={Complement} alt="monetary complement image" style={{
        height: isMobile ? "270px" : breakpointXL ? "374px" : breakpointL ? "330px" : breakpointM ? "280px" : breakpointS ? "240px" : breakpointXS ? "350px" : breakpointXXS ? "310px" : "260px", 
        position: "absolute", bottom: "0%", 
        left: breakpointXXXXL ? "50%" : breakpointXXXL ? "40%" : breakpointXL ? "30%" : breakpointM ? "40%" : "50%", 
        transform: "translateX(-50%)"
      }}/>
      <Box sx={{position: "relative", zIndex: 2, p: 1, my: 1}}>
        <Typography variant="body1" color="ornament.main" sx={{fontWeight: "bold", fontSize: breakpointXXL ? "18px" : "14px", lineHeight: "26px"}}>{t("shop.complement.title")}</Typography>
        <Typography variant="body1" color="ornament.main" sx={{fontWeight: 300, mb: 3, fontSize: breakpointXXL ? "18px" : "14px", lineHeight: "26px"}}>{t("shop.complement.subtitle")}</Typography>
        <Divider sx={{borderColor: "ornament.main", width: "50%", mb: 3}} />
        <Typography variant="body1" color="ornament.main" sx={{fontWeight: "medium", fontSize: breakpointXXL ? "18px" : "14px", fontStyle: "italic"}}>{t("shop.complement.text")}</Typography>
      </Box>
    </CardContent>
  </Card>
}
