import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {PUBLIC_URL} from "../../common/struct/urlManager";
import {FormControl, Grid, RadioGroup, Typography} from "@mui/material";
import {AppContext} from "../../App";
import {useTranslation} from "react-i18next";
import BasketOverview from "./basketOverview";
import {TAddress, TAddressType} from "../../common/struct/models/TAddress";
import Loader from "../../components/loader/loader";
import {TViolation} from "../../common/struct/models/TViolation";
import {StoreContext} from "../../common/struct/store";
import {getUserAddresses} from "../../services/ParticipantService";
import {getOperationParticipantId} from "../../common/struct/globalVar";
import BasketBillingAddressCard from "./basketBillingAddressCard";
import BasketCreateAddress from "./basketCreateAddress";


export default function BasketShippingAddress(): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [store] = useContext<StoreContext>(AppContext);
  const [violations, setViolations] = useState<TViolation[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = t("tab_title.basket3");
  }, []);

  const [address, setAddress] = useState<TAddress|undefined>(undefined);
  const [shippingAddress, setShippingAddress] = useState<TAddress|undefined>(undefined);
  const [lastAddress, setLastAddress] = useState<TAddress|undefined>(undefined);
  const [selectedAddress, setSelectedAddress] = useState<TAddress|undefined>(undefined);

  useEffect(() => {
    const operationParticipantId = getOperationParticipantId();
    if (operationParticipantId == null) {
      navigate(PUBLIC_URL.HOME);
      return;
    }
    const shippingAddress = store.shippingAddress;
    if (shippingAddress == null) {
      navigate(PUBLIC_URL.BASKET);
      return;
    }

    setShippingAddress(shippingAddress);
    getUserAddresses(operationParticipantId)
      .then(result => {
        const address = result.addresses.find(address => address.type == TAddressType.INVOICE);
        const lastAddress = result.lastAddresses.find(address => address.type == TAddressType.INVOICE);

        setAddress(address);
        setSelectedAddress(address??shippingAddress);
        setLastAddress(lastAddress);
      })
      .finally(() => setLoading(false))
  }, [])

  const paddingH = store.isMobile ? 2 : 5;
  const paddingV = store.isMobile ? 4 : 6;

  return loading || shippingAddress == null ? <Loader size={75} sx={{position: "sticky", top: "0%", height: "100vh", mt: "-350px"}}/> :
    <Grid container justifyContent="center" columns={10} columnSpacing={5} sx={{py: paddingV, px: paddingH}}>
      <Grid item xs={10} sm={6} sx={{display: "flex", flexDirection: "column", mb: store.isMobile ? 6 : "0px"}}>
        <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", mb: 4}}>
          {t("basket.billing.title")}
        </Typography>
        <FormControl sx={{width: "100%"}}>
          <RadioGroup>
            <BasketBillingAddressCard address={shippingAddress} isMobile={store.isMobile} sameAsShipping={true} favorite={false} selected={selectedAddress} setSelected={setSelectedAddress} violations={[]} loading={loading}/>
            {address && <BasketBillingAddressCard address={address} isMobile={store.isMobile} sameAsShipping={false} favorite={true} selected={selectedAddress} setSelected={setSelectedAddress} violations={violations} loading={loading}/>}
            {lastAddress?.id == undefined && <BasketCreateAddress type={TAddressType.INVOICE} isMobile={store.isMobile} selected={selectedAddress} setSelected={setSelectedAddress} violations={violations} loading={loading}/>}
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={10} sm={3} sx={{ml: store.isMobile ? "0px" : 6}}>
        <BasketOverview step={3} setViolations={setViolations} billingAddress={selectedAddress} favoriteBillingAddress={selectedAddress?.id == address?.id??""}/>
      </Grid>
    </Grid>
}
