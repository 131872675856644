import React, {useContext} from "react";
import CardMediaLoader from "../../components/image/cardMediaLoader";
import {Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {AppContext} from "../../App";
import {globalStoreReducer} from "../../common/methods/context/globalStoreReducer";
import {getOperationParticipantId, removeGiftFromBasket} from "../../common/struct/globalVar";
import {PUBLIC_URL} from "../../common/struct/urlManager";
import {StoreActions} from "../../common/struct/store";
import {TBasketGift} from "../../common/struct/models/TBasket";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import {updateParticipantBasket} from "../../services/ParticipantService";

type RemoveGiftFromBasketDialogProps = {
  gift: TBasketGift,
  isMobile: boolean,
  open: boolean,
  setOpen: (open: boolean) => void,
  setSuccess: (success: boolean) => void
}

export default function RemoveGiftFromBasketDialog(props: RemoveGiftFromBasketDialogProps): JSX.Element {
  const {gift, isMobile, open, setOpen, setSuccess} = props;
  const {t} = useTranslation();
  const navigate = useNavigate();
  const giftImage = isMobile ? gift.image?.small??null : gift.image?.medium??null;
  const STORE = useContext<any>(AppContext);
  const [store] = STORE;

  const closeDialog = (): void => setOpen(false);

  const deleteFromBasket = (): void => {
    const operationParticipantId = getOperationParticipantId();
    if (!operationParticipantId) {
      return;
    }

    const basket = removeGiftFromBasket(store.basket, gift.id);
    updateParticipantBasket(operationParticipantId, basket)
      .then(basket => {
        globalStoreReducer(STORE, StoreActions.UPDATE_BASKET, {basket: basket});
      })
      .finally((): void => {
        setSuccess(true);
        closeDialog();
      })
      .catch(() => navigate(PUBLIC_URL.HOME));
  }

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <Typography color="neutral.dark" sx={{fontWeight: "bold"}} variant="h2">
            {t('gift_remove_from_basket.title')}
          </Typography>
          <IconButton onClick={closeDialog}><CloseIcon/></IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{display: "flex", flexDirection: "column"}}>
        <Box sx={{display: "flex", mb: 3}}>
          <CardMediaLoader image={giftImage} isMobile={isMobile} sx={{width: 180, height: 180}}/>
          <Box sx={{display: "flex", flexDirection: "column", width: "100%", ml: 3}}>
            <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold"}}>
              {gift.name}
            </Typography>
            <Box sx={{display: "flex", mt: 2}}>
              <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", mr: 0}}>
                {gift.points}
              </Typography>
              <Typography variant="caption" color="neutral.dark" sx={{fontWeight: "bold"}}>
                {t("gift_remove_from_basket.points")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <Button variant="outlined" onClick={closeDialog} sx={{height: "56px", width: "100%", mr: 3}}>
            {t('gift_remove_from_basket.cancel')}
          </Button>
          <PrimaryBigButton sx={{width: "100%"}} label={t("gift_remove_from_basket.confirm")} action={deleteFromBasket}/>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
