import {TAddress, TAddressType} from "../../common/struct/models/TAddress";
import {Box, Card, FormControlLabel, Radio, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import AddressForm from "./addressForm";
import {TViolation} from "../../common/struct/models/TViolation";

interface BasketCreateAddressProps {
  isMobile: boolean;
  type: TAddressType;
  selected: TAddress|undefined;
  setSelected: (selected: TAddress|undefined) => void;
  violations: TViolation[];
  loading: boolean;
}

export default function BasketCreateAddress(props: BasketCreateAddressProps): JSX.Element {
  const {isMobile, type, selected, setSelected, violations, loading} = props;
  const {t} = useTranslation();

  return (
    <Card sx={{mb: 4, border: "1px solid", borderColor: "ornament.dark", display: "flex", flexDirection: "column"}}>
      <FormControlLabel sx={{py: 4, px: isMobile ? 4 : 6, mr: "0px", borderBottom: "1px solid", borderColor: "ornament.dark"}} checked={selected?.id == null}
        onChange={(): void => setSelected(undefined)} control={<Radio sx={{fontSize: 24}}/>} disabled={loading}
        label={<Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold", ml: 1}}>
          {t(`address.${type == TAddressType.INVOICE ? "billing" : "shipping"}_create`)}
        </Typography>}/>
      {selected?.id == null && <Box sx={{display: "flex", flexDirection: "column", p: isMobile ? 4 : 6}}>
        <AddressForm type={type} setAddress={setSelected} violations={violations} loading={loading}/>
      </Box>}
    </Card>
  )
}
