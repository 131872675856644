import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {PUBLIC_URL} from "../../common/struct/urlManager";
import {Card, Grid, Typography} from "@mui/material";
import CardMediaLoader from "../../components/image/cardMediaLoader";
import emptyBasket from "../../assets/empty_basket.svg";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import React, {useContext} from "react";
import {AppContext} from "../../App";

export default function BasketEmpty(): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [store] = useContext<any>(AppContext);

  const redirectToShop = (): void => navigate(PUBLIC_URL.HOME);
  return (
    <Grid item xs={12} sx={{display: "flex", flexDirection: "column"}}>
      <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", mb: 4}}>
        {t("basket.your_basket.title")}
      </Typography>
      <Card sx={{py: 6, display: "flex", flexDirection: "column", alignItems: "center", border: "1px solid", borderColor: "ornament.dark"}}>
        <CardMediaLoader image={emptyBasket} isMobile={store.isMobile} sx={{width: 220, height: 220}}/>
        <Typography variant="h2" color="neutral.main" sx={{fontWeight: "medium", mt: 2}}>
          {t("basket.your_basket.empty")}
        </Typography>
        <Typography variant="body1" color="neutral.main" sx={{mt: 0, mb: 4}}>
          {t("basket.your_basket.find_gift")}
        </Typography>
        <PrimaryBigButton label={t("basket.your_basket.access_universes")} action={redirectToShop}/>
      </Card>
    </Grid>
  )
}
